import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, doc, updateDoc, setDoc, deleteDoc, orderBy, query, where } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase_config';
import { signOut } from "firebase/auth";
import Swal from "sweetalert2";
import UpIcon from '../images/upward.svg';
import DownIcon from '../images/downward.svg';


function CaseCategoryMod(props) {
    const userAuth = props.userAuth;
    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);

    const [cateList, setCateList] = useState();
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    let maxCateVal = 0;

    const callDb = useCallback(async () => {
        // const querySnapshot = await getDocs(collection(db, "category"));    //기존에 정렬없이 호출
        const cateRef = collection(db, "casecategory");
        const q = query(cateRef, where("groupid", "==", props.userGroup), orderBy("value"));
        const querySnapshot = await getDocs(q); //category테이블의 value값을 기준으로 정렬한 로직
        
        const data = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        
        setCateList(data)
    },[props.userGroup])

    useEffect(() => {
        callDb();
    },[updateState, callDb])

    //카테고리, 수정, 삭제 렌더링 부분
    function UpdateCateVal(props) {
        const [cateVal, setCateVal] = useState(props.cateval);
        let fileid = props.fileid;
        //const [prevCateVal, setPrevCateVal] = useState(cateVal);

        return(
            <div className="catelist_wrap">
                <div className="catelist_upgroup">
                    <img className="catelist_updown"
                        src={UpIcon} alt="img"
                        onClick={()=>{
                            //const cateval = event.target.cateval.value;
                            if(userAuth === 1 || userAuth === 2 || userAuth === 7)
                                props.onUpward();
                        }}
                    >
                    </img>
                    <img className="catelist_updown"
                        src={DownIcon} alt="img"
                        onClick={()=>{
                            //const cateval = event.target.cateval.value;
                            if(userAuth === 1 || userAuth === 2 || userAuth === 7)
                                props.onDownward();
                        }}
                    >
                    </img>
                    <input className="catelist_input"
                        type="text"
                        placeholder="카테고리명을 입력하세요"
                        name="cateval"
                        value={cateVal}
                        onChange={(event)=>{
                            //setPrevCateVal(cateVal);    //변경 전 카테고리 값 저장
                            setCateVal(event.target.value);
                        }}
                    ></input>
                </div>
                <div className="catelist_downgroup">
                    {/* 미지정 카테고리는 삭제할수 없게 처리 */}
                    {fileid !== '기본 카테고리'
                        ?<button className="catelist_btn"
                            onClick={()=>{
                                if(userAuth === 1 || userAuth === 2 || userAuth === 7)
                                    props.onUpdate(cateVal, props.repcateval);
                            }}
                            >수정
                        </button>
                        :null
                    }
                    <button className="catelist_btn"
                        onClick={()=>{
                            if(userAuth === 1 || userAuth === 2 || userAuth === 7)
                                toCateDesignPage(fileid, cateVal);
                        }}
                        >꾸미기
                    </button>
                    {/* 미지정 카테고리는 삭제할수 없게 처리 */}
                    {fileid !== '기본 카테고리'
                        ?<button className="catelist_btn"
                            onClick={()=>{
                                //const cateval = event.target.cateval.value;
                                if(userAuth === 1 || userAuth === 2 || userAuth === 7){
                                    Swal.fire({
                                        icon: "question",
                                        title: "카테고리 삭제",
                                        text: "카테고리 삭제를 하시겠습니까?",
                                        showCancelButton: true,
                                        confirmButtonText: "삭제",
                                        cancelButtonText: "취소",
                                    }).then((res) => {
                                        if (res.isConfirmed) {
                                            props.onDelete();
                                        }
                                    });
                                }
                            }}
                            >삭제
                        </button>
                        :null
                    }
                </div>
            </div>
        )
    }

    //카테고리 변경, 삭제 로직
    function ShowCateList() {
        let tmpCateList = []
        tmpCateList = cateList;

        if(tmpCateList === undefined){
            return(
                <div></div>
            )
        }else{
            // console.log("카테고리변수개수:", tmpCateList.length);
            maxCateVal = tmpCateList.length;    //카테고리 최대변수 갯수
            return(
                <div className="showcatelist_wrap">
                    {tmpCateList.map((option) => (
                        <div  className='showcatelist_map'
                              key={option.id}
                        >
                            <UpdateCateVal 
                                cateval={option.cateval}
                                repcateval={option.repcateval}
                                fileid={option.fileid}
                                fileAddr={option.fileAddr}
                                onUpdate={async (cateVal, repcateVal)=>{
                                        const docRef = collection(db, "casecategory");
                                        const q = query(docRef, where("repcateval", "==", cateVal.replace(/(\s*)/g,"")));
                                        const querySnapshot = await getDocs(q); //contents 테이블의 cateval값이 선택한 카테고리인 값을 호출

                                        if (cateVal.replace(/(\s*)/g,"") === repcateVal) {
                                            //띄어쓰기만 바꾸는 경우 변경가능하게 하는 로직
                                            const categoryRef = doc(db, "casecategory", option.id);
                                            await updateDoc(categoryRef, {
                                                cateval: cateVal,
                                                repcateval: cateVal.replace(/(\s*)/g,"")
                                            })
                                            //contents 테이블의 cateval값도 변경된 카테고리명으로 적용
                                            const contentsRef = collection(db, "casecontents");
                                            const q = query(contentsRef, where("cateval", "==", option.cateval));
                                            const querySnapshot = await getDocs(q); //contents 테이블의 cateval값이 선택한 카테고리인 값을 호출
    
                                            querySnapshot.docs.map(async content => {
                                                const contentRef = doc(db, "casecontents", content.id);
                                                await updateDoc(contentRef, {
                                                    cateval: cateVal,
                                                    repcateval: cateVal.replace(/(\s*)/g,"")
                                                })
                                            }); //contents 테이블의 cateval값이 변경 전 카테고리인 데이터들을 변경
                                            Swal.fire({
                                                icon: "success",
                                                title: "카테고리 수정 완료",
                                                text: "카테고리 수정이 완료되었습니다",
                                                //showCancelButton: true,
                                                confirmButtonText: "확인",
                                                //cancelButtonText: "취소",
                                            }).then((res) => {
                                                /* Read more about isConfirmed, isDenied below */
                                                if (res.isConfirmed) {
                                                    //확인 요청 처리
                                                }
                                            });
    
                                            setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                        } else { 
                                            //문구자체를 바꾸는 경우 바꾸는 문구가 이미 있는 문구인지, 빈값인지 체크해서 수정하는 로직
                                            if (querySnapshot.docs.length === 0 && cateVal !== "") {
                                                const categoryRef = doc(db, "casecategory", option.id);
                                                await updateDoc(categoryRef, {
                                                    cateval: cateVal,
                                                    repcateval: cateVal.replace(/(\s*)/g,"")
                                                })
                                                //contents 테이블의 cateval값도 변경된 카테고리명으로 적용
                                                const contentsRef = collection(db, "casecontents");
                                                const q = query(contentsRef, where("cateval", "==", option.cateval));
                                                const querySnapshot = await getDocs(q); //contents 테이블의 cateval값이 선택한 카테고리인 값을 호출
    
                                                querySnapshot.docs.map(async content => {
                                                    const contentRef = doc(db, "casecontents", content.id);
                                                    await updateDoc(contentRef, {
                                                        cateval: cateVal,
                                                        repcateval: cateVal.replace(/(\s*)/g,"")
                                                    })
                                                }); //contents 테이블의 cateval값이 변경 전 카테고리인 데이터들을 변경
                                                Swal.fire({
                                                    icon: "success",
                                                    title: "카테고리 수정 완료",
                                                    text: "카테고리 수정이 완료되었습니다",
                                                    //showCancelButton: true,
                                                    confirmButtonText: "확인",
                                                    //cancelButtonText: "취소",
                                                }).then((res) => {
                                                    /* Read more about isConfirmed, isDenied below */
                                                    if (res.isConfirmed) {
                                                        //확인 요청 처리
                                                    }
                                                });
    
                                                setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                            } else if(querySnapshot.docs.length !== 0) {
                                                // doc.data() will be undefined in this case
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "카테고리명 중복",
                                                    text: "띄어쓰기만 다른경우 동일한 단어로 간주됩니다",
                                                    //showCancelButton: true,
                                                    confirmButtonText: "확인",
                                                    //cancelButtonText: "취소",
                                                }).then((res) => {
                                                    /* Read more about isConfirmed, isDenied below */
                                                    if (res.isConfirmed) {
                                                        //확인 요청 처리
                                                    }
                                                });
                                            } else {
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "카테고리명 오류",
                                                    text: "카테고리명을 입력하세요",
                                                    //showCancelButton: true,
                                                    confirmButtonText: "확인",
                                                    //cancelButtonText: "취소",
                                                }).then((res) => {
                                                    /* Read more about isConfirmed, isDenied below */
                                                    if (res.isConfirmed) {
                                                        //확인 요청 처리
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }
                                onDelete={async ()=>{
                                        //삭제하면 그 뒤에 정렬된 카테고리의 value값들을 하나씩 앞으로 당겨주는 로직
                                        //맨 끝은 미지정으로 둬야하기때문에 for문의 끝은 카테고리길이(maxCateVal)보다 1 적은값으로 지정해줘야함
                                        for(let i = option.value;i<maxCateVal-1;i++){
                                            const categoryRef = doc(db, "casecategory", tmpCateList[i].id);
                                            await updateDoc(categoryRef, {
                                                value: i
                                            })
                                        }
                                        await deleteDoc(doc(db, "casecategory", option.id))
                                        const docRef = collection(db, "casecontents");
                                        const q = query(docRef, where("cateval", "==", option.cateval));
                                        const querySnapshot = await getDocs(q); //contents 테이블의 cateval값이 선택한 카테고리인 값을 호출
                                        
                                        querySnapshot.docs.map(async content => {
                                            const contentRef = doc(db, "casecontents", content.id);
                                            await updateDoc(contentRef, {
                                                cateval: "기본 카테고리",
                                                repcateval: "기본 카테고리"
                                            })
                                        }); //contents 테이블의 cateval값이 변경 전 카테고리인 데이터들을 변경

                                        Swal.fire({
                                            icon: "success",
                                            title: "카테고리 삭제 완료",
                                            text: "카테고리 삭제가 완료되었습니다",
                                            //showCancelButton: true,
                                            confirmButtonText: "확인",
                                            //cancelButtonText: "취소",
                                        }).then((res) => {
                                            /* Read more about isConfirmed, isDenied below */
                                            if (res.isConfirmed) {
                                                //확인 요청 처리
                                            }
                                        });
                                        setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                    }
                                }
                                onUpward={async ()=>{
                                        //위 클릭하면 바로 위 카테고리와 value값을 바꿔주는 로직
                                        //맨 끝은 미지정으로 둬야하기때문에 for문의 끝은 카테고리길이(maxCateVal)보다 1 적은값으로 지정해줘야함
                                        let optionVal = option.value;

                                        if(optionVal > 1 && optionVal < tmpCateList.length){
                                            let categoryRef = doc(db, "casecategory", tmpCateList[optionVal-2].id);
                                            await updateDoc(categoryRef, {
                                                value: optionVal
                                            })

                                            categoryRef = doc(db, "casecategory", tmpCateList[optionVal-1].id);
                                            await updateDoc(categoryRef, {
                                                value: optionVal-1
                                            })
                                        }
                                        setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                    }
                                }
                                onDownward={async ()=>{
                                        //아래 클릭하면 바로 아래 카테고리와 value값을 바꿔주는 로직
                                        //맨 끝은 미지정으로 둬야하기때문에 for문의 끝은 카테고리길이(maxCateVal)보다 1 적은값으로 지정해줘야함
                                        let optionVal = option.value;

                                        if(optionVal < tmpCateList.length-1){
                                            let categoryRef = doc(db, "casecategory", tmpCateList[optionVal].id);
                                            await updateDoc(categoryRef, {
                                                value: optionVal
                                            })

                                            categoryRef = doc(db, "casecategory", tmpCateList[optionVal-1].id);
                                            await updateDoc(categoryRef, {
                                                value: optionVal+1
                                            })
                                        }
                                        setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                    }
                                }
                            ></UpdateCateVal>
                        </div>
                    ))}
                </div>
            )
        }
    }

    //신규 카테고리 렌더링
    function AddCategory() {
        return(
            <form
                className="newcate_form"
                onSubmit={(event) => {
                    event.preventDefault();
                    if(userAuth === 1 || userAuth === 2 || userAuth === 7){
                        const addcate = event.target.addcate.value;
                        onCreate(addcate);
                    }
                }}
            >
                <div className="newcate_wrap">
                    <input className="newcate_input" 
                            type="text" 
                            name="addcate"
                            placeholder="카테고리명을 입력하세요">
                    </input>
                    <button className="newcate_btn"
                            type="submit"
                    >생성
                    </button>
                </div>
            </form>
        )
    }

    //신규 카테고리 생성 로직
    const onCreate = async (props) => {
        const docRef = collection(db, "casecategory");
        const q = query(docRef, where("repcateval", "==", props.replace(/(\s*)/g,"")));
        const querySnapshot = await getDocs(q); //contents 테이블의 cateval값이 선택한 카테고리인 값을 호출

        //console.log("띄어쓰기제거",props.replace(/(\s*)/g,""));

        let now = new Date();
        let todayMonth = now.getMonth() + 1;
        let todayDate = now.getDate();
        let todayHour = now.getHours();
        let todayMin = now.getMinutes();
        let todaySec = now.getSeconds();
        let fileid = props+String(todayMonth)+String(todayDate)+String(todayHour)+
                            String(todayMin)+String(todaySec);
        //카테고리 테이블에는 최초이름+만든시각을 붙여서 만들어줌
        if (querySnapshot.docs.length === 0 && props !== "") {
            try{
                await setDoc(doc(db, "casecategory", fileid),{
                    cateval: props,
                    repcateval: props.replace(/(\s*)/g,""),
                    //imgUrl: "",
                    fileAddr: "",
                    //filename: "",
                    fileid: fileid,
                    value: maxCateVal
                })
                maxCateVal++;
                Swal.fire({
                    icon: "success",
                    title: "카테고리 신규생성 완료",
                    text: "카테고리 생성이 완료되었습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
                setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
            } catch(error){
                console.log(error.message);
            }
        } else if(querySnapshot.docs.length !== 0) {
            // doc.data() will be undefined in this case
            Swal.fire({
                icon: "error",
                title: "카테고리명 중복",
                text: "띄어쓰기만 다른경우 동일한 단어로 간주됩니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "카테고리명 오류",
                text: "카테고리명을 입력하세요",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        }
    }

    const toHomePage = () => {
        navigate("/caseHome");
    }

    //매개변수를 넘기면서 이미지 수정 페이지로 이동
    const toCateDesignPage = (fileid, cateVal) => {
        navigate("/categoryDesign", {
            state: {
                fileid: fileid,
                cateVal: cateVal
            },
        });
    }

    return (
        <div className="wrap">
            <div className="content_wrap">
                <h3 className="sub_tit">
                    <span>DENTAL CASE PRESENTATION 카테고리 관리</span>
                </h3>
                <div className="catemod_wrap">
                    <button className="btn_catemod"
                            onClick={()=>{
                                setVisible(!visible);
                            }}
                    >신규
                    </button>
                    {visible && <AddCategory></AddCategory>}
                    <ShowCateList></ShowCateList>
                    <button className="btn_catemod" 
                            onClick={toHomePage}
                    >홈으로
                    </button>
                </div>
            </div>
            <footer>
                <button className="footer_logout"
                        onClick={()=>{
                            signOut(auth);
                            console.log("로그아웃");
                            navigate("/"); //페이지 이동
                            window.location.reload(false);  //페이지 refresh
                        }}
                >로그아웃</button>
                <span className="copyright">Copyright 2022. Dental Connect All rights reserved.</span>
            </footer>
        </div>
    )
}

export default CaseCategoryMod;