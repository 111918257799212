import React, { useRef, useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, getDocs, collection, query, orderBy, where, updateDoc } from "firebase/firestore";
import { storage, db } from '../firebase_config';
import { useNavigate, useLocation } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import Swal from "sweetalert2";

import NoImage from '../images/renewal/No_image.svg';
import CateSelect from '../images/renewal/Category_select.svg';


function ContentUpload(props) {
    const userGroup = props.userGroup;
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const cateName = state.cateval;
    const sysDiv = state.sysname;

    const contentData = state.data; //전 페이지에서 넘어온 매개변수 

    let initialSelFileaddr = "";
    let initialSelFilename = "";
    let initialSelContenttag = "";
    let initialSelCategory = "";
    let initialSelFiletype = "";

    if (contentData !== "NEW") {
        initialSelFileaddr = contentData.fileAddr;
        initialSelFilename = contentData.filename;
        initialSelContenttag = contentData.tag;
        initialSelCategory = contentData.cateval;
        initialSelFiletype = contentData.filetype;
    }

    const [contentURL, setContentURL] = useState(initialSelFileaddr !== "" ? initialSelFileaddr : "");
    const [upFileName, setUpFileName] = useState(initialSelFilename !== "" ? initialSelFilename : "");   //원본파일이름
    const [contentName, setContentName] = useState(initialSelContenttag !== "" ? initialSelContenttag : "");
    const [selCategory, setSelCategory] = useState(initialSelCategory !== "" ? initialSelCategory : cateName);
    const [fileType, setFileType] = useState(initialSelFiletype !== "" ? initialSelFiletype : "");
    
    const [cateList, setCateList] = useState([]);
    const [fileData, setFileData] = useState();     //파일 리스트
    const [contentChgYn, setContentChgYn] = useState(false); //컨텐츠 파일 변경여부
    
    const fileInput = useRef();
    const navigate = useNavigate();

    let fileUrl = '';    //원본파일 url값
    let thumbnailUrl = '';   //썸네일 url값
    let imgUrl = '';     //원본파일 firestore 경로
    let imgThUrl = '';   //썸네일 firestore 경로

    useEffect(() => {
        const fetchData = async () => {
            const docRef = collection(db, "category");
            let q;

            if (sysDiv === "CA") {
                q = query(docRef, where("sysdiv", "==", sysDiv), where("groupid", "==", userGroup), orderBy("value"));
            } else if (sysDiv === "DC" && cateName === "비용안내") {
                q = query(docRef, where("sysdiv", "==", sysDiv), where("repcateval", "==", "비용안내"), orderBy("value"));
            } else {
                q = query(docRef, where("sysdiv", "==", sysDiv), orderBy("value"));
            }

            const snapshot = await getDocs(q);
            const data = snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));

            setCateList(data);
        };

        fetchData();
    }, [cateName, sysDiv, userGroup]);

    // 파일 선택시 파일리스트 상태 변경해주는 함수
    const handleContentChange = async (e) => {
        // 여러 콘텐츠를 동시에 올리는 경우 아래 주석으로 살리기
        // for(const content of e.target.files){
        //     setFileList((prevState)=>[...prevState, content]);
        // }
        const uploadFile = Array.from(e.target.files)

        // 원본 리사이즈 로직
        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 3840
        }
        //썸네일생성용 리사이즈
        const optionsThumbnail = {
            maxSizeMB: 1,
            maxWidthOrHeight: 500
        }

        let file = uploadFile[0];
        
        let uploadFileType = uploadFile[0].type.substr(0,5);

        if(uploadFileType === "image") {
            Swal.fire({
                icon: "info",
                title: "이미지 압축중",
                text: "첨부파일 항목에 파일 이름이 생길때까지 기다려주세요",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })

            let now = new Date();
            let todayMonth = now.getMonth() + 1;
            let todayDate = now.getDate();
            let todayHour = now.getHours();
            let todayMin = now.getMinutes();
            let todaySec = now.getSeconds();
            let filename

            filename = "IMG"+String(todayMonth)+String(todayDate)+String(todayHour)+
                    String(todayMin)+String(todaySec)+userGroup;

            let compressedFile = [];
            compressedFile[0] =  await imageCompression(file, options);
            compressedFile[0].name = filename;
            compressedFile[0].thumbnail = 'N';
            compressedFile[1] =  await imageCompression(file, optionsThumbnail);    //썸네일생성용
            compressedFile[1].name = 'TN'+filename;
            compressedFile[1].thumbnail = 'Y';
            
            setFileData(compressedFile); //원본 리사이즈해서 올릴때
            //setFileData(uploadFile);   //원본 그대로 올릴때
            setUpFileName(uploadFile[0].name);
            setContentChgYn(true);
            setFileType("image");
        } else if(uploadFile[0].type === "video/mp4" || uploadFile[0].type === "video/quicktime") {
            setFileData(uploadFile)
            setUpFileName(uploadFile[0].name);
            setContentChgYn(true);
            setFileType("video");
        } else {
            Swal.fire({
                icon: "error",
                title: "지원하지 않는 형식의 파일",
                text: "mp4 또는 mov 형식의 파일을 업로드 해주세요",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    };

    //파일정보 테이블에 저장하는 로직
    const createContentInfo = async (fileid, fileUrl, thumbnailUrl, imgUrl, imgThUrl, contentName, upFileName, selCategory, fileListType) => {
        const now = new Date();
        try{
            await setDoc(doc(db, "contents", fileid),{
                fileAddr: fileUrl,
                thumbnail: thumbnailUrl,
                imgUrl: imgUrl,
                imgThUrl: imgThUrl,
                tag: contentName,
                uploadUser: props.userNameData,
                cateval: selCategory,
                repcateval: selCategory.replace(/[^\w가-힣]/g, ''),
                count: 0,
                filename: upFileName,
                filetype: fileListType,
                fileid: fileid,
                sysdiv: sysDiv,
                groupid: userGroup,
                uploadtime: now,
            })
        } catch(error){
            console.log(error.message);
        }
    }

    // 컨텐츠 정보 업데이트 함수
    const updateContentInfo = async (fileid, fileUrl, thumbnailUrl, imgUrl, imgThUrl, contentName, upFileName, selCategory, fileListType) => {
        await updateDoc(doc(db, "contents", fileid), {
            cateval: selCategory,
            repcateval: selCategory.replace(/[^\w가-힣]/g, ''),
            tag: contentName,
            fileAddr: fileUrl,
            thumbnail: thumbnailUrl,
            imgUrl: imgUrl,
            imgThUrl: imgThUrl,
            filename: upFileName,
            filetype: fileListType
        });
    };

    // 파일 업로드 함수
    const uploadFiles = async (fileData) => {
        const uploadTasks = fileData.map(async (file) => {
            let fileListType = file.type.substr(0, 5);   // 이미지/비디오 유형 정리용
            let storageRef;

            if (fileListType === 'image') {
                if (file.thumbnail === "Y") {
                    storageRef = ref(storage, 'images/thumbnails/' + file.name);
                    imgThUrl = storageRef._location.path;
                } else {
                    storageRef = ref(storage, 'images/' + file.name);
                    imgUrl = storageRef._location.path;
                }
            } else if (fileListType === 'video') {
                storageRef = ref(storage, 'videos/' + file.name);
                imgUrl = storageRef._location.path;
                imgThUrl = '';
            }

            const task = uploadBytesResumable(storageRef, file);
            const snapshot = await task;

            // 이미지 다운로드 URL 가져오기
            const downloadURL = await getDownloadURL(snapshot.ref);

            // 이미지 표시
            setContentURL(downloadURL); // setContentURL useState를 통해 상태를 관리하는 함수

            if (fileListType === 'image') {
                if (file.thumbnail === "N") {
                    fileUrl = downloadURL;
                } else if (file.thumbnail === "Y") {
                    thumbnailUrl = downloadURL;
                }
            } else if (fileListType === 'video') {
                fileUrl = downloadURL;
                thumbnailUrl = '';
            }

            return downloadURL;
        });

        await Promise.all(uploadTasks);
    };

    //업로드 시 호출될 함수
    const handleContentUpload = async (e, fileData) => {
        e.preventDefault();
    
        // 파일이 선택되지 않은 경우 경고 메시지 표시
        if (!upFileName) {
            showAlert("error", "업로드 파일 미선택", "업로드할 파일을 선택해주세요.");
            return;
        }
    
        // 직원 구분이 선택되지 않은 경우 경고 메시지 표시
        if (!selCategory) {
            showAlert("warning", "카테고리 미선택", "카테고리를 선택해주세요.");
            return;
        }
    
        // 중복 이미지인지 확인하는 쿼리 실행
        // const q = query(collection(db, "contents"), where("filename", "==", upFileName));
        // const querySnapshot = await getDocs(q);
        // if (querySnapshot.docs.length > 0) {
        //     showAlert("warning", "중복 이미지", "해당 파일은 이미 존재합니다.");
        //     return;
        // }
    
        // 이미지 업로드 처리
        try {
            if (contentData === "NEW") {
                await uploadFiles(fileData);
                const now = new Date();
                let fileid = '';
    
                fileData.forEach((file) => {
                    const fileListType = file.type.substr(0, 5);
                    if (fileListType === 'image' && file.thumbnail === "N" && fileUrl !== '' && thumbnailUrl !== '') {
                        fileid = sysDiv === "CA" ? `${sysDiv}${userGroup}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}IMG` : `${sysDiv}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}IMG`;
                    } else if (fileListType === 'video' && fileUrl !== '') {
                        fileid = sysDiv === "CA" ? `${sysDiv}${userGroup}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}VIDEO` : `${sysDiv}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}VIDEO`;
                    }
                });
    
                await createContentInfo(fileid, fileUrl, thumbnailUrl, imgUrl, imgThUrl, contentName, upFileName, selCategory, fileData[0].type.substr(0, 5));
    
                setUpFileName("");
                setContentName("");
                document.getElementById("_contentname").value = "";
                document.getElementById("_fileinfo").value = "";
    
                showAlert("success", "컨텐츠 등록", "컨텐츠 등록이 완료되었습니다.");
            } else if (contentChgYn) {
                await uploadFiles(fileData);
    
                await updateContentInfo(contentData.fileid, fileUrl, thumbnailUrl, imgUrl, imgThUrl, contentName, upFileName, selCategory, fileData[0].type.substr(0, 5));
    
                showAlert("success", "컨텐츠 편집", "컨텐츠 편집이 완료되었습니다.");
            } else {
                await updateDoc(doc(db, "contents", contentData.fileid), {
                    cateval: selCategory,
                    repcateval: selCategory.replace(/[^\w가-힣]/g, ''),
                    tag: contentName,
                });
    
                showAlert("success", "컨텐츠 편집", "컨텐츠 편집이 완료되었습니다.");
            }
        } catch (error) {
            console.error("이미지 업로드 중 오류 발생:", error);
            showAlert("error", "이미지 업로드 오류", "이미지 업로드 중 오류가 발생했습니다. 다시 시도해주세요.");
        }
    };

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background" >
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background">
                        <div>
                            <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>컨텐츠 업로드</div>
                            <div className="contents_upload_flex">
                                {contentURL ? (
                                    fileType === "image" ? (
                                            // 이미지가 있는 경우 이미지 표시
                                            <img src={contentURL} alt="업로드된 이미지" className="contentupload_area image" />
                                    ) : (
                                        <div className="contentupload_area">
                                            <video width="100%" height="100%" key={contentURL} controls>
                                                <source 
                                                    src={contentURL}
                                                    type="video/mp4"
                                                ></source>    
                                            </video>
                                        </div>
                                    )
                                ) : (
                                    // 이미지가 없는 경우 안내 메시지 표시
                                    <div className="contentupload_image_area">
                                        <img src={NoImage} alt="이미지없음" style={{width:"3.125rem",height:"3.125rem"}} />
                                        <div>이미지를 추가해주세요.</div>
                                    </div>
                                )}
                                <div className="contents_upload_option">
                                    <div className="renew_question_mod_subtitle">카테고리</div>
                                    <div style={{display:'inline-flex',padding:"0.75rem 1.125rem 0.75rem 0.875rem",justifyContent:'center',width:"18.75rem",
                                                alignItems:'center',borderRadius:"0.5rem",background:'linear-gradient(0deg,#191919 0%,#191919 100%), #FFF'}}
                                    >
                                        <select style={{fontSize:"1rem",fontWeight:500,color:'#FFF',flexGrow:1}}
                                                value={selCategory}
                                                onChange={(e)=>setSelCategory(e.target.value)}
                                        >
                                            {/* <option disabled>카테고리 선택</option> */}
                                            {cateList.map((option) => (
                                                <option
                                                    key={option.id}
                                                    value={option.cateval}
                                                    style={{color:"#000"}}
                                                >
                                                    {option.cateval}
                                                </option>
                                            ))}
                                        </select>
                                        <img src={CateSelect} alt="콤보" style={{width:24,height:24}} />
                                    </div>
                                    <div className="renew_question_mod_subtitle" style={{marginTop:"2rem"}}>제목</div>
                                    <input id="_contentname" 
                                            className="renew_employee_mod"
                                            placeholder="제목"
                                            defaultValue={contentName}
                                            onChange={(e)=>setContentName(e.target.value)}
                                    ></input>
                                    <div className="renew_question_mod_subtitle" style={{marginTop:"2rem"}}>이미지 첨부</div>
                                    <div style={{display:'flex'}}>
                                        <input 
                                            // multiple //파일 여러개올릴때
                                            accept='image/*, video/*'
                                            type="file"
                                            ref={fileInput}
                                            style={{display:"none"}}
                                            onChange={handleContentChange}
                                        ></input>
                                        <input id="_fileinfo" className="renew_employee_mod"
                                            style={{width:"13.25rem"}} 
                                            value={upFileName}
                                            readOnly
                                        ></input>
                                        <button style={{width:"5rem",height:"3rem",flexShrink:0,borderRadius:"0.5rem",border:'1px solid #7962F6',
                                                        color:'#7962F6',fontSize:"1rem",fontWeight:600,marginLeft:"0.5rem"}}
                                                onClick={()=>fileInput.current.click()}
                                        >파일첨부</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:"14.69rem"}}>
                            <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF'}}
                                    onClick={()=>navigate(-1)}
                            >목록</button>
                            <button className="renew_evasignup" style={{marginLeft:"1rem"}}
                                    onClick={(e) =>handleContentUpload(e, fileData)}
                            >완료</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentUpload