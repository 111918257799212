import React, { useRef, useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, getDocs, collection, query, orderBy, where } from "firebase/firestore";
import { storage, db } from '../firebase_config';
import { useNavigate, useLocation } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import Swal from "sweetalert2";

import CateSelect from '../images/renewal/Category_select.svg';


function EasyUpload(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const userGroup = props.userGroup;

    const [isLoading, setIsLoading] = useState(false);
    const [fileData, setFileData] = useState();     //파일 리스트
    const [upFileName, setUpFileName] = useState("");   //원본파일이름
    const [patientList, setPatientList] = useState([]);
    const [selPatient, setSelPatient] = useState("");

    const fileInput = useRef(null);
    const fileUploadButton = useRef(null);

    let fileUrl = '';    //원본파일 url값
    let imgUrl = '';     //원본파일 firestore 경로

    useEffect(() => {
        if (location.state?.autoClickFileUpload) {
          fileUploadButton.current.click();
        }
    }, [location]);

    useEffect(() => {
        const fetchData = async () => {
            let now = new Date();
            let todayYear = now.getFullYear();
            let todayMonth = now.getMonth() + 1;
            let todayDate = now.getDate();
            let registerDate = String(todayYear) + String(todayMonth) + String(todayDate);
            
            const docRef = collection(db, "evapatient");
            const q = query(docRef, where("groupid", "==", userGroup), where("regdate", "==", registerDate),
                    orderBy("username"));

            const snapshot = await getDocs(q);
            const data = snapshot.docs.map(doc => {
                const docData = doc.data();
                return {
                    ...docData,
                    id: doc.id,
                    userinfo: `${docData.username || ''} ${docData.userphone || ''}`.trim()
                };
            });

            setPatientList(data);
        };

        fetchData();
    }, [userGroup]);

    // 파일 선택시 파일리스트 상태 변경해주는 함수
    const handleContentChange = async (e) => {
        // 여러 콘텐츠를 동시에 올리는 경우 아래 주석으로 살리기
        // for(const content of e.target.files){
        //     setFileList((prevState)=>[...prevState, content]);
        // }
        const uploadFile = Array.from(e.target.files)

        // 원본 리사이즈 로직
        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 3840
        }

        let file = uploadFile[0];
        
        let uploadFileType = uploadFile[0].type.substr(0,5);

        if(uploadFileType === "image") {
            Swal.fire({
                icon: "info",
                title: "이미지 압축중",
                text: "첨부파일 항목에 파일 이름이 생길때까지 기다려주세요",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })

            let now = new Date();
            let todayMonth = now.getMonth() + 1;
            let todayDate = now.getDate();
            let todayHour = now.getHours();
            let todayMin = now.getMinutes();
            let todaySec = now.getSeconds();
            let filename

            filename = "IMG"+String(todayMonth)+String(todayDate)+String(todayHour)+
                    String(todayMin)+String(todaySec)+userGroup;

            let compressedFile = [];
            compressedFile[0] =  await imageCompression(file, options);
            compressedFile[0].name = filename; 
            
            setFileData(compressedFile); //원본 리사이즈해서 올릴때
            //setFileData(uploadFile);   //원본 그대로 올릴때
            setUpFileName(uploadFile[0].name);
        } else if(uploadFile[0].type === "video/mp4" || uploadFile[0].type === "video/quicktime") {
            setFileData(uploadFile)
            setUpFileName(uploadFile[0].name);
        } else {
            showAlert("error", "지원하지 않는 형식의 파일", "mp4 또는 mov 형식의 파일을 업로드 해주세요");
        }
    };

    // 파일 업로드 함수
    const uploadFiles = async (fileData) => {
        const uploadTasks = fileData.map(async (file) => {
            let fileListType = file.type.substr(0, 5);   // 이미지/비디오 유형 정리용
            let storageRef;

            if (fileListType === 'image') {
                storageRef = ref(storage, 'patientcontents/images/' + file.name);
                imgUrl = storageRef._location.path;
            } else if (fileListType === 'video') {
                storageRef = ref(storage, 'patientcontents/videos/' + file.name);
                imgUrl = storageRef._location.path;
            }

            const task = uploadBytesResumable(storageRef, file);
            const snapshot = await task;

            // 이미지 다운로드 URL 가져오기
            const downloadURL = await getDownloadURL(snapshot.ref);

            if (fileListType === 'image') {
                fileUrl = downloadURL;
            } else if (fileListType === 'video') {
                fileUrl = downloadURL;
            }

            return downloadURL;
        });

        await Promise.all(uploadTasks);
    };

    //파일정보 테이블에 저장하는 로직
    const createContentInfo = async (fileid, fileUrl, imgUrl, upFileName, selPatient, fileListType) => {
        const now = new Date();
        try{
            // 선택된 환자의 정보를 찾습니다
            const selectedPatient = patientList.find(patient => patient.id === selPatient);

            if (!selectedPatient) {
                console.error("Selected patient not found");
                return;
            }

            await setDoc(doc(db, "patientcontents", fileid),{
                fileAddr: fileUrl,
                imgUrl: imgUrl,
                uploadUser: props.userNameData,
                patientname: selectedPatient.username, // 환자 이름,
                filename: upFileName,
                filetype: fileListType,
                fileid: fileid,
                groupid: userGroup,
                uploadtime: now,
                userbirth: selectedPatient.userbirth, // 환자 생년월일
                userphone: selectedPatient.userphone, // 환자 전화번호
            })
        } catch(error){
            console.log(error.message);
        }
    }

    //업로드 시 호출될 함수
    const handleContentUpload = async (e, fileData) => {
        e.preventDefault();
    
        // 파일이 선택되지 않은 경우 경고 메시지 표시
        if (!upFileName) {
            showAlert("error", "업로드 파일 미선택", "업로드할 파일을 선택해주세요.");
            return;
        }
    
        // 환자가 선택되지 않은 경우 경고 메시지 표시
        if (!selPatient) {
            showAlert("warning", "환자 미선택", "환자를 선택해주세요.");
            return;
        }
    
        // 이미지 업로드 처리
        try {
            setIsLoading(true);
            await uploadFiles(fileData);
            const now = new Date();
            let fileid = '';

            fileData.forEach((file) => {
                const fileListType = file.type.substr(0, 5);
                if (fileListType === 'image' && fileUrl !== '') {
                    fileid = `${userGroup}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}IMG`;
                } else if (fileListType === 'video' && fileUrl !== '') {
                    fileid = `${userGroup}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}VIDEO`;
                }
            });

            await createContentInfo(fileid, fileUrl, imgUrl, upFileName, selPatient, fileData[0].type.substr(0, 5));

            setUpFileName("");
            document.getElementById("_fileinfo").value = "";
            setSelPatient(""); // selPatient 상태를 리셋

            showAlert("success", "컨텐츠 등록", "컨텐츠 등록이 완료되었습니다.");
        } catch (error) {
            console.error("이미지 업로드 중 오류 발생:", error);
            showAlert("error", "이미지 업로드 오류", "이미지 업로드 중 오류가 발생했습니다. 다시 시도해주세요.");
        } finally {
            setIsLoading(false);
        }
    };

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background" >
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background">
                        <div>
                            <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>간편 업로드</div>
                            <div className="contents_upload_flex">
                                <div className="renew_question_mod_subtitle">자료 첨부</div>
                                <div style={{display:'flex'}}>
                                    <input 
                                        // multiple //파일 여러개올릴때
                                        accept='image/*, video/*'
                                        type="file"
                                        ref={fileInput}
                                        style={{display:"none"}}
                                        onChange={handleContentChange}
                                    ></input>
                                    <input id="_fileinfo" className="renew_employee_mod"
                                        style={{width:"13.25rem"}} 
                                        value={upFileName}
                                        readOnly
                                    ></input>
                                    <button style={{width:"5rem",height:"3rem",flexShrink:0,borderRadius:"0.5rem",border:'1px solid #7962F6',
                                                    color:'#7962F6',fontSize:"1rem",fontWeight:600,marginLeft:"0.5rem"}}
                                            ref={fileUploadButton}
                                            onClick={()=>fileInput.current.click()}
                                    >파일첨부</button>
                                </div>
                                <div className="renew_question_mod_subtitle" style={{marginTop:"2rem"}}>환자선택</div>
                                <div style={{display:'inline-flex',padding:"0.75rem 1.125rem 0.75rem 0.875rem",justifyContent:'center',width:"18.75rem",
                                            alignItems:'center',borderRadius:"0.5rem",background:'linear-gradient(0deg,#191919 0%,#191919 100%), #FFF'}}
                                >
                                    <select style={{fontSize:"1rem",fontWeight:500,color:'#FFF',flexGrow:1}}
                                            value={selPatient}
                                            onChange={(e)=>setSelPatient(e.target.value)}
                                    >
                                        <option value="" style={{color:"#000"}}>환자 선택</option>
                                        {patientList.map((option) => (
                                            <option
                                                key={option.id}
                                                value={option.id}
                                                style={{color:"#000"}}
                                            >
                                                {option.userinfo}
                                            </option>
                                        ))}
                                    </select>
                                    <img src={CateSelect} alt="콤보" style={{width:24,height:24}} />
                                </div>
                            </div>
                            <div style={{display:'flex',justifyContent:'center',marginTop:"14.69rem"}}>
                                {isLoading ? (
                                    <button className="renew_evasignup" disabled style={{cursor:'not-allowed'}}>업로드 중</button> // 로딩 중 표시
                                ) : (
                                    <>
                                        <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF'}}
                                                onClick={()=>navigate(-1)}
                                        >뒤로</button>
                                        <button className="renew_evasignup" style={{marginLeft:"1rem"}}
                                                onClick={(e) =>handleContentUpload(e, fileData)}
                                        >완료</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EasyUpload