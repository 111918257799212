import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDoc, getDocs, doc, setDoc, updateDoc, orderBy, query, where } from "firebase/firestore";
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config';
import Swal from "sweetalert2";


function EvaSurvey(props) {
    const navigate = useNavigate();
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const userInfo = state.userinfo;

    const [empData, setEmpData] = useState([]);
    const [quesData, setQuesData] = useState([]);

    let resultValue = [];

    const fetchDataCallback = useCallback(async () => {
        const employeeInfo = [];
    
        async function renderEmployeeInfo(empId) {
            const docRef = doc(db, "employees", empId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return docSnap.data();
            } else {
                return null;
            }
        }
    
        async function renderQuestions() {
            const docRef = collection(db, "questionnaire");
            const q = query(docRef, where("groupid", "==", userInfo.groupid),
                                        orderBy("quesno")
                        );
            const snapshot = await getDocs(q);
            return snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        }
    
        const empIds = [
            userInfo.dentist1st, userInfo.dentist2nd, userInfo.dentist3rd, userInfo.dentist4th, userInfo.dentist5th,
            userInfo.staff1st, userInfo.staff2nd, userInfo.staff3rd, userInfo.staff4th, userInfo.staff5th
        ];
    
        for (const empId of empIds) {
            if (empId !== 'none') {
                const empData = await renderEmployeeInfo(empId);
                employeeInfo.push(empData);
            }
        }

        const qData = await renderQuestions();
    
        setEmpData(employeeInfo);
        setQuesData(qData);
    }, [userInfo]);

    useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback]);

    const handleClickRadioButton = (e) => {
        let i = e.target.name - 1;
        const quesno = parseInt(e.target.name);

        // quesData에서 현재 문항의 fileid 값을 가져옵니다.
        const currentQuestion = quesData.find(question => question.quesno === quesno && question.empdiv === empData[0].empdiv);
        const fileid = currentQuestion ? currentQuestion.fileid : null;
        const quesdiv = currentQuestion ? currentQuestion.quesdiv : null;
        const question = currentQuestion ? currentQuestion.question : null;

        let value;

        // 라디오 버튼의 값을 0부터 "vb", "b", "n", "g", "vg"로 변환합니다.
        if(quesdiv === "Multiple") {
            switch (parseInt(e.target.value)) {
                case 0:
                    value = "vg";
                    break;
                case 1:
                    value = "g";
                    break;
                case 2:
                    value = "n";
                    break;
                case 3:
                    value = "b";
                    break;
                case 4:
                    value = "vb";
                    break;
                default:
                    value = e.target.value;
            }
        }else {
            value = e.target.value;
        }

        resultValue[i] = { fileid, value, quesdiv, question };
    }

    // 다음 담당자 이미지를 표시하기 위한 함수
    const handleNextButtonClick = () => {
        const filteredQuesData = quesData.filter(data => data.empdiv === empData[0].empdiv);
        // 답하지 않은 문항을 찾습니다.
        const unansweredQuestions = filteredQuesData.filter(data => {
            if (data.quesdiv === "Multiple") {
                // 다중 선택 질문의 경우 선택된 항목이 있는지 확인합니다.
                const checkedOptions = document.querySelectorAll(`input[name="${data.quesno}"]:checked`);
                return checkedOptions.length === 0;
            } else {
                // 텍스트 입력 질문의 경우 값이 있는지 확인합니다.
                const textArea = document.querySelector(`textarea[name="${data.quesno}"]`);
                return !textArea.value.trim();
            }
        });

        // 답하지 않은 문항이 있는 경우 알림 창을 띄웁니다.
        if(unansweredQuestions.length > 0) {
            showAlert("warning","고객만족도 조사","아직 입력하지 않은 항목이 있습니다");
            return;
        }else if (empData.length > 1) {
            insertSurveyData(resultValue);
            setEmpData(prevEmpData => prevEmpData.slice(1)); // 첫 번째 요소를 제외한 배열로 업데이트
        }else {
            insertSurveyData(resultValue);
            SurveyComplete();
            Swal.fire({
                icon: "success",
                title: "고객만족도 조사",
                text: "고객만족도 조사가 완료되었습니다",
                confirmButtonText: "확인",
            }).then((res) => {
                if (res.isConfirmed) {
                    // 확인 요청 처리
                    navigate("/evaSurveyFront",{
                        state: {
                            code: "Eva",
                        },
                    });
                }
            });
        }
    };

    const insertSurveyData = async (surveyData) => {
        try {
            // surveyData 배열을 순회하여 각 데이터에 대해 setDoc을 호출합니다.
            for (let i = 0; i < surveyData.length; i++) {
                const now = new Date();
                const todayYear = now.getFullYear();
                const todayMonth = now.getMonth() + 1;
                const todayDate = now.getDate();
                const registerDate = String(todayYear)+String(todayMonth)+String(todayDate);
                const fileId = userInfo.fileid + empData[0].fileid + surveyData[i].fileid;

                await setDoc(doc(db, "surveydatas", fileId), {
                    fileid: fileId,
                    patientid: userInfo.fileid,
                    questionid: surveyData[i].fileid,
                    managerid: empData[0].fileid,
                    managername: empData[0].name,
                    question: surveyData[i].question,
                    resultval: surveyData[i].value,
                    regdate: registerDate,
                    quesdiv: surveyData[i].quesdiv,
                    regdatetime: now
                });
            }
        } catch (error) {
            console.log("에러메세지:", error.message);
        }
    };

    const SurveyComplete = async () => {
        try{
            const docRef = doc(db, "evapatient", userInfo.fileid);
            await updateDoc(docRef, {
              surveyYn: "Y"
            })
            
            let DiagTimeData = null;
            if(userInfo.diagtimestart !== "" && userInfo.diagtimestart !== null){
                DiagTimeData = new Date(userInfo.diagtimestart.seconds * 1000 + userInfo.diagtimestart.nanoseconds / 1000000);
            }
            
            await setDoc(doc(db, "crmsurveynotification", userInfo.fileid),{
                patientname: userInfo.username,
                patientid: userInfo.fileid,
                regdate: new Date(),
                diagtimestart: DiagTimeData,
                confirmyn: "N",
                groupid: userInfo.groupid
            });
        } catch(error){
            console.log(error.message);
        }
    }

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva">
                        <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>{userInfo.username}님 진료는 어떠셨나요?</div>
                        {empData.length > 0 && (
                            <div className="flex_direction">
                                <div className="evasurvey_image_area">
                                    <img alt='담당자이미지' src={empData[0].fileAddr} style={{width:"15rem",height:"22.5rem",borderRadius:"0.625rem",background:'#F8F8F8',marginBottom:"1rem"}} />
                                    <div className="renew_employ_name">
                                        {empData[0].name}
                                        <div style={{ color: '#858585', marginLeft: 5 }}>
                                            {empData[0].empdiv === "Dentist" ? "원장" : "선생"}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {quesData
                                        .filter(data => empData[0].empdiv === data.empdiv)
                                        .map(data => (
                                            <div key={data.id}>
                                                <div className="renew_question">
                                                    <div style={{color:'#C39F56',marginRight:5}}>Q.</div>
                                                    {data.question}
                                                </div>
                                                {data.quesdiv === "Multiple" && (
                                                    <div className="evasurvey_multiple">
                                                        {['매우만족', '만족', '보통', '불만', '매우불만'].map((option, index) => (
                                                            <div key={index} className="renew_question_multiple">
                                                                <input type="radio" value={index} name={data.quesno} onChange={handleClickRadioButton}
                                                                    style={{width:"0.8125rem",height:"0.8125rem",strokeWidth:1,stroke:'#BEBEBE'}} />
                                                                <div>{option}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {data.quesdiv !== "Multiple" && <textarea name={data.quesno} className="evasurvey_textarea"
                                                    onChange={handleClickRadioButton} />}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )}
                        <div style={{display:'flex',justifyContent:'center',marginTop:"7.5rem"}}>
                            <button className="renew_evasignup" onClick={handleNextButtonClick}>다음</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaSurvey;