import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

import SelectOn from '../images/renewal/SelectOn.svg';
import SelectOff from '../images/renewal/SelectOff.svg';


function TermsAgree() {
    const navigate = useNavigate();

    const [checkAll, setCheckAll] = useState("N");
    const [checkPerson, setCheckPerson] = useState("N");
    const [checkEvent, setCheckEvent] = useState("N");
    const [checkUse, setCheckUse] = useState("N");

    const toSignUpPage = () => {
        if(checkPerson === "Y"){
            navigate("/signup", {
                state: {
                    checkperson: checkPerson,
                    checkevent: checkEvent,
                    checkuse: checkUse
                },
            })
        }else{
            Swal.fire({
                icon: "error",
                title: "필수항목 미동의",
                text: "동의하지 않은 필수 항목이 존재합니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        }
    }

    return (
        <div className="renew_background signup">
            <div className="renew_content_background">
                <div>
                    <div className="renew_content_title" >약관동의</div>
                    <div className="content_area" style={{color:'#373737',fontSize:"1.125rem",fontWeight:500}}>
                        <div className="termsagree_unit_wrap" style={{borderBottom:"1px #191919 solid"}}>
                            <div style={{display:'flex'}}>
                                <div style={{color:'#C39F56',marginRight:"1.94rem",fontWeight:600}}>전체</div>
                                <div>전체 동의</div>
                            </div>
                            <img src={checkAll === "Y" ? SelectOn : SelectOff} alt="선택" style={{width:24,height:24,cursor:'pointer'}}
                                onClick={()=>{
                                    if(checkAll === "N"){
                                        setCheckAll("Y");
                                        setCheckPerson("Y");
                                        setCheckEvent("Y");
                                        setCheckUse("Y");
                                    }else{
                                        setCheckAll("N");
                                        setCheckPerson("N");
                                        setCheckEvent("N");
                                        setCheckUse("N");
                                    }
                                }}
                            />
                        </div>
                        <div className="termsagree_unit_wrap" style={{borderBottom:'1px solid #E8E8E8'}}>
                            <div style={{display:'flex'}}>
                                <div style={{color:'#FF594F',marginRight:"1.94rem",fontWeight:600}}>필수</div>
                                <div>개인정보 수집 및 동의</div>
                            </div>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <div style={{color:'#A0A0A0',fontSize:"0.875rem",marginRight:"2.5rem",cursor:'pointer'}}
                                    onClick={()=>navigate("/termspersonal")}
                                >약관 상세보기</div>
                                <img src={checkPerson === "Y" ? SelectOn : SelectOff} alt="선택" style={{width:24,height:24,cursor:'pointer'}}
                                    onClick={()=>{
                                        if(checkPerson === "N"){
                                            setCheckPerson("Y");
                                            if(checkEvent === "Y" && checkUse === "Y"){
                                                setCheckAll("Y")
                                            }
                                        }else{
                                            setCheckAll("N");
                                            setCheckPerson("N");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="termsagree_unit_wrap" style={{borderBottom:'1px solid #E8E8E8'}}>
                            <div style={{display:'flex'}}>
                                <div style={{color:'#858585',marginRight:"1.94rem",fontWeight:600}}>선택</div>
                                <div>이벤트 정보 제공 및 동의</div>
                            </div>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <div style={{color:'#A0A0A0',fontSize:"0.875rem",marginRight:"2.5rem",cursor:'pointer'}}
                                    onClick={()=>navigate("/termsevent")}
                                >약관 상세보기</div>
                                <img src={checkEvent === "Y" ? SelectOn : SelectOff} alt="선택" style={{width:24,height:24,cursor:'pointer'}}
                                    onClick={()=>{
                                        if(checkEvent === "N"){
                                            setCheckEvent("Y");
                                            if(checkPerson === "Y" && checkUse === "Y"){
                                                setCheckAll("Y")
                                            }
                                        }else{
                                            setCheckAll("N");
                                            setCheckEvent("N");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="termsagree_unit_wrap" style={{borderBottom:'1px solid #E8E8E8'}}>
                            <div style={{display:'flex'}}>
                                <div style={{color:'#858585',marginRight:"1.94rem",fontWeight:600}}>선택</div>
                                <div>이용 약관</div>
                            </div>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <div style={{color:'#A0A0A0',fontSize:"0.875rem",marginRight:"2.5rem",cursor:'pointer'}}
                                    onClick={()=>navigate("/termsuse")}
                                >약관 상세보기</div>
                                <img src={checkUse === "Y" ? SelectOn : SelectOff} alt="선택" style={{width:24,height:24,cursor:'pointer'}}
                                    onClick={()=>{
                                        if(checkUse === "N"){
                                            setCheckUse("Y");
                                            if(checkPerson === "Y" && checkEvent === "Y"){
                                                setCheckAll("Y")
                                            }
                                        }else{
                                            setCheckAll("N");
                                            setCheckUse("N");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"13.38rem"}}>
                    {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                    <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF'}}
                            onClick={()=>toSignUpPage()}
                    >다음</button>
                </div>
            </div>
        </div>
    )
}

export default TermsAgree;