import React, { useState, useEffect, useCallback } from 'react';
import { ref, deleteObject } from 'firebase/storage';
import { doc, getDocs, collection, query, orderBy, where, getDoc, deleteDoc } from "firebase/firestore";
import { storage, db } from '../firebase_config';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from "sweetalert2";

import Refresh from '../images/renewal/Refresh.svg';

function PatientContentList(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState('');
    const [contentList, setContentList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUserInfo = useCallback(async () => {
        setIsLoading(true);
        const params = new URLSearchParams(location.search);
        const userdata = params.get('userinfo');
        if (userdata) {
            const docRef = doc(db, "evapatient", userdata);
            try {
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setUserInfo(docSnap.data());
                    
                    const contentRef = collection(db, "patientcontents");
                    const q = query(contentRef, 
                        where("userbirth", "==", docSnap.data().userbirth),
                        where("userphone", "==", docSnap.data().userphone),
                        orderBy("uploadtime", "desc")
                    );
                    const snapshot = await getDocs(q);
                
                    const contentData = snapshot.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id
                    }));

                    setContentList(contentData);
                    console.log("content: ", contentData);
                } else {
                    console.log("No such document!");
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [location]);

    useEffect(() => {
        fetchUserInfo();
    }, [fetchUserInfo]);

    const handleRefresh = () => {
        fetchUserInfo();
    };

    const handleDelete = async (option) => {
        try {
            // Firestore에서 문서 삭제
            await deleteDoc(doc(db, "patientcontents", option.id));
    
            // Storage에서 파일 삭제
            const storageRef = ref(storage, option.fileAddr);
            await deleteObject(storageRef);
    
            // 상태 업데이트 (contentList에서 해당 항목 제거)
            setContentList(prevList => prevList.filter(item => item.id !== option.id));
    
            showAlert("success", "삭제완료", "데이터가 성공적으로 삭제되었습니다.");
        } catch (error) {
            showAlert("error", "삭제오류", "삭제 중 오류가 발생했습니다. 관리자에게 문의해주세요.");
        }
    };

    //매개변수를 넘기면서 그림판 페이지로 이동
    const toDrawPage = (docData) => {
        navigate("/drawPage", {
            state: {
                fileinfo: docData,
                from: "patientcontent",
            },
        });
    }

    //매개변수를 넘기면서 동영상 페이지로 이동
    const toViewVideo = (docData) => {
        navigate("/viewVideo", {
            state: {
                fileinfo: docData,
                from: "patientcontent",
            },
        });
    }

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background" >
            <div className="content_align">
                <div className="content_maxwidth crm">
                    <div className="renew_content_background patientcontent">
                        <div>
                            <div style={{display:'flex',alignItems:'center',marginBottom:"4rem"}}>
                                <div className="renew_content_title" >{userInfo.username}님 자료</div>
                                <div className="renew_rectangle_btn" style={{marginLeft:"1.5rem",marginRight:"0.5rem"}}
                                    onClick={handleRefresh}
                                >
                                    <img src={Refresh} alt="새로고침" style={{width:18,height:18}} />
                                </div>
                            </div>
                            {isLoading && <div />}
                            <div className="notice_header">
                                <div style={{width:"50%"}}>제목</div>
                                <div style={{width:"20%"}}>등록일자</div>
                                <div style={{width:"10%"}}>타입</div>
                            </div>
                            {contentList.map((option, index) => {
                                const regDate = option.uploadtime.toDate(); // Firebase Timestamp를 JavaScript Date 객체로 변환

                                // 현재 시간과의 차이 계산
                                const currentTime = new Date();
                                const diffTime = currentTime - regDate;
                                const diffMinutes = Math.floor(diffTime / (1000 * 60)); // 분 단위로 변환
                            
                                let formattedDate = '';
                            
                                if (diffMinutes < 1) {
                                    formattedDate = '방금';
                                } else if (diffMinutes < 60) {
                                    formattedDate = `${diffMinutes}분 전`;
                                } else if (currentTime.getDate() === regDate.getDate()) {
                                    formattedDate = `${regDate.getHours()}:${('0' + regDate.getMinutes()).slice(-2)}`;
                                } else {
                                    formattedDate = `${('0' + (regDate.getMonth() + 1)).slice(-2)}-${('0' + regDate.getDate()).slice(-2)}`;
                                }

                                let fileType;

                                if(option.filetype === "image"){
                                    fileType = "이미지"
                                }else{
                                    fileType = "비디오"
                                }

                                return (
                                    <div key={option.id} style={{display:'flex',color:'#373737',fontSize:"1.125rem",fontWeight:500,padding:'0.53rem 0rem',
                                        borderBottom:'1px #E8E8E8 solid',alignItems:'center'}}>
                                        <div style={{width:"50%",cursor:'pointer'}}
                                            onClick={async ()=>{
                                                if(option.filetype === "image"){
                                                    toDrawPage(option);
                                                }else{
                                                    toViewVideo(option);
                                                }
                                            }}
                                        >{option.filename}</div>
                                        <div style={{width:"20%"}}>{formattedDate}</div>
                                        <div style={{width:"10%"}}>{fileType}</div>
                                        <div style={{width:"20%",display:'flex',justifyContent:'center'}}>
                                            <button style={{width:"5rem",background:"#191919",color:"#FFF",
                                                            borderRadius:"0.3rem",padding:"0.5rem",fontSize:"1rem",fontWeight:600}}
                                                    onClick={() => {
                                                        Swal.fire({
                                                            icon: "question",
                                                            title: "데이터 삭제",
                                                            text: "데이터 삭제를 하시겠습니까?",
                                                            showCancelButton: true,
                                                            confirmButtonText: "삭제",
                                                            cancelButtonText: "취소",
                                                        }).then(async (res) => {
                                                            if (res.isConfirmed) {
                                                                handleDelete(option)
                                                            }
                                                        });
                                                    }}
                                            >삭제</button>
                                        </div>
                                    </div>
                                    )
                                }
                            )}
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:"2rem"}}>
                            <button className="renew_evasignup" onClick={() => window.close()}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientContentList