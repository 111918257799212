import React, { useState } from 'react';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, orderBy, doc, updateDoc } from "firebase/firestore";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Swal from "sweetalert2";

function EvaEmpMngAdm(props) {
    const [empData, setEmpData] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);  //달력 보이기 기능
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [selectedDurDate, setSelectedDurDate] = useState([]);

    const [empId, setEmpId] = useState(""); //직원검색용
    const [filteredData, setFilteredData] = useState([]);

    const callDb = async (empId, selectedDurDate) => {
        // let modDurDate;

        // if(selectedDurDate.length === 2){
        //     let startDate;
        //     let endDate;

        //     for(let i=0;i<selectedDurDate.length;i++){
        //         if(i === 0 ){
        //             startDate = selectedDurDate[i]
        //         }else if( i === 1){
        //             endDate = selectedDurDate[i]
        //         }
        //     }

        //     const newStartDate = addOneMonthToDate(startDate);
        //     const newEndDate = addOneMonthToDate(endDate);

        //     modDurDate = [newStartDate, newEndDate];
        // }

        //직원 데이터 조회 변수
        const q = query( collection(db, "employees"),
                         where("fileid", "==", empId),
                        //  where("groupid", "==", props.userGroup),
                         orderBy("empno")
                        );
        const snapshot = await getDocs(q);

        const empInfoData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        //setEmpData(empInfoData);
        console.log("empinfo", empInfoData)

        empInfoData.map(async (doc) => {
        //진료시간 데이터 조회 변수
        const diagTimeRef = collection(db, "diagtime");
        let dq;

        if( selectedDurDate && selectedDurDate.length === 2 ){
            dq = query(diagTimeRef, where("diagtimestart", ">=", selectedDurDate[0]), where("diagtimestart", "<=", selectedDurDate[1]), where("empid", "==", doc.fileid));
            const dSnapshot = await getDocs(dq); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
            const diagTimeData = dSnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));

            console.log("진료데이터: ", diagTimeData);
            const filteredDiagTimeData = diagTimeData.filter(item => item.groupid.startsWith('bd'));
            console.log("진료데이터필터: ", filteredDiagTimeData);
            setFilteredData(filteredDiagTimeData);

            let tmpDiagTime = 0;    //총 진료시간
            let tmpAveTime = 0;     //평균 진료시간

            for(let i=0;i<diagTimeData.length;i++){
                tmpDiagTime += diagTimeData[i].diagelapsemin;
            }

            if(tmpDiagTime > 0){
                tmpAveTime = Math.round(tmpDiagTime/diagTimeData.length) 
            }

            //설문조사 데이터 조회 변수 (객관식)
            const surveyRef = collection(db, "surveydatas");
            let q;

            q = query(surveyRef, where("managerid", "==", doc.id), where("quesdiv", "==", "Multiple"), where("regdatetime", ">=", selectedDurDate[0]), where("regdatetime", "<=", selectedDurDate[1]));
            const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

            const surveyData = snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));

            let tmpPoint = 0;       //총 평점
            let tmpPointAve = 0;    //평균 평점

            for(let i=0;i<surveyData.length;i++){
                if(surveyData[i].resultval === "vg"){
                    tmpPoint += 5;
                } else if(surveyData[i].resultval === "g"){
                    tmpPoint += 4;
                } else if(surveyData[i].resultval === "n"){
                    tmpPoint += 3;
                } else if(surveyData[i].resultval === "b"){
                    tmpPoint += 2;
                } else if(surveyData[i].resultval === "vb"){
                    tmpPoint += 1;
                }
            }

            if(tmpPoint > 0){
                tmpPointAve = (tmpPoint/surveyData.length).toFixed(1);
            };

            //설문조사 데이터 조회 변수 (주관식)
            let sdq;

            sdq = query(surveyRef, where("managerid", "==", doc.id), where("quesdiv", "==", "Descriptive"), where("regdatetime", ">=", selectedDurDate[0]), where("regdatetime", "<=", selectedDurDate[1]));
            const sdesSnapshot = await getDocs(sdq); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

            const sdesSurveyData = sdesSnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));

            const newData = [doc.name, diagTimeData.length, tmpDiagTime, tmpAveTime, surveyData.length, tmpPoint, tmpPointAve, sdesSurveyData.length];
            setEmpData((prevEmpData) => prevEmpData.concat([newData]));

            }
        })
    }

    const updateTime = async () => {
        // if(filteredData !== undefined){
        //     filteredData.map(async content => {
        //         const contentRef = doc(db, "diagtime", content.fileid);
        //         await updateDoc(contentRef, {
        //             empid: '31716252김민수',
        //             id: content.patientid+'31716252김민수',
        //             fileid: content.patientid+'31716252김민수'
        //         })
        //     }); 
        // }
    }

    const handleDateClick = (date) => {
        // const selYear = date.getFullYear();
        // const selMonth = date.getMonth()+1;
        // const selDay = date.getDate();
        // setDisplayDate(selYear+"년 "+selMonth+"월 "+selDay+"일");
        // setShowCalendar(!showCalendar);
        // setSelectedDate(`${selYear}${selMonth}${selDay}`);

        if (!selectedStartDate) {
            // 시작일자 선택
            setSelectedStartDate(date);
        } else if (!selectedEndDate) {
            // 끝일자 선택
            setSelectedEndDate(date);
        } else {
            // 이미 시작일자와 끝일자를 모두 선택했으므로 초기화
            setSelectedStartDate(null);
            setSelectedEndDate(null);
        }
        setSelectedDurDate(date);

        setShowCalendar(!showCalendar);
        setEmpData([]);
    };

    return (
        <div className="wrap">
        <div className="content_wrap">
            <h3 className="sub_tit">
                <span>직원 관리자</span>
            </h3>
            <input  className="report_admin_input" 
                    placeholder="직원번호" 
                    onChange={(e)=>{setEmpId(e.target.value);}}>
            </input>
            <button className="report_admin_search"
                    onClick={()=>{if(empId !== "" && selectedDurDate.length === 2){
                                        callDb(empId, selectedDurDate);
                                    }else{
                                        Swal.fire({
                                            icon: "warning",
                                            title: "빈값있음",
                                            text: "확인바람",
                                            //showCancelButton: true,
                                            confirmButtonText: "확인",
                                        }).then((res) => {
                                            /* Read more about isConfirmed, isDenied below */
                                            if (res.isConfirmed) {
                                                
                                            }
                                        });
                                    }
                                }
                            }
            >검색</button>
            <button className="report_admin_search"
                    onClick={()=>{if(empId !== "" && selectedDurDate.length === 2 && filteredData.length > 0){
                                        updateTime();
                                    }else{
                                        Swal.fire({
                                            icon: "warning",
                                            title: "빈값있거나 데이터 없음",
                                            text: "확인바람",
                                            //showCancelButton: true,
                                            confirmButtonText: "확인",
                                        }).then((res) => {
                                            /* Read more about isConfirmed, isDenied below */
                                            if (res.isConfirmed) {
                                                
                                            }
                                        });
                                    }
                                }
                            }
            >실적수정</button>
            <div>
                <button className="chg_date" onClick={()=>{ setSelectedDurDate([]);
                                                            setEmpData([]);
                                                            setSelectedStartDate(null);
                                                            setSelectedEndDate(null);
                }}>전체기간</button>
                <button className="chg_date" onClick={()=>{ setShowCalendar(!showCalendar) }}>기간 설정</button>
            </div>
            {selectedDurDate && selectedDurDate.length === 2 && (
                <div className="sel_date_dur">기간: {selectedDurDate[0].toLocaleDateString()} ~ {selectedDurDate[1].toLocaleDateString()}</div>
            )}
            <div className={`sliding_calendar ${showCalendar ? '' : 'show'}`}>
                <Calendar
                    onChange={handleDateClick}
                    value={selectedStartDate || selectedEndDate} // 선택된 날짜 표시
                    selectRange={true} // 시작일자와 끝일자를 선택할 수 있도록 설정
                />
            </div>
            <div className="report_wrap">
                <div className="emplist_header">
                    <div className="emplist_header_name">이름</div>
                    <div className="emplist_header_patcnt">진료환자 수</div>
                    <div className="emplist_header_timetot">누적진료시간(분)</div>
                    <div className="emplist_header_timeave">평균진료시간(분)</div>
                    <div className="emplist_header_pointcnt">평점(건수)</div>
                    <div className="emplist_header_pointtot">평점(총점)</div>
                    <div className="emplist_header_pointave">평점(평균)</div>
                    <div className="emplist_header_comment">코멘트 수</div>
                </div>
                {empData.map((doc) => {
                    return(
                        <div className="emplist_body" key={doc.id}>
                            <div className="emplist_body_name">{doc[0]}</div>
                            <div className="emplist_body_patcnt">{doc[1]}</div>
                            <div className="emplist_body_timetot">{doc[2]}</div>
                            <div className="emplist_body_timeave">{doc[3]}</div>
                            <div className="emplist_body_pointcnt">{doc[4]}</div>
                            <div className="emplist_body_pointtot">{doc[5]}</div>
                            <div className="emplist_body_pointave">{doc[6]}</div>
                            <div className="emplist_body_comment">{doc[7]}</div>
                        </div>
                    )
                })}   
            </div>
        </div>
        <footer>
            <span className="copyright">Copyright 2022. Dental Connect All rights reserved.</span>
        </footer>
        </div>
    );
}

export default EvaEmpMngAdm;