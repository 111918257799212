import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, } from 'react-router-dom';
import { doc, getDoc, updateDoc, setDoc, deleteDoc, query, collection, where, getDocs, orderBy } from "firebase/firestore";
import { db } from '../firebase_config';
import Swal from "sweetalert2";

import AddRed from '../images/renewal/AddRed.svg';
import Subtract from '../images/renewal/Subtract.svg';
import Delete from '../images/renewal/Delete.svg';
import Triangle from '../images/renewal/triangle.svg';
import Square from '../images/renewal/square.svg';
import Refresh from '../images/renewal/Refresh.svg';

function EvaManageBack(props) {
    const navigate = useNavigate();
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const userInfo = state.userinfo;
    const userGroup = props.userGroup;

    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [selEmpList, setSelEmpList] = useState(false);  //직원리스트 메뉴
    const [employeeList, setEmployeeList] = useState([]);
    const [empType, setEmpType] = useState(""); // "doctor" 또는 "staff"
    const [chgAddType, setChgAddType] = useState(""); // 변경인지 추가인지 구분값

    const [empData, setEmpData] = useState([]);
    const [startTime, setStartTime] = useState(
        userInfo.diagtimestart
        ? new Date(userInfo.diagtimestart.seconds * 1000 + userInfo.diagtimestart.nanoseconds / 1000000)
        : null
    );
    const [stopTime, setStopTime] = useState(
        userInfo.diagtimeend
        ? new Date(userInfo.diagtimeend.seconds * 1000 + userInfo.diagtimeend.nanoseconds / 1000000)
        : null
    );
    const windowWidth = window.innerWidth;

    const preventClose = useCallback((e) => {
        e.preventDefault();
        e.returnValue = ""; //Chrome에서 동작하도록; deprecated
    }, []);
    
    useEffect(() => {
        window.addEventListener("beforeunload", preventClose);
    
        return () => {
            window.removeEventListener("beforeunload", preventClose);
        };
    }, [preventClose]);

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault(); // 기본 뒤로가기 동작을 막습니다.
            navigate('/evaManageFront', { state: { usergroup : userGroup } });
        };
    
        window.addEventListener('popstate', handleBackButton); // 뒤로가기 이벤트를 감지합니다.
    
        return () => {
            window.removeEventListener('popstate', handleBackButton); // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
        };
    }, [navigate, userGroup]);

    const fetchDataCallback = useCallback(async () => {
        async function renderEmployeeInfo(empId) {
            const empData = await getEmployeeData(empId);
            
            if (empData) {
                return empData;
            }
            return null;
        }
        
        const employeeInfo = [];
        
        if (userInfo.dentist1st !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.dentist1st);
            employeeInfo.push(empData);
        }
        if (userInfo.dentist2nd !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.dentist2nd);
            employeeInfo.push(empData);
        }
        if (userInfo.dentist3rd !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.dentist3rd);
            employeeInfo.push(empData);
        }
        if (userInfo.dentist4th !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.dentist4th);
            employeeInfo.push(empData);
        }
        if (userInfo.dentist5th !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.dentist5th);
            employeeInfo.push(empData);
        }
        if (userInfo.staff1st !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.staff1st);
            employeeInfo.push(empData);
        }
        if (userInfo.staff2nd !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.staff2nd);
            employeeInfo.push(empData);
        }
        if (userInfo.staff3rd !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.staff3rd);
            employeeInfo.push(empData);
        }
        if (userInfo.staff4th !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.staff4th);
            employeeInfo.push(empData);
        }
        if (userInfo.staff5th !== 'none') {
            const empData = await renderEmployeeInfo(userInfo.staff5th);
            employeeInfo.push(empData);
        }

        setEmpData(employeeInfo);
    }, [userInfo]);

    useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback]);

    // RenewShowDentistList 함수 내에서 사용할 함수
    async function getEmployeeData(empId) {
        const docRef = doc(db, "employees", empId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data();
        } else {
            return null;
        }
    }

    function ShowDentistList() {
        return (
            <div>
                {empData.length === 0 ? (
                    <div style={{ width: '100%', height: 360, background: '#FAFAF8', borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#A5A5A5', fontSize: 18, fontWeight: 400 }}>
                        담당 원장/선생님을 추가해주세요
                    </div>
                ) : (
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                        {empData.map((empdata, index) => (
                            <div className="evamanageback_image_area" key={index}>
                                <img className="renew_employ_img" src={empdata.fileAddr} alt="이미지" />
                                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                    {empdata.empdiv === "Dentist" ? (
                                        <div className="renew_employ_name">{empdata.name} 
                                            <div style={{color:'#858585',marginLeft:5}}>원장</div>
                                        </div>
                                    ) : (
                                        <div className="renew_employ_name">{empdata.name} 
                                            <div style={{color:'#858585',marginLeft:5}}>선생</div>
                                        </div>
                                    )}
                                    <div>
                                        <img src={Subtract} alt="변경" style={{width:"1.5rem",height:"1.5rem",marginRight:"0.625rem",cursor:'pointer'}} 
                                            onClick={()=>{
                                                if(empdata.empdiv === "Dentist"){
                                                    handleEmpListClick("Dentist",empdata.fileid)
                                                }else{
                                                    handleEmpListClick("Staff",empdata.fileid)
                                                }
                                            }}
                                        />
                                        <img src={Delete} alt="삭제" style={{width:"1.5rem",height:"1.5rem",cursor:'pointer'}} 
                                            onClick={()=>{
                                                if(empdata.empdiv === "Dentist"){
                                                    delManager("Dentist",empdata.fileid)
                                                }else{
                                                    delManager("Staff",empdata.fileid)
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    const startDiagnostic = () => {
        if(empData.length > 0) {
            if(!startTime && !stopTime) {
                setStartTime(new Date());
            }else {
                Swal.fire({
                    icon: "error",
                    title: "진료시작 불가",
                    text: "진료시간 데이터가 존재합니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });  
            }
        }else {
            Swal.fire({
                icon: "error",
                title: "담당자 미지정",
                text: "담당자를 먼저 지정 하시기 바랍니다.",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });  
        }
    };

    const stopDiagnostic = () => {
        if(empData.length > 0) {
            if(startTime && !stopTime) {
                setStopTime(new Date());
            }else {
                Swal.fire({
                    icon: "error",
                    title: "진료종료 불가",
                    text: "진료시간 데이터가 존재합니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });  
            }
        }else {
            Swal.fire({
                icon: "error",
                title: "담당자 미지정",
                text: "담당자를 먼저 지정 하시기 바랍니다.",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });  
        }
    };

    const resetDiagnostic = () => {
        if(startTime && stopTime) {
            clearDiagData();
        }
        setStartTime(null);
        setStopTime(null);
    };

    const calculateDuration = useCallback(() => {
        if(startTime && stopTime) {
            const diff = Math.abs(stopTime - startTime);
            const minutes = Math.floor((diff / (1000 * 60)) % 60);
            return minutes;
        }
        return 0;
    }, [startTime, stopTime]);

    const makeDiagData = useCallback(async () => {
        empData.forEach(async (data) => {
            const fileId = userInfo.fileid + data.fileid;
    
            try {
                await setDoc(doc(db, "diagtime", fileId), {
                    groupid: userGroup,
                    empid: data.fileid,
                    patientid: userInfo.fileid,
                    fileid: fileId,
                    diagstart: "",
                    diagend: "",
                    diagtimestart: startTime,
                    diagtimeend: stopTime,
                    diagelapsemin: calculateDuration(),
                    regdate: new Date(),
                });
            } catch (error) {
                console.error("Error adding data:", error);
            }
        });

        try {
            await updateDoc(doc(db, "evapatient", userInfo.fileid), {
                diagstart: "",
                diagtimestart: startTime,
                diagend: "",
                diagtimeend: stopTime,
                diagelapsemin: calculateDuration()
            })
        } catch (error) {
            console.error("Error updating data:", error);
        }
    }, [empData, userInfo, startTime, stopTime, userGroup, calculateDuration]);
    
    useEffect(() => {
        if (stopTime) {
            makeDiagData();
        }
    }, [stopTime, makeDiagData]);

    const clearDiagData = async () => {
        empData.forEach(async (data) => {
            const fileId = userInfo.fileid + data.fileid;
    
            try {
                await deleteDoc(doc(db, "diagtime", fileId))
            } catch (error) {
                console.error("Error deleting data:", error);
            }
        });

        try {
            await updateDoc(doc(db, "evapatient", userInfo.fileid), {
                diagstart: "",
                diagtimestart: null,
                diagend: "",
                diagtimeend: null,
                diagelapsemin: calculateDuration()
            })
        } catch (error) {
            console.error("Error clearing data:", error);
        }
    }

    const handleEmpListClick = (empdiv, chgdiv) => {
        if(!startTime) {
            const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
            // 마우스 클릭 위치 가져오기
            const mouseX = window.event.clientX;
            const mouseY = window.event.clientY;
            const windowWidth = window.innerWidth;
            const popupWidth = 15.125 * rootFontSize;

            let popupX;

            if (mouseX > windowWidth / 2) {
                // 클릭 위치가 브라우저 중앙의 오른쪽인 경우
                popupX = mouseX - popupWidth - 10; // 10은 약간의 여백
            } else {
                // 클릭 위치가 브라우저 중앙의 왼쪽인 경우
                popupX = mouseX + 10; // 10은 약간의 여백
            }

            // 팝업 위치 설정
            setPopupPosition({ x: popupX, y: mouseY });

            // 담당자 유형 설정
            setEmpType(empdiv);
            setChgAddType(chgdiv);
            setSelEmpList(!selEmpList);
        }else {
            Swal.fire({
                icon: "error",
                title: "담당자 변경/추가 불가",
                text: "진료시간 데이터가 존재합니다.",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });  
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(collection(db, 'employees'), where('groupid', '==', userGroup), 
                            where('empdiv', '==', empType), orderBy("empno"));
                const querySnapshot = await getDocs(q);
                const dataList = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));
                setEmployeeList(dataList);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        if (selEmpList) {
            fetchData();
        }
    }, [selEmpList, empType, userGroup]);

    const showError = (errTitle, errMsg) => {
        Swal.fire({
            icon: "error",
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    const findEmptyField = (fields, targetValue) => {
        let emptyField = null;
        for (const field of fields) {
            if (userInfo[field] === targetValue) {
                return null; // 이미 targetValue를 가진 필드가 있으면 null 반환
            }
            if (userInfo[field] === "none" && !emptyField) {
                emptyField = field; // "none"인 필드를 찾아서 emptyField에 저장
            }
        }
        return emptyField;
    };

    const addManager = async (props) => {
        let selfield;

        //chgAddType는 담당자 추가일땐 "A" 로 변경일땐 변경 전 담당자 코드로 선언됨
        if(chgAddType === "A"){
            const fields = props.empdiv === "Dentist" ? 
                ["dentist1st", "dentist2nd", "dentist3rd", "dentist4th", "dentist5th"] :
                ["staff1st", "staff2nd", "staff3rd", "staff4th", "staff5th"];

            selfield = findEmptyField(fields, props.fileid);

            if (!selfield) {
                showError(
                    props.empdiv === "Dentist" ? "담당원장 추가 불가" : "담당직원 추가 불가",
                    props.empdiv === "Dentist" ? "이미 등록되었거나 최대 5명 원장 등록 가능" : "이미 등록되었거나 최대 5명 직원 등록 가능"
                );
                return;
            }
        }else {
            if (props.empdiv === "Dentist") {
                const dentistFields = ["dentist1st", "dentist2nd", "dentist3rd", "dentist4th", "dentist5th"];
                const index = dentistFields.findIndex(field => userInfo[field] === chgAddType);
                const dupindex = dentistFields.findIndex(field => userInfo[field] === props.fileid);
            
                if (index !== -1 && dupindex === -1) {
                    selfield = dentistFields[index];
                } else {
                    showError("담당원장 변경 불가","이미 등록된 원장입니다.");
                    return;
                }
            } else {
                const staffFields = ["staff1st", "staff2nd", "staff3rd", "staff4th", "staff5th"];
                const index = staffFields.findIndex(field => userInfo[field] === chgAddType);
                const dupindex = staffFields.findIndex(field => userInfo[field] === props.fileid);
            
                if (index !== -1 && dupindex === -1) {
                    selfield = staffFields[index];
                } else {
                    showError("담당직원 변경 불가","이미 등록된 직원입니다.");
                    return;
                }
            }
        }

        try {
            await updateDoc(doc(db, "evapatient", userInfo.fileid), {
                [selfield]: props.fileid
            })
        } catch (error) {
            console.error('Error fetching data: ', error);
        }

        setSelEmpList(false);
        // userInfo를 새로운 데이터로 갱신
        const updatedUserInfo = {
            ...userInfo,
            [selfield]: props.fileid
        };
        // 갱신된 userInfo를 사용하여 화면을 다시 렌더링
        navigate('/evaManageBack', { state: { userinfo: updatedUserInfo } });
    }

    const delManager = async (empdiv, empdata) => {
        let employeeFields;
        let dentistFields = [
            userInfo.dentist1st,
            userInfo.dentist2nd,
            userInfo.dentist3rd,
            userInfo.dentist4th,
            userInfo.dentist5th
        ];
        let staffFields = [
            userInfo.staff1st,
            userInfo.staff2nd,
            userInfo.staff3rd,
            userInfo.staff4th,
            userInfo.staff5th
        ];
    
        if (empdiv === "Dentist") {
            employeeFields = dentistFields;
        } else {
            employeeFields = staffFields;
        }
    
        const index = employeeFields.indexOf(empdata);
        if (index !== -1) {
            const newEmployeeFields = employeeFields.slice(0, index).concat(employeeFields.slice(index + 1));
            
            let updatedUserInfo = { ...userInfo };
            if (empdiv === "Dentist") {
                updatedUserInfo = {
                    ...updatedUserInfo,
                    dentist1st: newEmployeeFields[0] || "none",
                    dentist2nd: newEmployeeFields[1] || "none",
                    dentist3rd: newEmployeeFields[2] || "none",
                    dentist4th: newEmployeeFields[3] || "none",
                    dentist5th: newEmployeeFields[4] || "none"
                };
            } else {
                updatedUserInfo = {
                    ...updatedUserInfo,
                    staff1st: newEmployeeFields[0] || "none",
                    staff2nd: newEmployeeFields[1] || "none",
                    staff3rd: newEmployeeFields[2] || "none",
                    staff4th: newEmployeeFields[3] || "none",
                    staff5th: newEmployeeFields[4] || "none"
                };
            }
    
            const docRef = doc(db, "evapatient", userInfo.fileid);
            try {
                await updateDoc(docRef, updatedUserInfo);
                navigate('/evaManageBack', { state: { userinfo: updatedUserInfo } });
            } catch (error) {
                console.error('Error updating document: ', error);
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "담당직원 삭제 불가",
                text: "Q&A게시판에 남겨주시기 바랍니다.",
                confirmButtonText: "확인",
            }).then((res) => {
                if (res.isConfirmed) {
                    // 확인 요청 처리
                }
            });
        }
    };

    const toEvaMngFront = () => {
        if(startTime && !stopTime) {
            showError("진료 중","진료 종료를 먼저 하시기 바랍니다.");
        }else {
            navigate('/evaManageFront', { state: { usergroup : userGroup } });
        }
    }

    const dentistText = windowWidth <= 480 ? '원장' : '담당 원장';
    const staffText = windowWidth <= 480 ? '선생님' : '담당 선생님';

    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva">
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div className="renew_content_title">담당자 연결</div>
                            <div style={{display:'flex'}}>
                                <div className="renew_add_employ patientcontent" style={{marginRight:20,background:"#191919",color:'#FFF'}}
                                    onClick={() => {
                                        const url = `/patientContentList?userinfo=${encodeURIComponent(userInfo.fileid)}`;
                                        const width = window.screen.width;
                                        const height = window.screen.height;
                                        window.open(
                                            url, 
                                            '환자 자료', 
                                            `width=${width},height=${height},top=0,left=0,fullscreen=yes`
                                        );
                                    }}
                                >
                                    환자 자료
                                </div>
                                <div className="renew_add_employ" style={{marginRight:7}}
                                    onClick={()=>handleEmpListClick("Dentist","A")}
                                >
                                    <img src={AddRed} alt='추가' style={{width:"1rem",height:"1rem",marginRight:8}}/>
                                    {dentistText}
                                </div>
                                <div className="renew_add_employ" onClick={()=>handleEmpListClick("Staff","A")}>
                                    <img src={AddRed} alt='추가' style={{width:"1rem",height:"1rem",marginRight:8}}/>
                                    {staffText}
                                </div>
                            </div>
                        </div>
                        {selEmpList && (
                            <>
                                <div className="renew_overlay" onClick={()=>{setSelEmpList(!selEmpList)}}/>
                                <div className="renew_dropdown_menu" style={{left:popupPosition.x,top:popupPosition.y}}>
                                    {employeeList.map((data, index) => (
                                        <div key={index} style={{display:'flex',color:'#FFF',fontSize:"1.25rem",marginBottom:"1.25rem",cursor:'pointer'}}
                                            onClick={()=>addManager(data)}
                                        >
                                            <div style={{marginRight:"0.625rem"}}>{data.name}</div>
                                            {data.empdiv === "Dentist" ? (
                                                <div style={{color:'#858585'}}>원장</div>
                                            ) : (
                                                <div style={{color:'#858585'}}>선생</div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <div style={{color:'#C39F56',fontSize:"1.375rem",fontWeight:600,marginTop:"3.875rem",marginBottom:"1.5rem"}}
                        >{userInfo.username}님의 담당자 리스트</div>
                        <ShowDentistList />
                        <div style={{marginBottom:"1.875rem"}} />
                        <div style={{color:'#C39F56',fontSize:"1.375rem",fontWeight:600,marginBottom:"1.5rem"}}>진료기록</div>
                        <div className="evamanageback_footer_area">
                            <div className="diagbutton_group">
                                <div style={{color:'#E9E9E9',fontSize:"1rem",fontWeight:500,fontStyle:'normal'}}>진료 시작</div>
                                <div className="renew_diag_btn" onClick={startDiagnostic}><img src={Triangle} alt='시작' style={{width:"1.5rem",height:"1.5rem"}} /></div>
                                <div className="renew_diag_btn" onClick={stopDiagnostic}><img src={Square} alt='종료' style={{width:"1.5rem",height:"1.5rem"}} /></div>
                                <div className="renew_diag_btn" onClick={resetDiagnostic}>
                                    <img src={Refresh} alt='초기화' style={{width:"1.375rem",height:"1.375rem"}} />
                                    <div style={{color:'#FFF',textAlign:'center',fontSize:"1rem",fontStyle:'normal',fontWeight:500}}
                                    >
                                        초기화
                                    </div>
                                </div>
                            </div>
                            <div className="evamanageback_footer_info_area">
                                <div className="renew_diaginfo_div" />
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <div className="renew_diaginfo_title" style={{width:'40%'}}>진료 시작</div>
                                    <div className="renew_diaginfo_title" style={{fontSize:"1.125rem",color:'#373737'}}>{startTime && startTime.toLocaleString()}</div>
                                </div>
                                <div className="renew_diaginfo_div" />
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <div className="renew_diaginfo_title" style={{width:'40%'}}>진료 종료</div>
                                    <div className="renew_diaginfo_title" style={{fontSize:"1.125rem",color:'#373737'}}>{stopTime && stopTime.toLocaleString()}</div>
                                </div>
                                <div className="renew_diaginfo_div" />
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <div className="renew_diaginfo_title" style={{width:'40%'}}>총 진료 시간</div>
                                    <div className="renew_diaginfo_title" style={{fontSize:"1.125rem",color:'#373737'}}>{calculateDuration()} 분</div>
                                </div>
                                <div className="renew_diaginfo_div" />
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:"7.5rem"}}>
                            <button style={{width:"8.25rem",height:"3rem",flexShrink:0,borderRadius:"0.5rem",background:'#EFEFEF',
                                        color:'#373737',fontSize:"1rem",fontWeight:500}}
                                    onClick={()=>toEvaMngFront()}
                            >뒤로</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaManageBack;