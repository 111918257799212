import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getDoc, doc, updateDoc, getDocs, setDoc, query, collection, where, increment, orderBy } from "firebase/firestore";
import { db, auth } from '../firebase_config.js';
import Swal from "sweetalert2";

import Refresh from '../images/renewal/Refresh.svg';
import ViewCnt from '../images/renewal/View_cnt.svg';
import Reply from '../images/renewal/Reply.svg';
import ArrowUp from '../images/renewal/Arrow-up.svg';

function NoticeRead(props) {
  const navigate = useNavigate();
  const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
  const bbsdataid = state.id;
  const owncontent = state.owncontent;
  const noticeName = state.noticename;
  const noticeCode = state.noticecode;

  const [data, setData] = useState({ contentData: null, replyData: [] });
  const [reply, setReply] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "bbsdata", bbsdataid);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const timestamp = docSnap.data().regdate.toDate();
        const formattedDate = `${timestamp.getFullYear()}-${(timestamp.getMonth() + 1).toString().padStart(2, '0')}-${timestamp.getDate().toString().padStart(2, '0')} ${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}:${timestamp.getSeconds().toString().padStart(2, '0')}`;
  
        const contentData = {
          ...docSnap.data(),
          id: docSnap.id,
          regdate: formattedDate
        };
  
        const q = query(collection(db, "bbsdata"), where("code", "==", noticeCode), where("delyn", "==", "N"),
                        where("reply", "==", "Y"), where("replydest", "==", bbsdataid), orderBy("replyseq"));
        const snapshot = await getDocs(q);
  
        const replyData = snapshot.docs.map(doc => {
          const timestamp = doc.data().regdate.toDate();
          const formattedDate = `${timestamp.getFullYear()}-${(timestamp.getMonth() + 1).toString().padStart(2, '0')}-${timestamp.getDate().toString().padStart(2, '0')} ${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}:${timestamp.getSeconds().toString().padStart(2, '0')}`;
  
          return {
            ...doc.data(),
            id: doc.id,
            regdate: formattedDate
          };
        });
  
        setData({ contentData, replyData });
      } else {
        console.log("No such document!");
      }
    };
  
    fetchData();
  }, [bbsdataid, refresh, noticeCode]);

  const handleContentChange = (event) => {
    setReply(event.target.value);
  };

  const delContent = () => {
    Swal.fire({
      icon: "question",
      title: "게시글 삭제",
      text: "게시글 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then(async (res) => {
      /* Read more about isConfirmed, isDenied below */
      if (res.isConfirmed) {
        //확인 요청 처리
        const docRef = doc(db, "bbsdata", bbsdataid);
        await updateDoc(docRef, {
          delyn: "Y"
        })

        Swal.fire({
          icon: "success",
          title: "게시물 삭제 완료",
          text: "게시물 삭제되었습니다",
          //showCancelButton: true,
          confirmButtonText: "확인",
          //cancelButtonText: "취소",
        }).then((res) => {
            /* Read more about isConfirmed, isDenied below */
            if (res.isConfirmed) {
              //확인 요청 처리
              navigate("/notice",{
                state: {
                  id: noticeName,
                  code: noticeCode
                },
              })
            }
        })
      }
    });
  }

  const delReply = (props) => {
    Swal.fire({
      icon: "question",
      title: "댓글 삭제",
      text: "댓글 삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then(async (res) => {
      /* Read more about isConfirmed, isDenied below */
      if (res.isConfirmed) {
        //확인 요청 처리
        const docRef = doc(db, "bbsdata", props);
        await updateDoc(docRef, {
          delyn: "Y"
        })

        setRefresh(!refresh);
      }
    });
  }

  const regReply = async () => {
    if(reply !== ""){
      try{     
        const now = new Date();
        // "users" 테이블에서 작성자 정보 가져오기 
        const userId = auth.currentUser.email;
        const userDocRef = doc(db, "users", userId);
        const userSnapshot = await getDoc(userDocRef);

        // "code" 필드가 noticeCode인 데이터의 개수 가져오기
        const qnaQuery = query(collection(db, "bbsdata"), where("code", "==", noticeCode));
        const qnaSnapshot = await getDocs(qnaQuery);
        const qnaCount = qnaSnapshot.size+1;

        // "replydest" 필드가 게시글 아이디인 데이터의 개수 가져오기
        const replyQuery = query(collection(db, "bbsdata"), where("code", "==", noticeCode),
                                where("replydest", "==", bbsdataid));
        const replySnapshot = await getDocs(replyQuery);
        const replyCount = replySnapshot.size+1;

        const dataId = noticeCode+qnaCount

        const docRef = doc(db, "bbsdata", bbsdataid);
        await updateDoc(docRef, {
          replycount: increment(1)
        })

        await setDoc(doc(db, "bbsdata", dataId),{
          code: noticeCode,
          seq: qnaCount,
          userid: userSnapshot.data().userId,
          username: userSnapshot.data().userName,
          title: "",
          content: reply,
          regdate: now,
          secret: false,
          reply: "Y",
          replycount: 0,
          replyseq: replyCount,
          replydest: bbsdataid,
          count: 0,
          delyn: "N",
        }).then(
          Swal.fire({
            icon: "success",
            title: "댓글 등록 완료",
            text: "댓글 등록완료 되었습니다",
            //showCancelButton: true,
            confirmButtonText: "확인",
            //cancelButtonText: "취소",
          }).then((res) => {
              /* Read more about isConfirmed, isDenied below */
              if (res.isConfirmed) {
                //확인 요청 처리
                setReply("");
                setRefresh(!refresh);
              }
          })
        );
      } catch(error) {
          console.log("에러메세지:", error.message);
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "누락된 댓글 내용",
        text: "댓글을 입력하세요",
        //showCancelButton: true,
        confirmButtonText: "확인",
        //cancelButtonText: "취소",
      }).then((res) => {
          /* Read more about isConfirmed, isDenied below */
          if (res.isConfirmed) {
              //확인 요청 처리
          }
      });
    }
  }

  return (
    <div className="renew_background" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      {data.contentData ? (
        <div className="renew_content_background notice">
          <div>
            <div className="noticeread_noticetitle_area">
              <div className="renew_content_title">{noticeName}</div>
              <div style={{display:'flex',width:"2.25rem",height:"2.25rem",flexShrink:0,borderRadius:"0.5rem",border:'1px solid #E3E3E3',
                          alignItems:'center',justifyContent:'center',marginLeft:"1rem",cursor:'pointer'}}
                  onClick={()=>setRefresh(!refresh)}
              >
                <img src={Refresh} alt='새로고침' style={{width:"1.125rem",height:"1.125rem"}} />
              </div>
            </div>
            <div className="noticeread_info_area">
              <div style={{display:'flex'}}>
                <div style={{marginRight:"1.875rem"}}>
                  <div className="renew_qna_read_title">번호</div>
                  <div className="renew_qna_read_content" style={{color:'#858585'}}>
                    {data.contentData.istop === true
                        ? "공지"
                        : `${data.contentData.seq}`}
                  </div>
                </div>
                <div style={{marginRight:"1.875rem"}}>
                  <div className="renew_qna_read_title">작성자</div>
                  <div className="renew_qna_read_content">
                    {data.contentData.username === "관리자"
                        ? data.contentData.username
                        : `${data.contentData.username[0]}${'*'.repeat(data.contentData.username.length - 1)}`}
                  </div>
                </div>
                <div>
                  <div className="renew_qna_read_title">제목</div>
                  <div className="renew_qna_read_content">{data.contentData.title}</div>
                </div>
              </div>
              <div className="renew_qna_read_title" style={{display:'flex',alignItems:'center',fontSize:"0.9375rem"}}>
                <img src={ViewCnt} alt='조회수' style={{width:"1.5rem",height:"1.5rem"}} />
                <div style={{marginRight:"1.875rem",marginLeft:"0.1875rem"}}>{data.contentData.count}</div>
                <img src={Reply} alt='댓글수' style={{width:"1.5rem",height:"1.5rem"}} />
                <div style={{marginRight:"1.875rem",marginLeft:"0.1875rem"}}>{data.contentData.replycount}</div>
                <div>{data.contentData.regdate}</div>
              </div>
            </div>
            <div className="noticeread_info_area mobile">
              <div>
                {data.contentData.username === "관리자"
                    ? data.contentData.username
                    : `${data.contentData.username[0]}${'*'.repeat(data.contentData.username.length - 1)}`}
              </div>
              <div style={{display:'flex'}}>
                <div style={{marginRight:"1rem"}}>{data.contentData.regdate}</div>
                <img src={ViewCnt} alt='조회수' style={{width:"1.5rem",height:"1.5rem"}} />
                <div style={{marginRight:"1.875rem",marginLeft:"0.1875rem"}}>{data.contentData.count}</div>
              </div>
              <div style={{marginTop:"1rem",fontSize:"1.3rem",fontWeight:500}}>{data.contentData.title}</div>
            </div>
            <div className="noticeread_content">{data.contentData.content}</div>
            {owncontent === "Y" && (
              <div className="noticeread_moddel">
                <div style={{cursor:'pointer'}}
                      onClick={()=>navigate("/noticeWrite",{
                        state: {
                          contentdata: data.contentData,
                          noticename: noticeName,
                          noticecode: noticeCode,
                        },
                      })}>수정하기</div>
                <div style={{marginLeft:"0.3125rem",marginRight:"0.3125rem"}}>|</div>
                <div style={{cursor:'pointer'}} onClick={()=>delContent()}>삭제하기</div>
              </div>
            )}  
            {data.replyData.map((option, index) => {
              return (
                <div key={index} className="noticeread_reply_area">
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                      <div style={{fontSize:"0.875rem",fontWeight:600,padding:"0.1875rem 0.3125rem",
                                  background: option.username === "관리자" && '#FFF2E3',}}
                      >
                        {option.username === "관리자"
                        ? option.username
                        : `${option.username[0]}${'*'.repeat(option.username.length - 1)}`}
                      </div>
                      <div style={{color:'#CECECE',marginLeft:"0.75rem",fontSize:"0.75rem",fontWeight:500}}>{option.regdate}</div>
                    </div>
                    {(option.userid === auth.currentUser.email || auth.currentUser.email === "mawang86@naver.com") && 
                      <div style={{fontSize:"0.875rem",fontWeight:500,cursor:'pointer'}}
                            onClick={()=>delReply(option.id)}
                      >댓글삭제</div>
                    }
                  </div>
                  <div style={{marginTop:"0.9375rem",fontSize:"0.875rem",color:'#707070',fontWeight:500,whiteSpace:'pre-wrap'}}>{option.content}</div>
                </div>
              )
            })}
            <div style={{height:1,background:'#E8E8E8'}} />
            { noticeName !== "공지사항" &&
              <>
                <div style={{color:'#373737',fontSize:"0.9375rem",fontWeight:500,marginTop:"2rem",marginBottom:"0.5625rem"}}>댓글을 남겨주세요.</div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end',}}>
                  <textarea 
                    style={{background:'#F4F4F0',borderRadius:"0.5rem",fontSize:"1rem",padding:"1rem",flexGrow:1,color:'#373737',
                            fontWeight:500,resize:'none',height:"3.5rem"}} 
                    placeholder='불량댓글 작성 시 이용정지 등의 제재를 받습니다.' 
                    value={reply}
                    onChange={handleContentChange}
                  />
                  <button style={{width:"3.5rem",height:"3.5rem",background:'#191919',borderRadius:"0.5rem",marginLeft:"0.625rem"}}
                          onClick={()=>regReply()}>
                    <img src={ArrowUp} alt='업로드' style={{width:"1.75rem",height:"1.75rem"}}/>
                  </button>
                </div>
              </>
            }
            <div className="bottom_button_area">
              {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
              <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF'}}
                      onClick={()=>navigate("/notice",{
                          state: {
                            id: noticeName,
                            code: noticeCode
                          },
                        })
                      }
              >목록 보기</button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default NoticeRead;