import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, setDoc, doc, getDocs, getDoc, query, where, updateDoc } from "firebase/firestore";
import { db, auth } from '../firebase_config.js';
import Checkbox from '../Checkbox.js';
import Swal from "sweetalert2";


function NoticeWrite(props) {
  const navigate = useNavigate();
  const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
  const contentData = state.contentdata;
  const noticeName = state.noticename;
  const noticeCode = state.noticecode;

  let initialTitle = "";
  let initialContent = "";
  let initialSecret = false;
  let initialIstop = false;

  if (contentData !== "NEW") {
    initialTitle = contentData.title;
    initialContent = contentData.content;
    initialSecret = contentData.secret;
    initialIstop = contentData.istop;
  }

  const [title, setTitle] = useState(initialTitle !== "" ? initialTitle : "");
  const [content, setContent] = useState(initialContent !== "" ? initialContent : "");
  const [secret, setSecret] = useState(initialSecret !== false ? initialSecret : false);
  const [istop, setIstop] = useState(initialIstop !== false ? initialIstop : false);

  const handleInputChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const save = async () => {
    if(title !== "" && content !== ""){
      if(contentData === "NEW"){
        try{     
          const now = new Date();
          // "users" 테이블에서 작성자 정보 가져오기 
          const userId = auth.currentUser.email;
          const userDocRef = doc(db, "users", userId);
          const userSnapshot = await getDoc(userDocRef);

          // "code" 필드가 noticeCode인 데이터의 개수 가져오기
          const noticeQuery = query(collection(db, "bbsdata"), where("code", "==", noticeCode));
          const noticeSnapshot = await getDocs(noticeQuery);
          const noticeCount = noticeSnapshot.size+1;

          const dataId = noticeCode+noticeCount
          await setDoc(doc(db, "bbsdata", dataId),{
              code: noticeCode,
              seq: noticeCount,
              userid: userSnapshot.data().userId,
              username: userSnapshot.data().userName,
              title: title,
              content: content,
              regdate: now,
              secret: secret,
              reply: "N",
              replycount: 0,
              replyseq: 0,
              replydest: "",
              count: 0,
              delyn: "N",
              istop: istop,
              group: props.userGroup,
          }).then(
            Swal.fire({
              icon: "success",
              title: "게시물 등록 완료",
              text: "게시물 등록완료 되었습니다",
              //showCancelButton: true,
              confirmButtonText: "확인",
              //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                    setTitle("");
                    setContent("");
                    setSecret(false);
                    navigate("/notice",{
                      state: {
                        id: noticeName,
                        code: noticeCode
                      },
                    })
                }
            })
          );
        } catch(error) {
            console.log("에러메세지:", error.message);
        }
      }else{
        try{     
          const docRef = doc(db, "bbsdata", contentData.id);
          await updateDoc(docRef, {
            title: title,
            content: content,
            secret: secret,
            istop: istop,
          }).then(
            Swal.fire({
              icon: "success",
              title: "게시물 수정 완료",
              text: "게시물 수정완료 되었습니다",
              //showCancelButton: true,
              confirmButtonText: "확인",
              //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                    navigate("/notice",{
                      state: {
                        id: noticeName,
                        code: noticeCode
                      },
                    })
                }
            })
          );
        } catch(error) {
            console.log("에러메세지:", error.message);
        }
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "누락된 제목/내용",
        text: "제목과 내용을 입력하세요",
        //showCancelButton: true,
        confirmButtonText: "확인",
        //cancelButtonText: "취소",
      }).then((res) => {
          /* Read more about isConfirmed, isDenied below */
          if (res.isConfirmed) {
              //확인 요청 처리
          }
      });
    }
  };

  const goNotice = () => {
    Swal.fire({
      icon: "warning",
      title: "게시판으로 돌아가시겠습니까?",
      text: "작성하신 내용은 저장되지 않습니다.",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((res) => {
        /* Read more about isConfirmed, isDenied below */
        if (res.isConfirmed) {
            //확인 요청 처리
            navigate("/notice",{
              state: {
                id: noticeName,
                code: noticeCode
              },
            })
        }
    });
  }

  return (
    <div className="renew_background" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <div className="renew_content_background notice">
        <div>
          <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>{noticeName} 등록</div>
          <div className="renew_qna_read_title" style={{fontSize:"1rem",marginBottom:"0.5rem"}}>제목</div>
          <input style={{padding:"1rem",borderRadius:"0.5rem",background:'#F4F4F0',color:'#373737',fontSize:"1rem",
                        fontWeight:500,marginBottom:"3.5rem",width:"100%"}}
              id="_title" 
              type="text" 
              placeholder='제목을 입력하세요.' 
              value={title}
              onChange={handleInputChange}
          />
          <div className="renew_qna_read_title" style={{fontSize:"1rem",marginBottom:"0.5rem"}}>내용</div>
          <textarea 
            style={{
                minHeight:300,
                padding:"1rem",
                borderRadius:"0.5rem",
                background:'#F4F4F0',
                color:'#373737',
                fontSize:"1rem",
                fontWeight:500,
                marginBottom:"0.6875rem",
                width:"100%",
                resize: 'none',
            }} 
            placeholder='내용을 입력하세요.' 
            value={content}
            onChange={handleContentChange}
          />
          <div style={{display:'flex',color:'#909090',fontSize:"0.9375rem",fontWeight:500,alignItems:'center'}}>
            <Checkbox checked={secret} onChange={setSecret} />
            <div style={{marginLeft:"0.5rem",marginRight:"0.625rem"}}>비밀글(작성자만 볼 수 있습니다)</div>
            {(props.userAuth === 7 || props.userAuth === 1) && 
              <>
                <Checkbox checked={istop} onChange={setIstop} />
                <div style={{marginLeft:"0.5rem"}}>공지여부</div>
              </>
            }
          </div>
        </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:"5.625rem"}}>
          <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF',width:"8.25rem"}}
                  onClick={()=>goNotice()}
          >취소</button>
          <button className="renew_evasignup" style={{width:"8.25rem",marginLeft:"1rem"}}
                  onClick={()=>save()}
          >작성 완료</button>
        </div>
      </div>
    </div>
  )
}

export default NoticeWrite;