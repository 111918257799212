import React, { useState, useEffect, useCallback, useRef } from 'react';
import { collection, getDocs, doc, updateDoc, setDoc, deleteDoc, orderBy, query, where, limit } from "firebase/firestore";
import { db } from '../firebase_config';
import Swal from "sweetalert2";

import IconUpward from '../images/renewal/Icon_upward.svg';
import IconDownward from '../images/renewal/Icon_downward.svg';


function CategoryMod(props) {
    const sysDiv = props.sysDiv;
    const groupId = props.userGroup;

    const [cateList, setCateList] = useState([]);

    // 클릭한 div의 인덱스를 추적하는 상태 변수
    const [selectedDivIndex, setSelectedDivIndex] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [editText, setEditText] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [minHeight, setMinHeight] = useState(window.innerHeight);

    const inputRef = useRef(null);

    useEffect(() => {
        function handleResize() {
            const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
            const heightInRem = window.innerHeight - (12.49 * rootFontSize);
            setMinHeight(heightInRem);
        }

        handleResize(); // 초기 렌더링 시 실행

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchDataCallback = useCallback(async () => {
        async function renderCategorys() {
            const docRef = collection(db, "category");
            let q;
            if(sysDiv === "CA"){
                q = query(docRef, where("sysdiv", "==", sysDiv), where("groupid", "==", groupId), 
                     orderBy("value"));
            }else{
                q = query(docRef, where("sysdiv", "==", sysDiv), orderBy("value"));
            }
            const snapshot = await getDocs(q);

            return snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        }

        const data = await renderCategorys();
    
        setCateList(data);
    }, [sysDiv, groupId]);

    useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback]);

    const swapIndex = async (direction) => {
        // 선택한 div의 인덱스가 유효한지 확인
        if (selectedDivIndex === null) {
            showAlert("error", "카테고리 미선택", "카테고리를 클릭하여 선택하시기 바랍니다.");
            return;
        }
    
        const currentIndex = selectedDivIndex;
        const nextIndex = direction === "Up" ? currentIndex - 1 : currentIndex + 1;
    
        // 다음 인덱스가 유효한지 확인
        if (nextIndex < 0 || nextIndex >= cateList.length) {
            return;
        }
    
        const currentDoc = doc(db, "category", cateList[currentIndex].id);
        const nextDoc = doc(db, "category", cateList[nextIndex].id);
    
        const currentCateno = cateList[currentIndex].value;
        const nextCateno = cateList[nextIndex].value;
    
        try {
            // 업데이트할 문항의 quesno를 서로 교환
            await Promise.all([
                updateDoc(currentDoc, { value: nextCateno }),
                updateDoc(nextDoc, { value: currentCateno })
            ]);
            // swapIndex 이후 selectedDivIndex 값 변경
            setSelectedDivIndex(nextIndex);

            // swapIndex 이후 quesData 상태 업데이트
            fetchDataCallback();
        } catch (error) {
            console.error("에러 메시지:", error.message);
        }
    }

    const handleEditClick = (index, currentText) => {
        setEditIndex(index);
        setEditText(currentText);
    };

    const updateCategory = async (categoryId, newCateval, newRepcateval) => {
        const categoryRef = doc(db, "category", categoryId);
        await updateDoc(categoryRef, {
            cateval: newCateval,
            repcateval: newRepcateval,
        });
    };
    
    const updateContents = async (oldRepcateval, newCateval, newRepcateval) => {
        const contentsRef = collection(db, "contents");
        const q = query(contentsRef, where("repcateval", "==", oldRepcateval), where("sysdiv", "==", sysDiv));
        const querySnapshot = await getDocs(q);
        
        const updatePromises = querySnapshot.docs.map(async (content) => {
            const contentRef = doc(db, "contents", content.id);
            await updateDoc(contentRef, {
                cateval: newCateval,
                repcateval: newRepcateval,
            });
        });
        
        await Promise.all(updatePromises);
    };
    
    const handleSaveClick = async (props) => {
        const newRepcateval = editText.replace(/[^\w가-힣]/g, '');
        const docRef = collection(db, "category");
        const q = query(docRef, where("repcateval", "==", newRepcateval), where("sysdiv", "==", sysDiv), where("groupid", "==", groupId), where("fileid", "!=", props.id));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.docs.length === 0) {
            await updateCategory(props.id, editText, newRepcateval);
            await updateContents(props.repcateval, editText, newRepcateval);
            fetchDataCallback();
        } else {
            showAlert("error", "카테고리명 중복", "띄어쓰기만 다른경우 동일한 단어로 간주됩니다.");
        }
        
        setEditIndex(null);
    };

    useEffect(() => {
        if (editIndex !== null) {
          inputRef.current?.focus();
        }
    }, [editIndex]);

    const handleNewButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleSaveNewCategory = async () => {
        // 새로운 카테고리 저장 로직을 여기에 추가합니다.
        const newRepcateval = newCategoryName.replace(/[^\w가-힣]/g, '');
        const docRef = collection(db, "category");
        const q = query(docRef, where("repcateval", "==", newRepcateval), where("sysdiv", "==", sysDiv), 
                        where("groupid", "==", groupId));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.docs.length === 0) {
            let now = new Date();
            let todayMonth = now.getMonth() + 1;
            let todayDate = now.getDate();
            let todayHour = now.getHours();
            let todayMin = now.getMinutes();
            let todaySec = now.getSeconds();
            let fileid = groupId+newRepcateval+String(todayMonth)+String(todayDate)+String(todayHour)+
                                String(todayMin)+String(todaySec)+sysDiv;

            try{
                // 가장 큰 value 값을 찾기 위한 쿼리
                const maxValueQuery = query(docRef, 
                    where("groupid", "==", groupId), 
                    where("sysdiv", "==", sysDiv), 
                    orderBy("value", "desc"), 
                    limit(1));
                const maxValueSnapshot = await getDocs(maxValueQuery);
                let maxValue = 0;
                if (!maxValueSnapshot.empty) {
                    maxValue = maxValueSnapshot.docs[0].data().value;
                }

                await setDoc(doc(db, "category", fileid),{
                    cateval: newCategoryName,
                    repcateval: newRepcateval,
                    fileAddr: "",
                    fileid: fileid,
                    value: maxValue + 1,
                    sysdiv: sysDiv,
                    groupid: groupId
                })

                setNewCategoryName('');
                fetchDataCallback();
            } catch(error){
                console.log(error.message);
            }
        } else {
            showAlert("error", "카테고리명 중복", "띄어쓰기만 다른경우 동일한 단어로 간주됩니다.");
        }
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleDeleteButtonClick = (props) => {
        Swal.fire({
            icon: "question",
            title: "카테고리 삭제",
            text: "카테고리 삭제 시 내부 데이터도 함께 삭제됩니다.",
            showCancelButton: true,
            confirmButtonText: "삭제",
            cancelButtonText: "취소",
        }).then(async (res) => {
            if (res.isConfirmed) {
                try {
                    // category 테이블에서 해당 데이터 삭제
                    await deleteDoc(doc(db, "category", props.id));
            
                    // 삭제된 데이터의 value보다 큰 value 값을 가진 데이터들의 value를 1씩 차감하여 업데이트
                    const updatePromises = cateList
                        .filter(data => data.value > props.value)
                        .map(async data => {
                            const docRef = doc(db, "category", data.id);
                            await updateDoc(docRef, { value: data.value - 1 });
                        });
            
                    // 모든 업데이트 프로미스를 동시에 실행
                    await Promise.all(updatePromises);

                    const docRef = collection(db, "contents");
                    const q = query(docRef, where("repcateval", "==", props.repcateval), where("sysdiv", "==", sysDiv),where("groupid", "==", groupId));
                    const querySnapshot = await getDocs(q); //contents 테이블의 repcateval값이 선택한 카테고리인 값을 호출
                    
                    querySnapshot.docs.map(async content => {
                        await deleteDoc(doc(db, "contents", content.id));
                    }); 
            
                    // cateList 상태 업데이트
                    fetchDataCallback();
                } catch (error) {
                    console.error("삭제 에러:", error);
                }
            }
        });
    };

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <div className="renew_content_background notice" style={{minHeight:`${minHeight}px`}}>
                <div>
                    <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>카테고리 편집</div>
                    <div style={{display:'flex',justifyContent:'flex-end',paddingBottom:"0.75rem",
                                borderBottom:'2px #191919 solid'}}>
                        <img src={IconUpward} alt='위로' style={{width:"2rem",height:"2rem",cursor:'pointer'}}
                            onClick={() => swapIndex('Up')} />
                        <img src={IconDownward} alt='아래로' style={{width:"2rem",height:"2rem",cursor:'pointer',marginLeft:"0.5rem"}} 
                            onClick={() => swapIndex('Down')} />
                    </div>
                    {cateList
                        .map((data, index) => (
                            <div key={index} onClick={() => setSelectedDivIndex(index)}
                                style={{backgroundColor: selectedDivIndex === index ? '#FDFFF0' : 'transparent'}}
                            >
                                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',color:'#373737',fontSize:"1.125rem",
                                            fontWeight:600,height:"3.438rem",borderBottom:'1px #E8E8E8 solid'}}>
                                    {editIndex === index ? (
                                    <input
                                        type="text"
                                        value={editText}
                                        ref={inputRef}
                                        onChange={(e) => setEditText(e.target.value)}
                                        style={{fontSize:"1.125rem",fontWeight:600,color:'#373737',background:'#FDFFF0'}}
                                    />
                                    ) : (
                                    <div>{data.cateval}</div>
                                    )}
                                    <div style={{display:'flex'}}>
                                    {editIndex === index ? (
                                        <button className="renew_question_moddel" onClick={()=>handleSaveClick(data)}>저장</button>
                                    ) : (
                                        <>
                                            <button className="renew_question_moddel" onClick={() => handleEditClick(index, data.cateval)}>편집</button>
                                            <button className="renew_question_moddel" onClick={() => handleDeleteButtonClick(data)}>삭제</button>
                                        </>
                                    )}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"6.25rem"}}>
                    <button className="renew_categorymod" 
                            style={{marginRight:"1rem"}}
                            onClick={props.toCasePreMainPage}
                    >뒤로</button>
                    <button className="renew_categorymod"
                            style={{background:'#191919',color:'#FFF'}}
                            onClick={handleNewButtonClick}
                    >신규</button>
                </div>
            </div>
            {isModalOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "31.25rem",
                        height: "12.5rem",
                        background: '#FFF',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        borderRadius: "1rem",
                        padding: "1.25rem",
                        zIndex: 1000,
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-between'
                    }}
                >
                    <div>
                        <div style={{color:'#191919',fontSize:"1.5rem",fontWeight:600}}>신규 카테고리 추가</div>
                        <input
                            type="text"
                            value={newCategoryName}
                            onChange={(e) => setNewCategoryName(e.target.value)}
                            style={{width:'100%',borderRadius:"0.5rem",border:'1px #E8E8E8 solid',padding:"0.875rem",
                                    margin:'1.25rem 0rem',color:'#ABABAB',fontSize:"1rem",fontWeight:500}}
                            placeholder="카테고리 입력"
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            onClick={handleCloseModal}
                            style={{
                                background: '#EFEFEF',
                                color: '#373737',
                                borderRadius: "0.5rem",
                                padding: '0.625rem 1.25rem',
                                marginRight: "0.625rem",
                                fontSize: "0.875rem",
                                fontWeight: 500
                            }}
                        >취소</button>
                        <button
                            onClick={handleSaveNewCategory}
                            style={{
                                background: '#191919',
                                color: '#FFF',
                                borderRadius: "0.5rem",
                                padding: '0.625rem 1.25rem',
                                fontSize: "0.875rem",
                                fontWeight: 500
                            }}
                        >저장</button>
                    </div>
                </div>
            )}
            {isModalOpen && (
                // 모달영역 바깥 부분
                <div
                    onClick={handleCloseModal}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.5)',
                        zIndex: 999,
                    }}
                />
            )}
        </div>
    )
}

export default CategoryMod;