import React from 'react';
import { useNavigate } from 'react-router-dom';
/*import bdlogo from '../images/bdlogo.jpg';*/
import bdlogo from '../images/common/icon_illu_basic.svg';


function AdminHome(props) {
    const navigate = useNavigate();

    const toAdminMemberlist = () => {
      navigate("/adminMemberlist");
    }

    const toAdminMemAprv = () => {
      navigate("/adminMemAprv");
    }

    const toEvaReportAdmin = () => {
      navigate("/evaReportAdmin");
    }

    const toEvaEmpMngAdm = () => {
      navigate("/evaEmpMngAdm");
    }

    const toAdminCommon = () => {
      navigate("/adminCommon");
    }

    const toDataMod = () => {
      navigate("/adminDataMod");
    }

    function ShowCateList() {
        return(
            <div className="content_wrap">
              <h3 className="sub_tit">
                <span>관리자메뉴</span>
              </h3>
              <ul className="category_wrap">
                <li>
                  <div onClick={()=>toAdminMemberlist()}>
                    <span className="category_tit">승인가입자정보</span>
                    <img
                      alt="카테고리이미지"
                      src={bdlogo}
                      className="category_images"
                    />
                  </div>
                </li>
                {props.userAuth === 7 &&
                  <>
                    <li>
                      <div onClick={()=>toAdminMemAprv()}>
                        <span className="category_tit">신규회원승인</span>
                        <img
                          alt="카테고리이미지"
                          src={bdlogo}
                          className="category_images"
                        />
                      </div>
                    </li>
                    <li>
                      <div onClick={()=>toEvaReportAdmin()}>
                        <span className="category_tit">리포트관리자</span>
                        <img
                          alt="카테고리이미지"
                          src={bdlogo}
                          className="category_images"
                        />
                      </div>
                    </li>
                    <li>
                      <div onClick={()=>toEvaEmpMngAdm()}>
                        <span className="category_tit">직원관리자</span>
                        <img
                          alt="카테고리이미지"
                          src={bdlogo}
                          className="category_images"
                        />
                      </div>
                    </li>
                    <li>
                      <div onClick={()=>toAdminCommon()}>
                        <span className="category_tit">공통코드관리</span>
                        <img
                          alt="카테고리이미지"
                          src={bdlogo}
                          className="category_images"
                        />
                      </div>
                    </li>
                    <li>
                      <div onClick={()=>toDataMod()}>
                        <span className="category_tit">데이터수정</span>
                        <img
                          alt="카테고리이미지"
                          src={bdlogo}
                          className="category_images"
                        />
                      </div>
                    </li>
                  </>
                }
              </ul>
            </div>
        )
    }

    return (
      <div className="wrap">
        <ShowCateList></ShowCateList>
        <footer>
          <span className="copyright">Copyright 2022. Dental Connect All rights reserved.</span>
        </footer>
      </div>
    )
}

export default AdminHome;