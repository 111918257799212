import React from 'react';
import { useNavigate } from 'react-router-dom';
/*import bdlogo from '../images/bdlogo.jpg';*/
import bdlogo from '../images/common/icon_illu_basic.svg';


function EvaHome(props) {
    const navigate = useNavigate();

    /*
    const toContentListPage = (props) => {
        navigate("/contentlist", {
            state: {
                cateval: props.cateval,
                repcateval: props.repcateval
            },
        });
    }
    */

    const toEvaSignup = () => {
      navigate("/evaSignup");
    }

    const toEvaManageFront = () => {
      navigate("/evaManageFront");
    }

    const toEvaSurveyFront = (props) => {
      navigate("/evaSurveyFront",{
        state: {
            code: props,
        },
      });
    }

    const toEvaReportHome = () => {
      // navigate("/evaReportHome");
      navigate("/evaReportMain");
    }

    const toEvaEmployeeManage = () => {
      navigate("/evaEmployeeManage");
    }

    const toEvaQuestionManage = () => {
      navigate("/evaQuestionManage");
    }

    function ShowCateList() {
        return(
            <div className="content_wrap">
              <h3 className="sub_tit">
                <span>고객만족도 관리 시스템</span>
              </h3>
              <ul className="category_wrap">
                <li>
                  <div onClick={()=>toEvaSignup()}>
                    <span className="category_tit">환자 등록</span>
                    <img
                      alt="카테고리이미지"
                      src={bdlogo}
                      className="category_images"
                    />
                  </div>
                </li>
                <li>
                  <div onClick={()=>toEvaManageFront()}>
                    <span className="category_tit">담당자 연결</span>
                    <img
                      alt="카테고리이미지"
                      src={bdlogo}
                      className="category_images"
                    />
                  </div>
                </li>
                <li>
                  <div onClick={()=>toEvaSurveyFront("Eva")}>
                    <span className="category_tit">고객만족도 조사</span>
                    <img
                      alt="카테고리이미지"
                      src={bdlogo}
                      className="category_images"
                    />
                  </div>
                </li>
                <li>
                  <div onClick={()=>toEvaQuestionManage()}>
                    <span className="category_tit">문항 관리</span>
                    <img
                      alt="카테고리이미지"
                      src={bdlogo}
                      className="category_images"
                    />
                  </div>
                </li>
                <li>
                  <div onClick={()=>toEvaEmployeeManage()}>
                    <span className="category_tit">직원 관리</span>
                    <img
                      alt="카테고리이미지"
                      src={bdlogo}
                      className="category_images"
                    />
                  </div>
                </li>
                <li>
                  <div onClick={()=>toEvaReportHome()}>
                    <span className="category_tit">리포트</span>
                    <img
                      alt="카테고리이미지"
                      src={bdlogo}
                      className="category_images"
                    />
                  </div>
                </li>
              </ul>
            </div>
        )
    }

    return (
      <div className="wrap">
        <ShowCateList></ShowCateList>
        <footer>
          <span className="copyright">Copyright 2022. Dental Connect All rights reserved.</span>
        </footer>
      </div>
    )
}

export default EvaHome;