import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';


function FindIDResult() {
    const navigate = useNavigate();
    const location = useLocation();
    const { users } = location.state || { users: [] }; // 전달된 상태에서 사용자 데이터 받

    return (
        <div className="renew_background signup">
            <div className="renew_content_background">
                <div>
                    <div className="renew_content_title" >아이디 찾기 결과</div> 
                    <div className="content_area" />
                    <div style={{display:'flex',borderBottom:'#E8E8E8 1px solid',color:'#9F9F9F',fontSize:"1rem",
                                fontWeight:500,paddingBottom:"0.625rem",width:'100%'}}>
                        <div>아이디</div>
                    </div>
                    {users
                        .map((option, index) => (
                            <div key={option.id} style={{display:'flex',color:'#373737',fontSize:"1.125rem",fontWeight:500,
                                height:"3.4375rem",borderBottom:'#E8E8E8 1px solid',background:index%2 === 0 && '#FAFAFA',
                                alignItems:'center',justifyContent:'space-between'}}>
                                <div style={{display:'flex'}}>
                                    <div>
                                        {option.userId.length > 3 
                                        ? `***${option.userId.substring(3)}` 
                                        : '***'}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"13.38rem"}}>
                    <button className="renew_evasignup"
                            onClick={()=>navigate("/login")}
                    >로그인화면</button>
                </div>
            </div>
        </div>
    )
}

export default FindIDResult;