import React, { useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { Outlet, useNavigate } from 'react-router-dom';

function PrivateRoute(props) {
    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate();

    useEffect(() => {
        //props.authentication가 true -> 로그인 안했으면 못들어가는 페이지 로직
        //props.authentication가 false -> 로그인/회원가입페이지는 로그인한상태면 못들어가도록 home으로 가게하는 로직
        if (props.authentication === "true") {
            if (user === null) {
                navigate('/login', { replace: true });
            }
        } else {
            if (user !== null) {
                navigate('/', { replace: true });
            }
        }
    }, [props.authentication, user, navigate]);

    return <Outlet />;
}

export default PrivateRoute;