import React, { useState } from 'react';
import { doc, setDoc, getDocs, collection, query, where, updateDoc } from "firebase/firestore";
import { db } from '../firebase_config';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from "sweetalert2";


function EvaQueMngReg(props) {
    const navigate = useNavigate();
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const quesdata = state.data; //전 페이지에서 넘어온 매개변수 

    let initialSelEmpdiv = "";
    let initialSelQuesdiv = "";
    let initialQuestion = "";

    if (quesdata !== "NEW") {
        initialSelEmpdiv = quesdata.empdiv;
        initialSelQuesdiv = quesdata.quesdiv;
        initialQuestion = quesdata.question;
    }

    const [selEmpdiv, setSelEmpdiv] = useState(initialSelEmpdiv !== "" ? initialSelEmpdiv : "");
    const [selQuesdiv, setSelQuesdiv] = useState(initialSelQuesdiv !== "" ? initialSelQuesdiv : "");
    const [question, setQuestion] = useState(initialQuestion !== "" ? initialQuestion : "");

    const insertQuestion = async () => {
        if(selEmpdiv !== "" && selQuesdiv !== "" && question !== "") {
            if(quesdata === "NEW"){
                let now = new Date();
                let todayYear = now.getFullYear();
                let todayMonth = now.getMonth() + 1;
                let todayDate = now.getDate();
                let todayHour = now.getHours();
                let todayMin = now.getMinutes();
                let todaySec = now.getSeconds();

                let qDiv;
                let eDiv;
                let regTime = String(todayYear)+String(todayMonth)+String(todayDate)+String(todayHour)+String(todayMin)+String(todaySec);

                if(selQuesdiv === "Multiple"){
                    qDiv = "M"
                }else{
                    qDiv = "D"
                }

                if(selEmpdiv === "Dentist"){
                    eDiv = "D"
                }else{
                    eDiv = "S"
                } 

                // 해당 그룹 및 직원 부서에 속하는 데이터를 가져옵니다.
                const querySnapshot = await getDocs(query(collection(db, "questionnaire"), where("groupid", "==", props.userGroup), where("empdiv", "==", selEmpdiv)));
                
                // 가져온 데이터의 개수를 세어 quesno를 설정합니다.
                const quesno = querySnapshot.size + 1;

                let fileid = props.userGroup + qDiv + eDiv + regTime;

                try {
                    await setDoc(doc(db, "questionnaire", fileid), {
                        fileid: fileid,
                        question: question,
                        quesno: quesno,
                        quesdiv: selQuesdiv,
                        empdiv: selEmpdiv,
                        groupid: props.userGroup,
                        regtime: regTime,
                        uploadUser: props.userNameData
                    });
                    Swal.fire({
                        icon: "success",
                        title: "문항 등록",
                        text: "문항 등록이 완료되었습니다",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            setSelEmpdiv("");
                            setSelQuesdiv("");
                            setQuestion("");
                            document.getElementById("_question").value = "";
                        }
                    }); 
                } catch (error) {
                    console.error("문항 추가 에러:", error);
                }
            }else{
                try{
                    await updateDoc(doc(db, "questionnaire", quesdata.id), {
                        question: question,
                        quesdiv: selQuesdiv
                    })
                    Swal.fire({
                        icon: "success",
                        title: "문항 편집",
                        text: "문항 편집이 완료되었습니다",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    })
                } catch(error){
                    console.log(error.message);
                }
            }
        }else {
            showAlert("error", "문항 미입력", "문항을 입력하시기 바랍니다.");
        }
    }

    const changeEmpdiv = (props) => {
        if(quesdata === "NEW"){
            setSelEmpdiv(props)
        }else{
            showAlert("error", "변경불가", "기존문항 수정 시 직원구분은 변경할 수 없습니다.");
        }
    }

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };


    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva">
                        <div>
                            <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>문항 편집</div>
                            <div style={{display:'flex',marginBottom:"3.5rem"}}>
                                <div style={{marginRight:"3.19rem"}}>
                                    <div className="renew_question_mod_subtitle">직원구분</div>
                                    <div style={{display:'flex'}}>
                                        <div className="renew_question_sel_unit" 
                                            style={{marginRight:"0.625rem",color:selEmpdiv === 'Dentist' && '#FFF',
                                                background:selEmpdiv === 'Dentist' && '#191919'}}
                                            onClick={() => changeEmpdiv('Dentist')}>원장</div>
                                        <div className="renew_question_sel_unit"
                                            style={{color:selEmpdiv === 'Staff' && '#FFF',
                                                background:selEmpdiv === 'Staff' && '#191919'}}
                                            onClick={() => changeEmpdiv('Staff')}>선생님</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="renew_question_mod_subtitle">유형</div>
                                    <div style={{display:'flex'}}>
                                        <div className="renew_question_sel_unit" 
                                            style={{marginRight:"0.625rem",color:selQuesdiv === 'Multiple' && '#FFF',
                                                background:selQuesdiv === 'Multiple' && '#191919'}}
                                            onClick={() => setSelQuesdiv('Multiple')}>객관식</div>
                                        <div className="renew_question_sel_unit"
                                            style={{color:selQuesdiv === 'Descriptive' && '#FFF',
                                                background:selQuesdiv === 'Descriptive' && '#191919'}}
                                            onClick={() => setSelQuesdiv('Descriptive')}>서술형</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="renew_question_mod_subtitle">문항</div>
                                <textarea id="_question" defaultValue={initialQuestion}
                                    style={{width:"27.3125rem",padding:"1rem",borderRadius:"0.5rem",background:'#F4F4F0',fontSize:"1.125rem",fontWeight:500}}
                                    onChange={(e)=>setQuestion(e.target.value)} />
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:"6.25rem"}}>
                            <button className="renew_question_mod" onClick={()=>navigate("/evaQuestionManage")}>뒤로</button>
                            <button className="renew_question_mod" style={{marginLeft:"1rem",background:'#191919',color:'#FFF'}}
                                    onClick={()=>insertQuestion()}
                            >저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaQueMngReg