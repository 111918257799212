import React, { useState, useCallback, useEffect } from 'react';
import { db } from '../firebase_config.js';
import { getDocs, collection, query, where } from "firebase/firestore";

function AdminMemberlist(props) {
    const [userList, setUserList] = useState([]);

    const callDb = useCallback(async () => {
        const snapshot = await getDocs(query(collection(db, "users"), where("groupid", "!=" ,"nocode"),
                                             where("newmemberconfirm", "==", "Y")));
        
        const existData = snapshot.docs.map(doc => {
            const data = doc.data();
            const regdateTimestamp = data.regdate; // 가정: regdate가 타임스탬프 형식으로 저장되어 있다고 가정
            const regdate = new Date(regdateTimestamp.toDate());
            const year = regdate.getFullYear();
            const month = regdate.getMonth() + 1;
            const day = regdate.getDate();
            const formattedRegdate = `${year}년 ${month}월 ${day}일`;
        
            return {
                ...data,
                regdate: formattedRegdate,
                id: doc.id
            };
        });
        setUserList(existData);
    },[])

    useEffect(() => {
        callDb();
    },[callDb])

    function ShowCommonCodeList() {
        return(
            <div>
                {userList
                .sort((a, b) => a.userName.localeCompare(b.userName)) // userName을 기준으로 오름차순 정렬
                .map((option,index) => (
                    <div key={option.id} style={{display:'flex',color:'#373737',fontSize:18,fontWeight:500,
                        height:55,borderBottom:'#E8E8E8 1px solid',background:index%2 === 0 && '#FAFAFA',
                        alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{width:'20%'}}>{option.userName}</div>
                        <div style={{width:'30%'}}>{option.dentalname}</div>
                        <div style={{width:'30%'}}>{option.userId}</div>
                        <div style={{width:'20%'}}>{option.regdate}</div> 
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="renew_background" style={{padding:50}}>
            <div className="renew_content_background" 
                 style={{minWidth:window.innerWidth-700,minHeight:window.innerHeight-200}}
            >
                <div className="renew_content_title" style={{marginBottom:72}}>승인가입자정보</div>
                <div style={{display:'flex',borderBottom:'#E8E8E8 1px solid',color:'#9F9F9F',fontSize:16,
                            fontWeight:500,paddingBottom:10,width:'100%'}}>
                    <div style={{width:'20%'}}>이름</div>
                    <div style={{width:'30%'}}>병원명</div>
                    <div style={{width:'30%'}}>아이디</div>
                    <div style={{width:'20%'}}>가입일자</div>
                </div>
                <ShowCommonCodeList />
            </div>
        </div>
    )
}

export default AdminMemberlist;