import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth, db } from '../firebase_config.js';
import { where, getDocs, collection, query } from "firebase/firestore";
import Swal from "sweetalert2";


function FindPW() {
    const navigate = useNavigate();

    const [registerEmail, setRegisterEmail] = useState("");
    const [registerUsername, setRegisterUsername] = useState("");
    const [registerUserphone, setRegisterUserphone] = useState("");

    const changePW = async () => {
        try {
            const userRef = collection(db, "users");
            const q = query(userRef, where("userId", "==", registerEmail),
                                    where("userName", "==", registerUsername),
                                    where("userPhone", "==", registerUserphone),
                            )
            const querySnapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
            if (querySnapshot.size === 1) {
                sendResetEmail(registerEmail);
            }else{
                showAlert("warning", "일치 회원정보 없음", "입력한 정보와 일치하는 회원정보가 없습니다.");
            }
        } catch (error) {
            // Handle any potential errors
            showAlert("warning", "비밀번호재설정 오류", error);
        }
    }

    const sendResetEmail = async (registerEmail) => {
        try {
            await sendPasswordResetEmail(auth, registerEmail);
            Swal.fire({
                icon: "success",
                title: "재설정메일 발송완료",
                text: "발송된 재설정메일을 확인해주세요",
                confirmButtonText: "확인",
            }).then((res) => {
                if (res.isConfirmed) {
                    navigate("/login");
                }
            });
        } catch (error) {
            showAlert("error", "메일발송 오류", error.message);
        }
    };

    const handleEmailCheck = (e) => {
        const newEmail = e.target.value;
        setRegisterEmail(newEmail);
    }

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background signup">
            <div className="renew_content_background">
                <div>
                    <div className="renew_content_title" >비밀번호 재설정</div> 
                    <div className="content_area" />
                    <div className="renew_inner_title">아이디(이메일)</div>
                    <div>
                        <input className="renew_evasignup"
                                id="_email" 
                                type="email" 
                                placeholder='아이디(이메일)'
                                onChange={handleEmailCheck}
                        />
                    </div>
                    <div className="renew_inner_title">이름</div>
                    <div>
                        <input className="renew_evasignup"
                                id="_username" 
                                type="text" 
                                onChange={(e)=>{setRegisterUsername(e.target.value);}}
                        />
                    </div>
                    <div className="renew_inner_title">전화번호</div>
                    <div>
                        <input className="renew_evasignup"
                                id="_phone" 
                                type="text" 
                                placeholder="예)01012345678" 
                                onChange={(e)=>{setRegisterUserphone(e.target.value);}}
                        />
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"13.38rem"}}>
                    {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                    <button className="renew_evasignup"
                            onClick={(e)=>{
                                e.preventDefault();
                                if(
                                    registerEmail !== "" &&
                                    registerUsername !== "" &&
                                    registerUserphone !== ""
                                ){
                                    changePW();
                                }else{
                                    Swal.fire({
                                        icon: "error",
                                        title: "미입력 값 존재",
                                        text: "입력하지 않은 항목이 존재합니다",
                                        //showCancelButton: true,
                                        confirmButtonText: "확인",
                                        //cancelButtonText: "취소",
                                    }).then((res) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (res.isConfirmed) {
                                            //확인 요청 처리
                                        }
                                    });
                                }
                            }}
                    >비밀번호 재설정</button>
                </div>
            </div>
        </div>
    )
}

export default FindPW;