import React from 'react';
import { useNavigate } from 'react-router-dom';

function TermsPersonalInfo() {
    const navigate = useNavigate();
    
    return (
        <div className="renew_background signup">
            <div className="renew_content_background">
                <div>
                    <div className="renew_content_title">개인정보수집및동의</div> 
                    <div className="content_area" style={{fontSize:"1rem"}}>
                        <div style={{fontSize:"1.125rem",fontWeight:500}}>시행일자 : 2024.03.05</div>
                        <br />
                        <div>덴탈커넥트 회원 개인정보처리동의서</div>
                        <br />
                        <div>제1장 개인정보 수집·이용 동의서</div>
                        <br />
                        <div>&lt;덴탈커넥트 개인정보 수집·이용 동의서&gt;<br />
                            덴탈커넥트는 서비스 이용을 위해 아래와 같이 개인정보를 수집·이용합니다.
                        </div>
                        <br />
                        <div>정보주체는 동의를 거부할 권리가 있으며, 동의 거부 시 덴탈커넥트 회원서비스 이용이 불가합니다.</div>
                        <br />
                        <div>&lt;목적&gt;<br />
                            본인여부 확인, 각종 맞춤형 서비스 제공, 서비스 개선 및 신규 서비스 개발을 위한 통계 활용, 계약 이행 및 약관변경 등의 고지를 위한 연락, 본인의사확인 및 민원 등 고객불만처리
                        </div>
                        <div>&lt;항목&gt;<br />
                            아이디, 비밀번호, 회원구분 및 직종, 이메일
                        </div>
                        <div>&lt;보유 및 이용기간&gt;<br />
                            홈페이지 회원자격 유지기간까지
                        </div>
                        <div>&lt;목적&gt;<br />
                            각종 맞춤형 서비스 제공
                        </div>
                        <div>&lt;항목&gt;<br />
                            이름, 생년월일, 휴대폰번호, 학력사항 또는 경력사항, 이메일, 전화번호, 주소, 홈페이지, 사진, 교육이수, 자격증
                        </div>
                        <div>&lt;보유 및 이용기간&gt;<br />
                            홈페이지 회원자격 유지기간까지
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"13.38rem"}}>
                    {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                    <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF'}}
                            onClick={()=>navigate(-1)}
                    >뒤로</button>
                </div>
            </div>
        </div>
    )
}

export default TermsPersonalInfo;