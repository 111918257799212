import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { query, collection, getDocs, where, orderBy } from "firebase/firestore"
import { db } from '../firebase_config.js';
import '../App.css'

import Arrowdown from '../images/renewal/Arrowdown_white.png';
import ArrowBack from '../images/renewal/Arrow-down.svg';
import LogoMobile from '../images/renewal/MobileLogo.svg';

function ViewVideo(props) {
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const navigate = useNavigate();
    const fromPage = state.from;
    const [fileinfo, setFileinfo] = useState(state.fileinfo);
    const [isWidthGreaterThanHeight, setIsWidthGreaterThanHeight] = useState(window.innerWidth > window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [contentList,setContentList] = useState([]);
    const [selTag, setSelTag] = useState(fileinfo.tag);
    const [isTagOpen, setIsTagOpen] = useState(false);
    const tagDropdownRef = useRef(null);
    const tagButtonRef = useRef(null);

    useEffect(() => {
        function handleResize() {
            const newIsWidthGreaterThanHeight = window.innerWidth > window.innerHeight;
            setIsWidthGreaterThanHeight(newIsWidthGreaterThanHeight);
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getWidth = () => {
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

        if (width > 1400) {
            return '87.5rem';
        } else if(!isWidthGreaterThanHeight) {
            return '59.0625rem';
        } else {
            return `${width - (16 * rootFontSize)}px`;
        }
    };

    const getHeight = () => {
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

        if (width > 1400) {
            return `${height - (15 * rootFontSize)}px`;
        } else if(!isWidthGreaterThanHeight) {
            return '35.3125rem';
        } else {
            return `${height - (12 * rootFontSize)}px`;
        }
    };

    useEffect(() => {
        if(fromPage !== "patientcontent"){
            const fetchData = async () => {
                const q = query(collection(db, "contents"), where("sysdiv", "==", props.sysDiv), 
                                where("repcateval","==", fileinfo.repcateval),
                                orderBy("uploadtime","desc"));
                const snapshot = await getDocs(q);
            
                const data = snapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setContentList(data);
            }
        
            fetchData(); // fetchData 함수를 즉시 호출
        }
    }, [props, fileinfo.repcateval, fromPage])

    useEffect(() => {
        function handleClickOutside(event) {
            if (tagDropdownRef.current && !tagDropdownRef.current.contains(event.target)&&
                tagButtonRef.current && !tagButtonRef.current.contains(event.target)) {
                // setSelCategory(fileinfo.cateval);
                setSelTag(fileinfo.tag);
                setIsTagOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [fileinfo.cateval, fileinfo.tag]);

    const toggleTagDropdown = () => {
        setIsTagOpen(!isTagOpen);
    };

    const toDentalExplanPage = () => {
        navigate("/", {
            state: {
                cateval: fileinfo.cateval,
                repcateval: fileinfo.repcateval,
                sysdiv: "DC"
            }
        });
    };

    return (
        <div className="renew_background drawvideo" >
            <div className="drawpage_content_area" >
                <div className="header_group">
                    <div style={{display:'flex'}}>
                        <div className="renew_video_back"
                                onClick={()=>{
                                    if(fromPage !== "patientcontent"){
                                        toDentalExplanPage()
                                    }else{
                                        navigate(-1)
                                    }
                                }}
                        >
                            <img src={ArrowBack} alt='뒤로' style={{width:"1.5rem",height:"1.5rem"}} />
                        </div>
                        {fromPage !== "patientcontent" &&
                            <div style={{position:'relative'}}>
                                <div ref={tagButtonRef}
                                    className="renew_content_sel"
                                    onClick={toggleTagDropdown}
                                >
                                    <div>{selTag}</div>
                                    <img src={Arrowdown} alt='선택' style={{width:"1.5rem",height:"1.5rem"}}></img>
                                </div>
                                {isTagOpen && (
                                    <div className="renew_dropdown_menu" ref={tagDropdownRef}>
                                        {contentList
                                        .map(content => (
                                            <div key={content.id} 
                                                style={{marginBottom:"1.64rem",cursor:'pointer',
                                                        color:selTag === content.tag ? "#FFF" : "#A9A9A9"}}
                                                onClick={()=>{
                                                    if(content.filetype === "image"){
                                                        navigate("/drawPage", {
                                                            state: {
                                                                fileinfo: content,
                                                            },
                                                        });
                                                    }else{
                                                        setFileinfo(content);
                                                        setSelTag(content.tag);
                                                        toggleTagDropdown();
                                                    }
                                                }}
                                            >{content.tag}</div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
                <div style={{width:getWidth(),height:getHeight()}}>
                    <div style={{width:'100%',height:'100%',backgroundColor:'#D4D4D4',borderRadius:"1.25rem",
                                boxShadow:"9px 4px 42px 4px rgba(0,0,0,0.04)",display:'flex',alignItems:'center'}}
                    >
                        <video key={fileinfo.id} controls autoPlay loop style={{maxWidth:'100%',maxHeight:'100%'}}>
                            <source src={fileinfo.fileAddr} type="video/mp4" />
                            <source src={fileinfo.fileAddr} type="video/quicktime" />
                        </video>
                    </div>
                </div>
            </div>
            <div className="drawpage_content_area_mobile" style={{width:window.innerWidth,height:window.innerHeight}}>
                <img src={LogoMobile} alt="모바일로고" style={{width:"5rem",height:"6.125",flexShrink:0,marginBottom:"1.56rem"}} />
                <div>더 나은 서비스를 위해</div>
                <div>큰 화면으로 이용해주세요.</div>
            </div>
        </div>
    )
}

export default ViewVideo