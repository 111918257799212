import React, { useState, useCallback, useEffect, useRef } from 'react';
import { db } from '../firebase_config.js';
import { getDocs, collection, query, doc, updateDoc, setDoc, deleteDoc, where, Timestamp, orderBy } from "firebase/firestore";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from 'react-router-dom';

import CheckGreen from '../images/renewal/Check_green.svg';
import PlusRed from '../images/renewal/Plus_red.svg';
import MinusRed from '../images/renewal/Minus_red.svg';
import Search from '../images/renewal/Search.svg';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function CrmMain(props) {
    const userGroup = props.userGroup;
    const navigate = useNavigate();
    const { state } = useLocation();
    const coCode = state.coData;

    const [personInfo, setPersonInfo] = useState([]);
    const [personInfoRaw, setPersonInfoRaw] = useState([]);

    const [showCalendar, setShowCalendar] = useState(false);  //방문예약일자 달력 보이기 기능
    const [dataId, setDataId] = useState(); // 달력선택했을 때 지정된 고객데이터
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });

    const [employeelist, setEmployeeList] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [calendarModal, setCalendarModal] = useState(false);
    const [textboxModal, setTextboxModal] = useState(false);
    const [newCustomer, setNewCustomer] = useState('');
    const [newCustomerphone, setNewCustomerphone] = useState('');
    const [searchName, setSearchName] = useState('');

    const [currentText, setCurrentText] = useState('');
    const [currentId, setCurrentId] = useState(null);
    const [currentField, setCurrentField] = useState(null);

    const calendarRef = useRef(null);
    const messageRef = useRef(null);

    const callDb = useCallback(async () => {
        //환자리스트 조회
        const personalInfoRef = collection(db, "crmpersonalinfo");
        const q = query(personalInfoRef, where("groupid", "==", userGroup), orderBy("regdate"));
        const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

        const personData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            selected: false
        }));
        
        setPersonInfo(personData);
        setPersonInfoRaw(personData);

        //소속그룹 직원 정보 조회
        const dRef = collection(db, "employees");
        const dq = query(dRef, where("groupid", "==", userGroup)
                             , orderBy("empno"));
        const dSnapshot = await getDocs(dq);

        const dData = dSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        setEmployeeList(dData);
    },[userGroup])

    useEffect(() => {
        callDb();
    }, [callDb]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //저장완료됐을때 저장완료 div 보이게 하는 로직
    const handleSaveComplete = () => {
        // 가장 왼쪽으로 스크롤 이동
        // window.scrollTo(0, 0);

        // 메시지를 보이게 합니다.
        if (messageRef.current) {
            messageRef.current.style.display = 'flex';
        }

        // 0.5초 후에 메시지를 숨깁니다.
        setTimeout(() => {
            if (messageRef.current) {
                messageRef.current.style.display = 'none';
            }
        }, 500);
        callDb();
    };

    //신규고객 db 저장 로직
    async function SaveNewCust (name, phone) {
        const custId = name+phone+userGroup+1;

        try{
            await setDoc(doc(db, "crmpersonalinfo", custId),{
                name: name,
                phone: phone,
                age: "",
                carecate: "",
                cc: "",
                companion: "",
                companioninflu: "",
                decisionpoint: "",
                local: "",
                prefercounsel: "",
                purpose: "",
                reasonabandon: "",
                route: "",
                subseq: 1,
                groupid: userGroup,
                regdate: "",
                incdentist: "",
                incstaff: "",
            });

            await setDoc(doc(db, "crmcontactinfo", custId),{
                name: name,
                phone: phone,
                age: "",
                carecate: "",
                consultyn: "",
                consultdate: "",
                visityn: "",
                visitdate: "",
                proceedyn: "",
                proceeddate: "",
                reserveyn: "",
                reservedate: "",
                diagnosisyn: "",
                diagnosisdate: "",
                diagconsultyn: "",
                therapyyn: "",
                therapydate: "",
                csltexpectdate: "",
                etcproceedyn: "",
                etcproceeddate: "",
                etcreservedate: "",
                etcreserveyn: "",
                therapyexdate: "",
                therapyexyn: "",
                day0check: "N",
                day3check: "N",
                week1check: "N",
                week2check: "N",
                month1check: "N",
                month3check: "N",
                completeyn: "N",
                step: 0,
                subseq: 1,
                groupid: userGroup,
                incdentist: "",
                incstaff: "",
                incdentistname: "",
                incstaffname: "",
            });

            setNewCustomer('');
            setNewCustomerphone('');
            setIsModalOpen(false);
            handleSaveComplete();
        }catch(error){
            console.log(error)
        }
    }

    //고객이름조회 input 처리로직
    const handleSearch = () => {
        setPersonInfo(personInfoRaw);
        
        if(searchName !== ""){
            const filteredCustomer = personInfoRaw.filter(item => item.name.includes(searchName));
            setPersonInfo(filteredCustomer);
        }else{
            callDb();
        }
        // 가장 왼쪽으로 스크롤 이동
        window.scrollTo(0, 0);
    }

    function RenderSelect (props) {
        const { val, id, field, cocode } = props;
        const filteredCoCode = coCode.filter(item => item.code === cocode);
        const [selVal, setSelVal] = useState(val);

        const fieldWidthMap = {
            route: 150,
            purpose: 80,
            carecate: 100,
            prefercounsel: 80,
            companion: 80,
            companioninflu: 100,
        };

        const handleChange = async (e) => {
            const newValue = e.target.value;
            setSelVal(newValue); // 상태 업데이트
    
            const ref = doc(db, "crmpersonalinfo", id);
            try {
                await updateDoc(ref, { [field]: newValue }); // 데이터베이스 업데이트
                handleSaveComplete();
            } catch (error) {
                console.log(error);
            }
        };

        return(
            <select
                name={field}
                value={selVal}
                className="renew_crm_main_attribute_input"
                style={{ width: fieldWidthMap[field] || 100 }}
                onChange={handleChange}
            >
                {val === "" && <option></option>}
                {filteredCoCode.map((option) => (
                    <option key={option.id} value={option.value}>{option.value}</option>
                ))}
            </select>
        )
    }

    function RenderEmpSelect(props) {
        const { val, id, field } = props;
        const [selVal, setSelVal] = useState(val);
        const nameField = `${field}name`;
    
        const filteredList = employeelist.filter(row => 
            field === "incstaff" ? row.empdiv === "Staff" : row.empdiv === "Dentist"
        );
    
        const handleChange = async (e) => {
            const newValue = e.target.value;
            const selectedText = e.target.options[e.target.selectedIndex].text;
            setSelVal(newValue); // 상태 업데이트
    
            const ref = doc(db, "crmpersonalinfo", id);
            const contactRef = doc(db, "crmcontactinfo", id);
            try {
                await updateDoc(ref, { [field]: newValue }); // 데이터베이스 업데이트
                await updateDoc(contactRef, {
                    [field]: newValue,
                    [nameField]: selectedText
                });
                handleSaveComplete();
            } catch (error) {
                console.log(error);
            }
        };
    
        return (
            <select
                name={field}
                value={selVal}
                className="renew_crm_main_attribute_input"
                style={{ width: 80 }}
                onChange={handleChange}
            >
                {val === "" && <option></option>}
                {filteredList.map((option) => (
                    <option key={option.id} value={option.fileid}>{option.name}</option>
                ))}
            </select>
        );
    }

    //체크박스 선택로직
    const handleCheckboxChange = (id) => {
        setPersonInfo((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, selected: !row.selected } : row
            )
        );
    }

    //체크박스 선택한 항목 삭제로직
    const handleDeleteSelected = () => {
        const selectedIds = personInfo.filter(row => row.selected === true);

        if(selectedIds.length === 0){
            Swal.fire({
                icon: "error",
                title: "고객 삭제",
                text: "선택된 고객이 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }else{
            Swal.fire({
                icon: "question",
                title: "고객 삭제",
                text: "선택된 고객 삭제를 하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "삭제",
                cancelButtonText: "취소",
            }).then((res) => {
                if (res.isConfirmed) {
                    try{
                        selectedIds.map(async (row) => {
                            await deleteDoc(doc(db, "crmpersonalinfo", row.id))
                            await deleteDoc(doc(db, "crmcontactinfo", row.id))
                        })
                        Swal.fire({
                            icon: "success",
                            title: "고객 삭제",
                            text: "고객 삭제가 완료되었습니다",
                            //showCancelButton: true,
                            confirmButtonText: "확인",
                            //cancelButtonText: "취소",
                        }).then((res) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (res.isConfirmed) {
                                callDb();
                            }
                        });
                    }
                    catch(error){
                        console.log(error);
                    }
                }
            });
        }
    };

    //contact 페이지 이동
    const toCrmContactPage = (props) => {
        navigate("/crmContact", {
            state: {
                personalId: props,
                coData: coCode
            },
        })
    }

    //input 값 변경
    async function UpdateInput(id, val, field){
        const ref = doc(db, "crmpersonalinfo", id);
        const contactRef = doc(db, "crmcontactinfo", id);

        try{
            await updateDoc(ref, {
                [field]: val
            })
            handleSaveComplete();
        }catch(error){
            console.log(error)
        }

        if(field === "age"){
            try{
                await updateDoc(contactRef, {
                    [field]: val
                })
                handleSaveComplete();
            }catch(error){
                console.log(error)
            }
        }
    }

    const handleCalendarClick = async (date, field) => {
        const timestampDate = Timestamp.fromDate(date);

        const ref = doc(db, "crmpersonalinfo", dataId);
        try{
            if(field === "regdate"){
                setCalendarModal(false);
                setShowCalendar(!showCalendar);
                await updateDoc(ref, {
                    regdate: timestampDate,
                })
            }
            handleSaveComplete();
        }catch(error){
            console.log(error)
        }
    };

    function ViewCalendar() {
        const currentDate = new Date(); // 현재 날짜로 설정
      
        return (
            <>
                {showCalendar && (
                    <div className="sliding_calendar_regdate"
                        style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                        ref={calendarRef}
                    >
                        <Calendar
                            onChange={(e)=>handleCalendarClick(e, "regdate")}
                            defaultActiveStartDate={currentDate}
                        />
                    </div>
                )}
            </>
        );
    }

    const handleClickOutside = (e) => {
        if (calendarRef.current && !calendarRef.current.contains(e.target)) {
            setShowCalendar(false);
        }
    };

    //일자 데이터 년 월 일 로 표기하도록 바꾸는 로직
    function FormattedValDateRenew(props) {
        const { fieldval, valdate, dataid } = props;
    
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더합니다.
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
    
        const handleDateClick = (e) => {
            const { top, left, height } = e.target.getBoundingClientRect();
            let newTop = top + height; // 원하는 위치 조절
            const newLeft = left; // 원하는 위치 조절
            const windowHeight = window.innerHeight;
            const verticalCenter = windowHeight / 2; // 화면의 세로 중앙 위치
    
            if (top > verticalCenter) {
                newTop = top - 265;
            }
    
            setCalendarPosition({ top: newTop, left: newLeft });
            setDataId(dataid);
            setShowCalendar(!showCalendar);
            setCalendarModal(true);
        };
    
        if (fieldval !== "regdate") {
            return null;
        }
    
        return (
            <div
                onClick={handleDateClick}
                style={{ width: 95, padding: 10, cursor: "pointer" }}
            >
                {valdate && valdate !== "" ? formatDate(valdate.toDate()) : ""}
            </div>
        );
    }

    //input 값 그리기
    function RenderInputRenew(props) {
        const { val, field, id } = props;
        const [stateVal, setStateVal] = useState(val);
    
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                UpdateInput(id, stateVal, field);
            }
        };
    
        // 필드에 따라 동적 스타일 및 클래스 설정
        const getInputProps = (field) => {
            let width = 80;
            switch (field) {
                case "age":
                case "local":
                case "purpose":
                    width = 80;
                    break;
                case "route":
                    width = 150;
                    break;
                case "decisionpoint":
                    width = 100;
                    break;
                default:
                    break;
            }
            return {
                name: field,
                className: "renew_crm_main_attribute_input",
                style: { width: width, height: "100%" },
                value: stateVal,
                onChange: (e) => setStateVal(e.target.value),
                onKeyPress: handleKeyPress
            };
        };
    
        return <input type="text" {...getInputProps(field)} />;
    }

    const handleNewButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setNewCustomer('');
        setNewCustomerphone('');
        setIsModalOpen(false);
    };

    const handleCloseCalendarModal = () => {
        setCalendarModal(false);
        setShowCalendar(false);
    };

    const handleViewTextboxClick = (cc, id, field) => {
        setCurrentText(cc);
        setCurrentId(id);
        setCurrentField(field);
        setTextboxModal(true);
    };

    const handleCloseTextboxModal = () => {
        setTextboxModal(false);
        setCurrentText('');
        setCurrentId(null);
        setCurrentField(null);
    };

    const handleSaveNewCustomer = async () => {
        // 새로운 고객 저장 로직을 여기에 추가합니다.
        const onlyNumber = newCustomerphone.replace(/[^0-9]/g, '')
        const isNewCustomer = personInfoRaw.filter(item => item.name === newCustomer && item.phone === onlyNumber);

        if(newCustomer !== "" && onlyNumber !== ""){
            if(isNewCustomer.length !== 0){
                showAlert("error", "등록고객 존재", "이미 등록한 고객이 존재합니다.");
            }else{
                SaveNewCust(newCustomer, onlyNumber);
            }
        }else{
            showAlert("error", "미입력 값 존재", "입력하지 않은 값이 존재합니다.");
        }
    };

    const handleUpdateTextbox = async () => {
        const ref = doc(db, "crmpersonalinfo", currentId);
        try{
            await updateDoc(ref, {
                [currentField]: currentText
            })
            handleCloseTextboxModal();
            handleSaveComplete();
        }catch(error){
            console.log(error)
        }
    }

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',touchAction:'none'}}>
            <div ref={messageRef} className="renew_save_complete" style={{display:'none'}}>
                <img src={CheckGreen} alt='종결' style={{width:"0.8125rem",height:"0.8125rem",marginRight:"0.5rem"}} />
                <div>저장 완료</div>
            </div>
            <div className="crmmain_background">
                <div style={{display:'flex',justifyContent:'space-between',marginBottom:"1.75rem"}}>
                    <div className="renew_content_title" >CRM</div>
                    <div className="renew_content_title" style={{color:'#BEBEBE',cursor:'pointer'}} onClick={()=>navigate("/crmNotiCenter")}>알림센터</div>
                </div>
                <div style={{display:'flex',justifyContent:'space-between',marginBottom:"1.25rem"}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div className="renew_crm_main_button" style={{marginRight:"0.5rem"}} onClick={handleNewButtonClick}>
                            <img src={PlusRed} alt='생성' style={{width:"1rem",height:"1rem",marginRight:"0.5rem"}} />
                            <div>고객 생성</div>
                        </div>
                        <div className="renew_crm_main_button" style={{marginRight:"1rem"}} onClick={handleDeleteSelected}>
                            <img src={MinusRed} alt='삭제' style={{width:"1rem",height:"1rem",marginRight:"0.5rem"}} />
                            <div>고객 삭제</div>
                        </div>
                        <div className="crmmain_infotext web">*직접 텍스트를 입력하는 항목(예:나이,방문지역 등)은 입력 후 'Enter'키 치면 저장됩니다.</div>
                    </div>
                    <div className="crm_searchbox">
                        <input
                            type="search"
                            placeholder="고객이름"
                            value={searchName}
                            onChange={e => setSearchName(e.target.value)}
                            style={{color:'#CECECE',fontSize:"0.875rem",fontWeight:500,width:"70%"}}
                            onFocus={() => document.querySelector('.crm_searchbox').style.border = '1px solid #E6CE9F'}
                            onBlur={() => document.querySelector('.crm_searchbox').style.border = '1px solid #F3ECE0'}
                            onKeyPress={(e)=>{if(e.key === 'Enter'){ handleSearch()}}}
                        />
                        <img src={Search} alt="검색" style={{width:"1.5rem",height:"1.5rem",cursor:'pointer'}} onClick={handleSearch} />
                    </div>
                </div>
                <div className="crmmain_infotext mobile">
                    <div>*직접 텍스트를 입력하는 항목(예:나이,방문지역 등)은</div>
                    <div>입력 후 'Enter'키 치면 저장됩니다.</div>
                </div>
                <div className="crmmain_content_area">
                    <div style={{position:'sticky',display:'inline-flex',color:'#9F9F9F',fontSize:"0.875rem",fontWeight:500,whiteSpace:'nowrap',
                                borderBottom:'1px #E8E8E8 solid',zIndex:1,top:0,background:'#FFF'}}>
                        <div style={{width:30,padding:10}} />
                        <div style={{width:95,padding:10}}>날짜</div>
                        <div style={{width:150,padding:10}}>이름</div>
                        <div style={{width:150,padding:10}}>전화번호</div>
                        <div style={{width:80,padding:10}}>나이</div>
                        <div style={{width:80,padding:10}}>방문지역</div>
                        <div style={{width:150,padding:10}}>내원경로</div>
                        <div style={{width:80,padding:10}}>환자구분</div>
                        <div style={{width:80,padding:10}}>상담자</div>
                        <div style={{width:80,padding:10}}>진료의사</div>
                        <div style={{width:200,padding:10}}>CC</div>
                        <div style={{width:100,padding:10}}>진료 카테고리</div>
                        <div style={{width:80,padding:10}}>상담호의도</div>
                        <div style={{width:100,padding:10}}>결정포인트</div>
                        <div style={{width:80,padding:10}}>동반자유무</div>
                        <div style={{width:100,padding:10}}>동반자영향력</div>
                        <div style={{width:200,padding:10}}>진행포기 이유 및 해결책</div>
                    </div>
                    {personInfo
                        .map((option,index) => (
                            <div key={index} style={{display:'inline-flex',color:'#373737',fontSize:"0.9375rem",fontWeight:500,whiteSpace:'nowrap',borderBottom:'1px #E8E8E8 solid',
                                                    alignItems:'center',height:"2.5rem"}}
                            >
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:30,padding:"0.8125rem 0.5rem"}}>
                                    <input name="body_checkbox" 
                                        type="checkbox"
                                        style={{width:"0.875rem",height:"0.875rem"}}
                                        checked={option.selected || false}
                                        onChange={() => {handleCheckboxChange(option.id)}}
                                    />
                                </div>
                                <FormattedValDateRenew valdate={option.regdate} fieldval="regdate" dataid={option.id}/>
                                <div style={{width:150,padding:10,borderLeft:'1px #E8E8E8 solid',borderRight:'1px #E8E8E8 solid'}}>
                                    <div style={{display:'inline-flex',padding:"4px 6px",justifyContent:'center',alignItems:'center',
                                                borderRadius:4,background:'#F6F6F6',color:'#565656',cursor:'pointer'}} 
                                        onClick={()=>toCrmContactPage(option.id)}
                                    >{option.name}</div>
                                </div>
                                <div style={{width:150,padding:10,borderRight:'1px #E8E8E8 solid'}}>{option.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</div>
                                <RenderInputRenew val={option.age} field="age" id={option.id} />
                                <RenderInputRenew val={option.local} field="local" id={option.id} />
                                <RenderSelect val={option.route} id={option.id} field="route" cocode="008" />
                                <RenderSelect val={option.purpose} id={option.id} field="purpose" cocode="009" />
                                <RenderEmpSelect val={option.incstaff} id={option.id} field="incstaff" />
                                <RenderEmpSelect val={option.incdentist} id={option.id} field="incdentist" />
                                {/* <DentistSel incdentist={option.incdentist} id={option.id}/> */}
                                {option.cc !== "" ? 
                                    <div style={{width:200,padding:10,borderRight:'1px #E8E8E8 solid',cursor:'pointer'}}
                                        onClick={() => handleViewTextboxClick(option.cc, option.id, "cc")}
                                    >{option.cc}</div>
                                    :<div style={{width:200,height:40,borderRight:'1px #E8E8E8 solid',cursor:'pointer'}} 
                                        onClick={() => handleViewTextboxClick(option.cc, option.id, "cc")} />
                                }
                                <RenderSelect val={option.carecate} id={option.id} field="carecate" cocode="001" />
                                <RenderSelect val={option.prefercounsel} id={option.id} field="prefercounsel" cocode="002" />
                                <RenderInputRenew val={option.decisionpoint} field="decisionpoint" id={option.id} />
                                <RenderSelect val={option.companion} id={option.id} field="companion" cocode="004" />
                                <RenderSelect val={option.companioninflu} id={option.id} field="companioninflu" cocode="003" />
                                {option.reasonabandon !== "" ? 
                                    <div style={{width:200,padding:10,borderRight:'1px #E8E8E8 solid',cursor:'pointer'}}
                                        onClick={() => handleViewTextboxClick(option.reasonabandon, option.id, "reasonabandon")}
                                    >{option.reasonabandon}</div>
                                    :<div style={{width:200,height:40,borderRight:'1px #E8E8E8 solid',cursor:'pointer'}} 
                                        onClick={() => handleViewTextboxClick(option.reasonabandon, option.id, "reasonabandon")} />
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
            <ViewCalendar />
            {isModalOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "31.25rem",
                        height: "16.88rem",
                        background: '#FFF',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        borderRadius: 16,
                        padding: "1.25rem",
                        zIndex: 1000,
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-between'
                    }}
                >
                    <div>
                        <div style={{color:'#191919',fontSize:"1.5rem",fontWeight:600}}>고객생성</div>
                        <input
                            type="text"
                            value={newCustomer}
                            onChange={(e) => setNewCustomer(e.target.value)}
                            style={{width:'100%',borderRadius:"0.5rem",border:'1px #E8E8E8 solid',padding:"0.875rem",
                                    margin:'1.25rem 0rem',color:'#ABABAB',fontSize:"1rem",fontWeight:500}}
                            placeholder="이름"
                        />
                        <input
                            type="text"
                            value={newCustomerphone}
                            onChange={(e) => setNewCustomerphone(e.target.value)}
                            style={{width:'100%',borderRadius:"0.5rem",border:'1px #E8E8E8 solid',padding:"0.875rem",
                                    color:'#ABABAB',fontSize:"1rem",fontWeight:500}}
                            placeholder="전화번호"
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            onClick={handleCloseModal}
                            style={{
                                background: '#EFEFEF',
                                color: '#373737',
                                borderRadius: "0.5rem",
                                padding: '0.625rem 1.25rem',
                                marginRight: "0.625rem",
                                fontSize:"0.875rem",
                                fontWeight:500
                            }}
                        >취소</button>
                        <button
                            onClick={handleSaveNewCustomer}
                            style={{
                                background: '#191919',
                                color: '#FFF',
                                borderRadius: "0.5rem",
                                padding: '0.625rem 1.25rem',
                                fontSize:"0.875rem",
                                fontWeight:500
                            }}
                        >저장</button>
                    </div>
                </div>
            )}
            {isModalOpen && (
                // 모달영역 바깥 부분
                <div
                    onClick={handleCloseModal}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.5)',
                        zIndex: 999,
                    }}
                />
            )}
            {calendarModal && (
                // 모달영역 바깥 부분
                <div
                    onClick={handleCloseCalendarModal}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0)',
                        zIndex: 999,
                    }}
                />
            )}
            {textboxModal && (
                // 모달영역 바깥 부분
                <div
                    onClick={handleCloseTextboxModal}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.1)',
                        zIndex: 999,
                    }}
                />
            )}
            {textboxModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: "31.25rem",
                        height: "16.88rem",
                        background: '#FFF',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        borderRadius: "1rem",
                        padding: "1.25rem",
                        zIndex: 1000,
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-between'
                    }}
                >
                    <textarea
                        value={currentText}
                        onChange={(e) => setCurrentText(e.target.value)}
                        style={{
                            width: '100%',
                            height: '90%',
                            fontSize: "0.875rem",
                            padding: "0.625rem",
                            borderRadius: "0.5rem",
                            borderColor: '#E8E8E8',
                            resize: 'none',
                            marginBottom: "1.25rem"
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button
                            onClick={handleCloseTextboxModal}
                            style={{
                                background: '#EFEFEF',
                                color: '#373737',
                                borderRadius: "0.5rem",
                                padding: '0.625rem 1.25rem',
                                marginRight: "0.625rem",
                                fontSize:"0.875rem",
                                fontWeight:500
                            }}
                        >취소</button>
                        <button
                            onClick={handleUpdateTextbox}
                            style={{
                                background: '#191919',
                                color: '#FFF',
                                borderRadius: "0.5rem",
                                padding: '0.625rem 1.25rem',
                                marginRight: "0.625rem",
                                fontSize:"0.875rem",
                                fontWeight:500
                            }}
                        >저장</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CrmMain;