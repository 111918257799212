import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { getDocs, where, collection, query } from "firebase/firestore";
import Swal from "sweetalert2";

function EvaSurveyPublicFront() {
    const navigate = useNavigate();
    const [registerUserbirth, setRegisterUserbirth] = useState("");
    const [registerUserphone, setRegisterUserphone] = useState("");

    const patientChk = async () => {   
        let now = new Date();
        let todayYear = now.getFullYear();
        let todayMonth = now.getMonth() + 1;
        let todayDate = now.getDate();
        let registerDate = String(todayYear)+String(todayMonth)+String(todayDate);

        const contentsRef = collection(db, "evapatient");
        const q = query(contentsRef, where("userphone", "==", registerUserphone),
                                     where("userbirth", "==", registerUserbirth),
                                     where("regdate", "==", registerDate)
                        );
        const querySnapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

        if (querySnapshot.docs.length === 0) {
            Swal.fire({
                icon: "error",
                title: "오늘 등록된 환자정보 없음",
                text: "환자등록을 하고 이용해주세요.",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        } else {
            let surveyYn //설문조사 완료여부 체크
            let userinfo;

            querySnapshot.forEach((doc) => {
                surveyYn = doc.data().surveyYn;
                userinfo = doc.data();
            });

            if(surveyYn === "N"){
                navigate("/evaSurveyPublicBack", {
                    state: {
                        userinfo: userinfo,
                    },
                })
            }else{
                Swal.fire({
                    icon: "error",
                    title: "고객만족도 조사 완료",
                    text: "이미 고객만족도 조사를 완료하셨습니다.",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
            }
        }
    }

    const patientChkClick = () => {
      if(
          registerUserbirth !== "" &&
          registerUserphone !== ""
      ){
          patientChk();
      }else{
          Swal.fire({
              icon: "error",
              title: "미입력 값 존재",
              text: "입력하지 않은 항목이 존재합니다",
              //showCancelButton: true,
              confirmButtonText: "확인",
              //cancelButtonText: "취소",
          }).then((res) => {
              /* Read more about isConfirmed, isDenied below */
              if (res.isConfirmed) {
                  //확인 요청 처리
              }
          });
      }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            patientChkClick();
        }
    }

    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background" style={{marginTop:"4rem"}}>
                        <div>
                            <div className="renew_content_title">고객만족도 조사</div>
                            <div className="content_area" />
                            <div className="renew_inner_title">생년월일</div>
                            <input className="renew_evasignup" 
                                id="_birthdate" 
                                type="text" 
                                placeholder='예 ) 940214'
                                onChange={(e)=>{
                                    //숫자만저장되게
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setRegisterUserbirth(onlyNumber);
                                }}>
                            </input>
                            <div className="renew_inner_title">전화번호</div>
                            <input className="renew_evasignup" 
                                id="_phone" 
                                type="text" 
                                placeholder='예 ) 01012345678'
                                onChange={(e)=>{
                                    //숫자만저장되게 로직 변경 23.05.24
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setRegisterUserphone(onlyNumber);
                                }}
                                onKeyPress={onKeyPress}>
                            </input>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:"6.25rem"}}>
                            {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                            <button className="renew_evasignup" onClick={patientChkClick}>다음</button>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default EvaSurveyPublicFront;