import React, { useState, useCallback, useEffect } from 'react';
import { db } from '../firebase_config.js';
import { getDocs, setDoc, doc, collection, query, deleteDoc, orderBy } from "firebase/firestore";
import Swal from "sweetalert2";

function AdminCommon(props) {
    const userAuth = props.userAuth;
    
    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);

    const [commonCodeList, setCommonCodeList] = useState([]);
    const [code, setCode] = useState("");
    const [codeName, setCodeName] = useState("");
    const [subSeq, setSubSeq] = useState("");
    const [value, setValue] = useState("");

    const callDb = useCallback(async () => {
        const comSnapshot = await getDocs(query(collection(db, "commoncode"), orderBy('code'), orderBy('subseq')));
        const existData = comSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        setCommonCodeList(existData);
    },[])

    useEffect(() => {
        callDb();
    },[callDb, updateState])

    const registerCommonData = async () => {
        if(code !== "" && codeName !== "" && subSeq !== "" && value !== ""){
            if(commonCodeList.filter(item => item.code === code && item.subseq === subSeq).length === 0){
                const codeDocName = code+codeName+subSeq
                try{     
                    await setDoc(doc(db, "commoncode", codeDocName),{
                        code: code,
                        codename: codeName,
                        subseq: parseInt(subSeq, 10),
                        value: value,
                    });
                    Swal.fire({
                        icon: "success",
                        title: "공통코드등록",
                        text: "공통코드등록이 완료되었습니다",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            setUpdateState(!updateState);
                        }
                    });
                } catch(error) {
                    console.log("에러메세지:", error.message);
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "공통코드등록 실패",
                    text: "존재하는 공통코드",
                    // showCancelButton: true,
                    confirmButtonText: "확인",
                    // cancelButtonText: "취소",
                }).then((res) => {
                    if (res.isConfirmed) {
                        // props.onDelete();
                    }
                });
            }   
        }else{
            Swal.fire({
                icon: "error",
                title: "공통코드등록 실패",
                text: "입력값 없음",
                // showCancelButton: true,
                confirmButtonText: "확인",
                // cancelButtonText: "취소",
            }).then((res) => {
                if (res.isConfirmed) {
                    // props.onDelete();
                }
            });
        }
    }

    function ShowCommonCodeList() {
        return(
            <div>
                {commonCodeList.map((option,index) => (
                    <div key={option.id} style={{display:'flex',color:'#373737',fontSize:18,fontWeight:500,
                        height:55,borderBottom:'#E8E8E8 1px solid',background:index%2 === 0 && '#FAFAFA',
                        alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{width:'20%'}}>{option.code}</div>
                        <div style={{width:'30%'}}>{option.codename}</div>
                        <div style={{width:'10%'}}>{option.subseq}</div>
                        <div style={{width:'30%'}}>{option.value}</div>
                        <div style={{width:'10%'}}>
                            <button onClick={async ()=>{
                                    if(userAuth === 7){
                                        await deleteDoc(doc(db, "commoncode", option.id))
                                        Swal.fire({
                                            icon: "question",
                                            title: "삭제 완료",
                                            text: "공통코드 삭제 완료",
                                            // showCancelButton: true,
                                            confirmButtonText: "확인",
                                            // cancelButtonText: "취소",
                                        }).then((res) => {
                                            if (res.isConfirmed) {
                                                // props.onDelete();
                                                setUpdateState(!updateState);
                                            }
                                        });
                                    }else{
                                        Swal.fire({
                                            icon: "error",
                                            title: "삭제 실패",
                                            text: "",
                                            // showCancelButton: true,
                                            confirmButtonText: "확인",
                                            // cancelButtonText: "취소",
                                        }).then((res) => {
                                            if (res.isConfirmed) {
                                                // props.onDelete();
                                            }
                                        });
                                    }
                                }}
                                >삭제
                            </button>
                        </div>  
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="renew_background" style={{padding:50}}>
            <div className="renew_content_background" 
                 style={{minWidth:window.innerWidth-700,minHeight:window.innerHeight-200}}
            >
                <div className="renew_content_title" style={{marginBottom:72}}>공통코드관리</div>
                <div>
                    <div style={{display:'flex',borderBottom:'#E8E8E8 1px solid',color:'#9F9F9F',fontSize:16,
                                fontWeight:500,paddingBottom:10,width:'100%'}}>
                        <div style={{width:'20%'}}>코드</div>
                        <div style={{width:'30%'}}>코드명</div>
                        <div style={{width:'10%'}}>순번</div>
                        <div style={{width:'30%'}}>값</div>
                        <div style={{width:'10%'}}>등록</div>
                    </div>
                    <div style={{display:'flex',color:'#373737',fontSize:18,fontWeight:500,
                        height:55,borderBottom:'#E8E8E8 1px solid',alignItems:'center',justifyContent:'space-between'}}>
                        <input style={{width:'20%'}}
                                id="_groupid" 
                                type="text" 
                                placeholder="코드"
                                onChange={(e)=>{setCode(e.target.value);}}
                        ></input>
                        <input style={{width:'30%'}}
                                id="_groupname" 
                                type="text" 
                                placeholder="코드명"
                                onChange={(e)=>{setCodeName(e.target.value);}}
                        ></input>
                        <input style={{width:'10%'}}
                                id="_groupname" 
                                type="text" 
                                placeholder="순번"
                                onChange={(e)=>{setSubSeq(e.target.value);}}
                        ></input>
                        <input style={{width:'30%'}}
                                id="_groupname" 
                                type="text" 
                                placeholder="값"
                                onChange={(e)=>{setValue(e.target.value);}}
                        ></input>
                        <div style={{width:'10%'}}>
                            <button onClick={(e)=>{
                                    e.preventDefault();
                                    registerCommonData()
                                }
                            }>등록</button>
                        </div>
                    </div>
                    <div style={{display:'flex',borderBottom:'#E8E8E8 1px solid',color:'#9F9F9F',fontSize:16,
                            fontWeight:500,paddingBottom:10,width:'100%',marginTop:50}}>
                        <div style={{width:'20%'}}>코드</div>
                        <div style={{width:'30%'}}>코드명</div>
                        <div style={{width:'10%'}}>순번</div>
                        <div style={{width:'30%'}}>값</div>
                        <div style={{width:'10%'}}>삭제</div>
                    </div>
                    <ShowCommonCodeList />
                </div>
            </div>
        </div>
    )
}

export default AdminCommon;