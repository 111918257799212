import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, orderBy } from "firebase/firestore";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Swal from "sweetalert2";

import SelCalendar from '../images/renewal/Select_Calendar.svg';

function EvaReportTerm(props) {
    const navigate = useNavigate();
    const calendarRef = useRef(null);

    const [showCalendar, setShowCalendar] = useState(false);  //달력 보이기 기능
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
    const [selEmpdiv, setSelEmpdiv] = useState('Dentist');

    const [empData, setEmpData] = useState([]);

    const handleDateClick = (dates) => {
        if (dates.length === 2) {
            const [start, end] = dates;
            const oneMonthInMillis = 30 * 24 * 60 * 60 * 1000;
            if (end - start <= oneMonthInMillis) {
                setSelectedDates(dates);
                setShowCalendar(false);
            } else {
                showAlert("error", "허용 조회기간 초과", "기간은 최대 1개월까지만 선택할 수 있습니다.");
            }
        }
    };

    const handleButtonClick = (e) => {
        const rect = e.target.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const positionBelow = rect.bottom + window.scrollY;
        const positionAbove = rect.top + window.scrollY - 300; // 300px은 달력의 대략적인 높이입니다.
        const positionLeft = rect.left + window.scrollX;

        const calendarTop = rect.top + window.scrollY < windowHeight / 2 ? positionBelow : positionAbove;

        setCalendarPosition({ top: calendarTop, left: positionLeft });
        setShowCalendar(!showCalendar);
    };

    const handleClickOutside = (event) => {
        if (calendarRef.current && !calendarRef.current.contains(event.target)) {
            setShowCalendar(false);
        }
    };

    useEffect(() => {
        if (showCalendar) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showCalendar]);

    useEffect(() => {
        const startOfToday = new Date();
        startOfToday.setHours(0, 0, 0, 0);

        const endOfToday = new Date();
        endOfToday.setHours(23, 59, 59, 999);

        setSelectedDates([startOfToday, endOfToday]);
    }, []);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 월은 0부터 시작하므로 1을 더합니다.
        const day = date.getDate();
        return `${year}년 ${month}월 ${day}일`;
    };

    const getDisplayText = () => {
        if (selectedDates.length === 2) {
            const startDate = formatDate(selectedDates[0]);
            const endDate = formatDate(selectedDates[1]);
            return `${startDate} ~ ${endDate}`;
        }
        return '기간 설정';
    };

    const fetchDataCallback = useCallback(async () => {
        const fetchEmpData = async () => {
            const docRef = collection(db, "employees");
            const q = query(docRef, where("empdiv", "==", selEmpdiv), where("groupid", "==", props.userGroup), orderBy("empno"));
            const snapshot = await getDocs(q);
    
            return snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        };
    
        const empData = await fetchEmpData();
        setEmpData(empData);
    
        const fetchDiagData = async (empId) => {
            const docRef = collection(db, "diagtime");
            const q = query(docRef,
                where("empid", "==", empId),
                where("diagtimestart", ">=", selectedDates[0]),
                where("diagtimestart", "<=", selectedDates[1])
            );
            const snapshot = await getDocs(q);
    
            return snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        };
    
        const fetchSurveyData = async (empId) => {
            const docRef = collection(db, "surveydatas");
            const q = query(docRef,
                where("managerid", "==", empId),
                where("regdatetime", ">=", selectedDates[0]),
                where("regdatetime", "<=", selectedDates[1])
            );
            const snapshot = await getDocs(q);
    
            return snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        };
    
        const empDataWithStats = await Promise.all(empData.map(async (emp) => {
            const diagData = await fetchDiagData(emp.id);
            const tmpDiagTime = diagData.reduce((acc, cur) => acc + cur.diagelapsemin, 0);
    
            let totalDiagTime = 0;
            let avgDiagTime = 0;
    
            if (tmpDiagTime > 0) {
                totalDiagTime = tmpDiagTime;
                avgDiagTime = Math.round(tmpDiagTime / diagData.length);
            }
    
            const surveyData = await fetchSurveyData(emp.id);
    
            let pointTotal = 0;
            let pointAve = 0;
            let commentCnt = 0;
            let multipleCount = 0;
    
            surveyData.forEach(data => {
                if (data.quesdiv === "Multiple") {
                    multipleCount++;
                    switch (data.resultval) {
                        case 'vg':
                            pointTotal += 5;
                            break;
                        case 'g':
                            pointTotal += 4;
                            break;
                        case 'n':
                            pointTotal += 3;
                            break;
                        case 'b':
                            pointTotal += 2;
                            break;
                        case 'vb':
                            pointTotal += 1;
                            break;
                        default:
                            break;
                    }
                } else if (data.quesdiv === "Descriptive") {
                    commentCnt++;
                }
            });
    
            if (multipleCount > 0) {
                pointAve = pointTotal / multipleCount;
            }
    
            return {
                ...emp,
                totalDiagTime: totalDiagTime,
                avgDiagTime: avgDiagTime,
                diagDataLength: diagData.length,
                pointDataLength: multipleCount,
                pointTotal: pointTotal,
                pointAve: pointAve,
                commentCnt: commentCnt
            };
        }));
    
        setEmpData(empDataWithStats);
    }, [props.userGroup, selEmpdiv, selectedDates]);
    
    useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback]);

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva">
                        <div>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <div className="renew_content_title">리포트</div>
                                <div style={{display:'flex'}}>
                                    <div className="renew_report_button" style={{borderBottom:'4px solid #FFF',marginRight:"1rem"}} onClick={()=>navigate("/evaReportHome")}
                                    >개인별 종합</div>
                                    <div className="renew_report_button" 
                                        style={{fontWeight:600,color:'#191919',borderBottom:'4px solid #E5B88E'}}
                                    >기간별 진료 실적</div>
                                </div>
                            </div>
                            <div className="evareportterm_button_flex">
                                <div className="calendar_button"
                                    onClick={handleButtonClick}>
                                    <button style={{color:'#7962F6',fontSize:"1.125rem",fontWeight:600}}>{getDisplayText()}</button>
                                    <img src={SelCalendar} alt="달력" style={{width:"1rem",height:"1rem",marginLeft:5}} />
                                </div>
                                <div className="evareportterm_empsel_area">
                                    <div className="renew_question_empdiv"
                                        style={{color:selEmpdiv === 'Dentist' && '#191919',
                                            fontWeight:selEmpdiv === 'Dentist' && 600}}
                                        onClick={()=>setSelEmpdiv('Dentist')}
                                    >원장</div>
                                    <div style={{width:1,height:"0.9375rem",background:'#CECECE',margin:'0rem 0.5rem'}} />
                                    <div className="renew_question_empdiv"
                                        style={{ color:selEmpdiv === 'Staff' && '#191919',
                                            fontWeight:selEmpdiv === 'Staff' && 600}}
                                        onClick={()=>setSelEmpdiv('Staff')}
                                    >선생님</div>
                                </div>
                            </div>
                            {showCalendar && (
                                <div
                                    className="sliding_calendar show"
                                    style={{ 
                                        top: `${calendarPosition.top}px`, 
                                        left: `${calendarPosition.left}px`,
                                    }}
                                    ref={calendarRef}
                                >
                                    <Calendar
                                        onChange={handleDateClick}
                                        value={selectedDates}
                                        selectRange={true}
                                    />
                                </div>
                            )}
                            <div style={{overflow:'auto',maxWidth:"100%"}}>
                                <div className="evareportterm_inner_container_header">
                                    <div className="evareportterm_name">이름</div>
                                    <div className="evareportterm_diagdatalength">진료환자 수</div>
                                    <div className="evareportterm_totaldiagtime">누적진료시간(분)</div>
                                    <div className="evareportterm_avgdiagtime">평균진료시간(분)</div>
                                    <div className="evareportterm_pointdatalength">평점(건수)</div>
                                    <div className="evareportterm_pointtotal">평점(총점)</div>
                                    <div className="evareportterm_pointave">평점(평균)</div>
                                    <div className="evareportterm_commentcnt">코멘트 수</div>
                                </div>
                                {empData.map((data, index) => (
                                    <div key={index} className="evareportterm_inner_container_body">
                                        <div className="evareportterm_name">{data.name}</div>
                                        <div className="evareportterm_diagdatalength">{data.diagDataLength}</div>
                                        <div className="evareportterm_totaldiagtime">{data.totalDiagTime}</div>
                                        <div className="evareportterm_avgdiagtime">{data.avgDiagTime}</div>
                                        <div className="evareportterm_pointdatalength">{data.pointDataLength}</div>
                                        <div className="evareportterm_pointtotal">{data.pointTotal}</div>
                                        <div className="evareportterm_pointave">{data.pointAve}</div>
                                        <div className="evareportterm_commentcnt">{data.commentCnt}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EvaReportTerm;