import emailjs from "@emailjs/browser";

let emailSent = false; // 전역 변수 또는 컴포넌트 내부에서 상태로 사용

function Email(props){
  if (emailSent) return; // 이미 이메일이 전송되었다면, 함수 종료

  emailSent = true; // 이메일 전송 플래그 설정

  const templateParams = {
    to_email: props.userEmail,
    to_name: props.userName,
  }

  emailjs
    .send("service_nso4yud",props.templateKey,templateParams,{
      publicKey: "bLxHHB8tejV0m0DBn",
    })
    .then(
      (result) => {
        console.log(result.text);
        // 성공 메시지 처리
      },
      (error) => {
        console.log(error.text);
        // 에러 메시지 처리
      }
    );
}

export default Email;
