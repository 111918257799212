import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// 개발
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*
const firebaseConfig = {
  apiKey: "AIzaSyBnPKf4b5-3Y-AyXR4-zLExPwTN15ahi6c",
  authDomain: "deploytest-af602.firebaseapp.com",
  projectId: "deploytest-af602",
  storageBucket: "deploytest-af602.appspot.com",
  messagingSenderId: "912721417655",
  appId: "1:912721417655:web:97ccb220ecd1cf95d68432",
  measurementId: "G-V0T2LN6N72"
};
*/
// 운영

const firebaseConfig = {
  apiKey: "AIzaSyCvVSNW5QISdB6IPoPKOL88iiXylzU6K-4",
  authDomain: "bddental-f90a8.firebaseapp.com",
  projectId: "bddental-f90a8",
  storageBucket: "bddental-f90a8.appspot.com",
  messagingSenderId: "517727924863",
  appId: "1:517727924863:web:0948ef3fb2f819046c854a",
  measurementId: "G-3217R9Q43Q"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);