import './App.css';
import './css/style.css'
import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { query, collection, doc, getDoc, getDocs, where, orderBy, updateDoc } from "firebase/firestore"
import { db, auth } from './firebase_config.js';
import Swal from "sweetalert2";
import Email from './Email.js';

import Login from './pages/Login.js';
import FindPW from './pages/FindPW.js';
import FindID from './pages/FindID.js';
import FindIDResult from './pages/FindIDResult.js';
import TermsAgree from './pages/TermsAgree.js';
import TermsPersonalInfo from './pages/TermsPersonalInfo.js';
import TermsEventInfo from './pages/TermsEventInfo.js';
import TermsUse from './pages/TermsUse.js';
import TermsPersonalProcess from './pages/TermsPersonalProcess.js';
import Signup from './pages/Signup.js';
import ContentList from './pages/ContentList.js';
import ContentUpload from './pages/ContentUpload.js';
import PrivateRoute from './PrivateRoute.js';
import DrawPage from './pages/DrawPage.js';
import CategoryMod from './pages/CategoryMod.js';
import ViewVideo from './pages/ViewVideo.js';

import EvaHome from './evapages/EvaHome.js';
import EvaSignup from './evapages/EvaSignup.js';
import EvaManageFront from './evapages/EvaManageFront.js';
import EvaManageBack from './evapages/EvaManageBack.js';
import EvaEmployeeManage from './evapages/EvaEmployeeManage.js';
import EvaEmpMngReg from './evapages/EvaEmpMngReg.js';
import EvaQuestionManage from './evapages/EvaQuestionManage.js';
import EvaQueMngReg from './evapages/EvaQueMngReg.js';
import EvaSurvey from './evapages/EvaSurvey.js';
import EvaSurveyFront from './evapages/EvaSurveyFront.js';
import EvaSurveyPublicFront from './evapages/EvaSurveyPublicFront.js';
import EvaSurveyPublicBack from './evapages/EvaSurveyPublicBack.js';
import EvaReportHome from './evapages/EvaReportHome.js';
import EvaReportShow from './evapages/EvaReportShow.js';
import EvaReportTerm from './evapages/EvaReportTerm.js';

import CaseCategoryMod from './casepages/CaseCategoryMod.js';

import CrmMain from './crmpages/CrmMain.js';
import CrmContact from './crmpages/CrmContact.js';
import CrmNotiCenter from './crmpages/CrmNotiCenter.js';

import AdminHome from './adminpages/AdminHome.js';
import EvaReportAdmin from './adminpages/EvaReportAdmin.js';
import EvaEmpMngAdm from './adminpages/EvaEmpMngAdm.js';
import AdminMemAprv from './adminpages/AdminMemAprv.js';
import AdminCommon from './adminpages/AdminCommon.js';
import AdminDataMod from './adminpages/AdminDataMod.js'
import AdminMemberlist from './adminpages/AdminMemberlist.js';

import Notice from './pages/Notice.js';
import NoticeWrite from './pages/NoticeWrite.js';
import NoticeRead from './pages/NoticeRead.js';

import EasyUpload from './pages/EasyUpload.js';
import PatientContentList from './pages/PatientContentList.js';

import Logo from './images/renewal/Logo.svg';
import LogoMobile from './images/renewal/MobileLogo.svg';
import Subtract from './images/renewal/Subtract.svg';
import Menu from './images/renewal/Menu.svg';
import Floating from './images/renewal/Floating.svg';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { cateval, repcateval, sysdiv } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [userNameData, setUserNameData] = useState(); //다른페이지에 사용자 정보 넘기는 변수
  const [userAuth, setUserAuth] = useState(); //사용자 권한그룹 값 변수
  const [userGroup, setUserGroup] = useState(); //사용자 병원그룹 값 변수
  const [userGroupName, setUserGroupName] = useState(); //사용자 병원그룹 이름 값 변수
  const [showMenu, setShowMenu] = useState(false);  //메뉴 슬라이딩 기능

  // 리뉴얼 추가 변수
  const [selectedMenu, setSelectedMenu] = useState(() => {
    // localStorage에서 저장된 메뉴를 불러옵니다. 없으면 'TopMenu1'을 기본값으로 사용합니다.
    return localStorage.getItem('selectedMenu') || 'TopMenu1';
  });
  const [selMenuName, setSelMenuName] = useState(() => {
    // localStorage에서 저장된 메뉴를 불러옵니다. 없으면 'DENTAL EXPLANATION SYSTEM'을 기본값으로 사용합니다.
    return localStorage.getItem('selMenuName') || 'DENTAL EXPLANATION SYSTEM';
  });
  const [selTopEtcMenu, setSelTopEtcMenu] = useState(false);  //상단 기타 메뉴
  // const [selSideMenu, setSelSideMenu] = useState(0); //사이드 선택 메뉴
  const [sideMenu, setSideMenu] = useState([]); //사이드 메뉴
  const [selSideMenu, setSelSideMenu] = useState(() => {
    const savedMenu = localStorage.getItem('selSideMenu');
    return savedMenu ? parseInt(savedMenu, 10) : 0;
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [cateVal, setCateVal] = useState(() => {
    // localStorage에서 저장된 메뉴를 불러옵니다. 없으면 '임플란트'을 기본값으로 사용합니다.
    return cateval || localStorage.getItem('cateVal') || '임플란트';
  });
  const [repcateVal, setRepCateVal] = useState(() => {
    // localStorage에서 저장된 메뉴를 불러옵니다. 없으면 '임플란트'을 기본값으로 사용합니다.
    return repcateval || localStorage.getItem('repcateVal') || '임플란트';
  });
  const [sysDiv, setSysDiv] = useState(() => {
    // sysdiv가 있으면 사용하고, 없으면 localStorage에서 가져오거나 'DC' 사용
    return sysdiv || localStorage.getItem('sysDiv') || 'DC';
  });

  const [isFirstLoad, setIsFirstLoad] = useState(() => {
    return sessionStorage.getItem('isFirstLoad') !== 'false';
  });
  const isFirstLoadRef = useRef(isFirstLoad);

  const isHeaderMenuShow = location.pathname === '/login' || location.pathname === "/termsuse" || 
                            location.pathname === "/termspersonalprocess" || location.pathname === "/termsagree" ||
                            location.pathname === "/signup" || location.pathname === "/termspersonal" ||
                            location.pathname === "/termsevent" || location.pathname === "/evaSurveyPublicFront" ||
                            location.pathname === "/evaSurveyPublicBack" || location.pathname === "/findpw" || 
                            location.pathname === "/findid" || location.pathname === "/findidresult" ||
                            location.pathname === "/drawPage" || location.pathname === "/viewVideo" || 
                            location.pathname === "/patientContentList";

  const isSideMenuShow = selectedMenu === "TopMenu2" || selectedMenu === "TopMenu5" || 
                            location.pathname === '/login' || location.pathname === "/termsuse" || 
                            location.pathname === "/termspersonalprocess" || location.pathname === "/termsagree" ||
                            location.pathname === "/signup" || location.pathname === "/drawPage" ||
                            location.pathname === "/viewVideo" || location.pathname === "/notice" ||
                            location.pathname === "/noticeRead" || location.pathname === "/noticeWrite" ||
                            location.pathname === "/termspersonal" || location.pathname === "/termsevent" ||
                            location.pathname === "/categoryMod" || location.pathname === "/evaSurveyPublicFront" ||
                            location.pathname === "/evaSurveyPublicBack" || location.pathname === "/findpw" ||
                            location.pathname === "/findid" || location.pathname === "/findidresult" ||
                            location.pathname === "/patientContentList";

  const isLoginNeed = location.pathname !== "/termsuse" && location.pathname !== "/termspersonalprocess" &&
                      location.pathname !== "/termsagree" && location.pathname !== "/signup" &&
                      location.pathname !== "/termspersonal" && location.pathname !== "/termsevent" && location.pathname !== "/evaSurveyPublicFront" &&
                      location.pathname !== "/evaSurveyPublicBack" && location.pathname !== "/findpw" && location.pathname !== "/findid" &&
                      location.pathname !== "/findidresult" && location.pathname !== "/login";

  useEffect(() => {
    // selectedMenu가 변경될 때마다 localStorage에 저장합니다.
    localStorage.setItem('selectedMenu', selectedMenu);
    localStorage.setItem('selMenuName', selMenuName);
    localStorage.setItem('cateVal', cateVal);
    localStorage.setItem('repcateVal', repcateVal);
    localStorage.setItem('sysDiv', sysDiv);
    localStorage.setItem('selSideMenu', selSideMenu);
  }, [selectedMenu, selMenuName, cateVal, repcateVal, sysDiv, selSideMenu]);

  useEffect(() => {
    let emailVerifiedFlag = false;
    
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        const docRef = doc(db, "users", authUser.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setUserNameData(docSnap.data().userName);
          setUserAuth(docSnap.data().auth);
          setUserGroup(docSnap.data().groupid);

          const gDocRef = doc(db, "group", docSnap.data().groupid);
          const gDocSnap = await getDoc(gDocRef);

          if (gDocSnap.exists()) {
            setUserGroupName(gDocSnap.data().groupname);
          } else {
            console.log("group 테이블 데이터 로드 실패");
          }

          // 이메일 인증 상태 확인 및 업데이트
          if (!emailVerifiedFlag && docSnap.data().emailverified === "N" && authUser.emailVerified) {
            emailVerifiedFlag = true; // 플래그 설정
            // 이메일 인증 상태 업데이트 및 Email 함수 호출
            const userRef = doc(db, "users", authUser.email);
            await updateDoc(userRef, {
                emailverified: "Y",
            });

            const sendEmailForm = {
              templateKey: "template_cugdufd",
              userEmail: authUser.email,
              userName: docSnap.data().userName,
            }
            Email(sendEmailForm);
          }

          if (!isFirstLoadRef.current) {
            // 새로고침 또는 이후 로드 시
            if (selectedMenu === "TopMenu1") {
              const q = query(collection(db, "category"), where("sysdiv", "==", "DC"), orderBy("value"));
              const snapshot = await getDocs(q);
              const data = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
              setSideMenu(data);
            } else if (selectedMenu === "TopMenu3") {
              const evaMenuItems = [
                {id:'evaSignup', cateval:'환자 등록', sysdiv:'EVA'},
                {id:'evaManageFront', cateval:'담당자 연결', sysdiv:'EVA'},
                {id:'evaSurveyFront', cateval:'고객만족도 조사', sysdiv:'EVA'},
                {id:'evaQuestionMng', cateval:'문항 관리', sysdiv:'EVA'},
                {id:'evaEmployeeMng', cateval:'직원 관리', sysdiv:'EVA'},
                {id:'evaReport', cateval:'리포트', sysdiv:'EVA'}
              ];
              setSideMenu(evaMenuItems);
            } else if (selectedMenu === "TopMenu4" && docSnap.data().groupid !== undefined) {
              const q = query(collection(db, "category"), where("sysdiv", "==", "CA"), 
                        where("groupid", "==", docSnap.data().groupid), orderBy("value"));
              const snapshot = await getDocs(q);
              const data = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
              if (data.length > 0) {
                setSideMenu(data);
              }
            } 
          } else {
            // 최초 로드 시
            const q = query(collection(db, "category"), where("sysdiv", "==", "DC"), orderBy("value"));
            const snapshot = await getDocs(q);
            const data = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            
            setSideMenu(data);
            setSelectedMenu("TopMenu1");
            setSelSideMenu(0);
            setSelMenuName("DENTAL EXPLANATION SYSTEM");
            setCateVal(data[0].cateval);
            setRepCateVal(data[0].repcateval);
            setSysDiv("DC");
            navigate("/");
            
            isFirstLoadRef.current = false;
            sessionStorage.setItem('isFirstLoad', 'false');
          }
        } else {
          console.log("users 테이블 데이터 로드 실패");
        }
      } else if (isLoginNeed) {
        navigate("/login");
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [navigate, isLoginNeed, selectedMenu]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //새로고침 시 대기화면
  if (loading) {
    return <div />;
  }

  const handleMenuClick = async (menu) => {
    isFirstLoadRef.current = false;
    sessionStorage.setItem('isFirstLoad', 'false');
    setSelTopEtcMenu(false);
    setSelectedMenu(menu);
    if(menu === 'TopMenu1'){
      const q = query(collection(db, "category"), where("sysdiv", "==", "DC"), 
                      orderBy("value"));
      const snapshot = await getDocs(q);
  
      const data = snapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
      }));
      
      setSideMenu(data);
      setSelSideMenu(0);
      setSelMenuName("DENTAL EXPLANATION SYSTEM");
      toDentalExplanPage(data);
    }else if(menu === 'TopMenu2'){
      setSelSideMenu(0);
      setSelMenuName("비용안내");
      toCostInfoPage();
    }else if(menu === 'TopMenu3'){
      const evaSignup = {id:'evaSignup',cateval:'환자 등록',sysdiv:'EVA'};
      const evaManageFront = {id:'evaManageFront',cateval:'담당자 연결',sysdiv:'EVA'};
      const evaSurveyFront = {id:'evaSurveyFront',cateval:'고객만족도 조사',sysdiv:'EVA'};
      const evaQuestionMng = {id:'evaQuestionMng',cateval:'문항 관리',sysdiv:'EVA'};
      const evaEmployeeMng = {id:'evaEmployeeMng',cateval:'직원 관리',sysdiv:'EVA'};
      const evaReport = {id:'evaReport',cateval:'리포트',sysdiv:'EVA'};
      setSideMenu([evaSignup, evaManageFront, evaSurveyFront, evaQuestionMng,
        evaEmployeeMng, evaReport]);
      
      setSelSideMenu(0);
      setSelMenuName("고객만족도 관리시스템");
      setSysDiv("EVA");
      navigate("/evaSignup");
    }else if(menu === 'TopMenu4'){
      setSelMenuName("DENTAL CASE PRESENTATION");
      toCasePreMainPage();
      setSelSideMenu(0);
    }else if(menu === 'TopMenu5'){
      setSelMenuName("CRM");
      setSelSideMenu(0);
      toCrmMainPage();
    }
  };

  const handleTopEtcMenuClick = () => {
    setSelTopEtcMenu(!selTopEtcMenu);
  };

  // 상단 기타메뉴 클릭 이벤트 핸들러 (배경을 클릭하여 메뉴를 닫는 기능)
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      setSelTopEtcMenu(false); // 배경을 클릭한 경우에만 메뉴를 닫습니다.
    }
  };

  const handleSideMenuClick = (menu, category) => {
    setSelSideMenu(menu);
    if(category.sysdiv === "DC" || category.sysdiv === "CA"){
      setCateVal(category.cateval);
      setRepCateVal(category.repcateval);
      setSysDiv(category.sysdiv);
      navigate("/");
    }else if(category.sysdiv === "EVA"){
      if(menu === 0){
        navigate("/evaSignup");
      }else if(menu === 1){
        navigate("/evaManageFront", { state: { usergroup : userGroup } });
      }else if(menu === 2){
        navigate("/evaSurveyFront",{
          state: {
              code: "Eva",
          },
        });
      }else if(menu === 3){
        navigate("/evaQuestionManage");
      }else if(menu === 4){
        navigate("/evaEmployeeManage");
      }else if(menu === 5){
        navigate("/evaReportHome");
      }
    }else if(category.sysdiv === "ADMIN"){
      if(menu === 0){
        navigate("/adminMemberlist");
      }else if(menu === 1){
        navigate("/adminMemAprv");
      }else if(menu === 2){
        navigate("/adminCommon");
      }else if(menu === 3){
        navigate("/evaReportAdmin");
      }else if(menu === 4){
        navigate("/evaEmpMngAdm");
      }else if(menu === 5){
        navigate("/adminDataMod");
      }
    }
  };
  
  const Header = () => {
    // /login 페이지와 기타 로그인이 필요없는 페이지인 경우 메뉴를 비활성화
    if (isHeaderMenuShow) {
      return null;
    }
  
    return (
      <div className="renew_background header">
        <div className="content_align header">
          <div style={{display:'flex'}}>
            <div className="header_logo_area">
              <img src={Logo} alt="로고" style={{width:"7.05rem",height:"2.625rem",cursor:'pointer'}} onClick={() => handleMenuClick('TopMenu1')} />
              <div style={{width:1,height:"1.5rem",background:'#E1E1E1',marginLeft:13,marginRight:13}} />
              {auth && auth.currentUser ? (
                auth.currentUser.emailVerified ? (
                  <div className="group_name">{userGroupName}</div>
                ) : (
                  <div className="group_name">이메일 인증 후 정상이용</div>
                )
              ) : (
                <div className="group_name">{userGroupName}</div>
              )}
            </div>
            <div className="header_logo_mobile_area">
              <img src={LogoMobile} alt="모바일로고" style={{width:"1.9rem",height:"2.326rem"}} />
            </div>
            <div className="header_menu">
              <div className="renew_menutop"
                  style={{ color:selectedMenu === 'TopMenu1' && '#191919',
                            fontWeight:selectedMenu === 'TopMenu1' && 700}}
                  onClick={() => handleMenuClick('TopMenu1')}
              >DENTAL EXPLANATION SYSTEM</div>
              {(userAuth === 7 || userAuth === 1 || userAuth === 2) && (
                <>
                  <div className="renew_menutop"
                      style={{ color:selectedMenu === 'TopMenu2' && '#020202',
                                fontWeight:selectedMenu === 'TopMenu2' && 700}}
                      onClick={() => handleMenuClick('TopMenu2')}
                  >비용안내</div>
                  <div className="renew_menutop"
                      style={{ color:selectedMenu === 'TopMenu3' && '#020202',
                                fontWeight:selectedMenu === 'TopMenu3' && 700}}
                      onClick={() => handleMenuClick('TopMenu3')}
                  >고객만족도 관리시스템</div>
                  <div className="renew_menutop"
                      style={{ color:selectedMenu === 'TopMenu4' && '#020202',
                                fontWeight:selectedMenu === 'TopMenu4' && 700}}
                      onClick={() => handleMenuClick('TopMenu4')}
                  >DENTAL CASE PRESENTATION</div>
                  <div className="renew_menutop"
                      style={{ color:selectedMenu === 'TopMenu5' && '#020202',
                                fontWeight:selectedMenu === 'TopMenu5' && 700}}
                      onClick={() => handleMenuClick('TopMenu5')}
                  >CRM</div>
                </>
              )}
            </div>
          </div>
          <div className="selmenu_name" style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}>{selMenuName}</div>
          <img src={Menu} alt='메뉴' style={{width:"1.75rem",height:"1.75rem",cursor:'pointer'}} onClick={handleTopEtcMenuClick}/>
          {selTopEtcMenu && (
            <>
              <div className="renew_overlay" onClick={()=>{setSelTopEtcMenu(!selTopEtcMenu)}}/>
              <div className="menu_top_side" onClick={handleBackgroundClick} // 배경 클릭 이벤트 핸들러 추가
              >
                <div className="menu_top_sidelist">
                  <div style={{overflowY:'auto'}}>
                    <div className="menu_top_side_notdesktop"
                      onClick={() => handleMenuClick('TopMenu1')}
                    >DENTAL EXPLANATION SYSTEM</div>
                    <div className="menu_top_side_notdesktop"
                      onClick={() => handleMenuClick('TopMenu2')}
                    >비용안내</div>
                    <div className="menu_top_side_notdesktop"
                      onClick={() => handleMenuClick('TopMenu3')}
                    >고객만족도 관리시스템</div>
                    <div className="menu_top_side_notdesktop"
                      onClick={() => handleMenuClick('TopMenu4')}
                    >DENTAL CASE PRESENTATION</div>
                    <div className="menu_top_side_notdesktop"
                      onClick={() => handleMenuClick('TopMenu5')}
                    >CRM</div>
                    <div style={{marginTop:"2.0625rem",cursor:'pointer'}}
                        onClick={()=>{
                          setSelTopEtcMenu(false);
                          setSelectedMenu("");
                          setSelSideMenu(0);
                          setSelMenuName("공지사항");
                          toNoticePage("공지사항","announce");
                          
                        }}
                    >공지사항</div>
                    <div style={{marginTop:"2.0625rem",cursor:'pointer'}}
                        onClick={()=>{
                          setSelTopEtcMenu(false);
                          setSelectedMenu("");
                          setSelSideMenu(0);
                          setSelMenuName("Q&A");
                          toNoticePage("Q&A","qna");
                        }}
                    >Q&A</div>
                    <div style={{marginTop:"2.0625rem",cursor:'pointer'}}
                        onClick={()=>{
                          setSelTopEtcMenu(false);
                          setSelectedMenu("");
                          setSelSideMenu(0);
                          setSelMenuName("버그신고");
                          toNoticePage("버그신고","bug");
                        }}
                    >버그신고</div>
                    {(userAuth === 7 || userAuth === 1) && (
                      <>
                        {/* <div style={{marginTop:33,cursor:'pointer'}}
                            onClick={()=>{
                              setSelTopEtcMenu(false);
                              setSelectedMenu("");
                              setSelSideMenu(0);
                              toCateModPage();
                              }}
                        >카테고리 관리</div> */}
                        <div style={{marginTop:"2.0625rem",cursor:'pointer'}}
                            onClick={()=>{
                              const adminMemberlist = {id:'adminMemberlist',cateval:'승인가입자정보',sysdiv:'ADMIN'};
                              const adminMemAprv = {id:'adminMemAprv',cateval:'신규회원승인',sysdiv:'ADMIN'};
                              const adminCommon = {id:'adminCommon',cateval:'공통코드관리',sysdiv:'ADMIN'};
                              const evaReportAdmin = {id:'evaReportAdmin',cateval:'리포트관리자',sysdiv:'ADMIN'};
                              const evaEmpMngAdm = {id:'evaEmpMngAdm',cateval:'직원관리자',sysdiv:'ADMIN'};
                              const adminDataMod = {id:'adminDataMod',cateval:'데이터수정',sysdiv:'ADMIN'};
                              setSideMenu([adminMemberlist, adminMemAprv, adminCommon, evaReportAdmin,
                                evaEmpMngAdm, adminDataMod]);
                                
                              setSelTopEtcMenu(false);
                              setSelectedMenu("");
                              setSelSideMenu(0);
                              toAdminHomePage();
                            }}
                        >관리자</div>
                      </>
                    )}
                  </div>
                  <div style={{marginTop:"2.0625rem"}}>
                    <div style={{height:1,background:'rgba(255,255,255,0.41)',marginBottom:"1.25rem"}} />
                    <div style={{textAlign:'center',fontWeight:600,cursor:'pointer'}}
                        onClick={logoutRun}
                    >로그아웃</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const sideMenuClasses = classNames('side_menu', {
    'eva': sysDiv === 'EVA'
  });

  const SideMenu = () => {
    // /login 페이지와 기타 로그인이 필요없는 페이지인 경우 메뉴를 비활성화
    if (isSideMenuShow) {
      return null;
    }else if(selectedMenu === "TopMenu2" || selectedMenu === "TopMenu5" ){
      return(
        <div className="renew_background" style={{width:"3rem"}} />
      )
    }
    return(
      <div className="side_menu_wrap_tablet">
        <div className={sideMenuClasses}>
          <div className="side_menu_list">
            {sideMenu
              .filter(option => option.repcateval !== '기본카테고리' && option.repcateval !== '비용안내')
              .map((option, index) => (
                <div key={option.id} className="renew_menuside"
                    style={{
                      background: selSideMenu === index && '#000',
                      color: selSideMenu === index && '#FFF',
                      // paddingLeft: selSideMenu === index && 23,
                    }}
                    onClick={() => handleSideMenuClick(index, option)}
                >{option.cateval}</div>
            ))}
            {sysDiv === "CA" && 
              <div className="renew_menuside" 
                  style={{display:'flex',alignItems:'center'}}
                  onClick={()=>{
                    setSelTopEtcMenu(false);
                    setSelectedMenu("");
                    setSelSideMenu(0);
                    toCateModPage();
              }}>
                <div>카테고리 편집</div>
                <img src={Subtract} alt='수정' style={{width:16,height:16,marginLeft:6}} />
                </div>
            }
          </div>
        </div>
      </div>
    );
  };

  const logoutRun = () => {
    signOut(auth);
    navigate("/") //페이지 이동
    window.location.reload(false);  //페이지 refresh
  };

  const toCateModPage = () => {
    if(userAuth === 1 || userAuth === 7 || (userAuth === 2 && sysDiv === "CA")){
      navigate("/categoryMod");
    }else{
      noAuthAlert();
    }
  }

  const toDentalExplanPage = (props) => {
    setCateVal(props[0].cateval);
    setRepCateVal(props[0].repcateval);
    setSysDiv("DC");
    navigate("/");
  }

  const toCostInfoPage = () => {
    setCateVal("비용안내");
    setRepCateVal("비용안내");
    setSysDiv("DC");
    navigate("/");
  }

  const toCasePreMainPage = async () => {
    const q = query(collection(db, "category"), where("sysdiv", "==", "CA"), 
                    where("groupid", "==", userGroup), orderBy("value"));

    const snapshot = await getDocs(q);
    const data = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }));

    setSysDiv("CA");
    if(data.length > 0){
      setSideMenu(data);
      setShowMenu(!showMenu)
      
      setCateVal(data[0].cateval);
      setRepCateVal(data[0].repcateval);
      navigate("/");
    }else{
      toCateModPage();
    }
  } 

  const toCrmMainPage = () => {
    setShowMenu(!showMenu)
    
    navigate("/crmNotiCenter");
  } 

  const toAdminHomePage = () => {
    setShowMenu(!showMenu)
    
    navigate("/adminMemberlist");
  } 

  const toNoticePage = (id, code) => {
    setShowMenu(!showMenu)
    
    navigate("/notice", {
      state: {
        id: id,
        code: code
      },
    });
  } 

  const noAuthAlert = () => {
    Swal.fire({
      icon: "warning",
      title: "권한 없음",
      text: "해당 메뉴의 권한이 없습니다",
      //showCancelButton: true,
      confirmButtonText: "확인",
      //cancelButtonText: "취소",
    })
  }

  const handleCategoryUpdate = (newCategory) => {
    setCateVal(newCategory.cateval);
    setRepCateVal(newCategory.repcateval);
    setSysDiv(newCategory.sysdiv);
  };

  const FloatingIcon = () => {
    // 현재 경로가 "/easyUpload"이면 아이콘을 렌더링하지 않습니다
    if (location.pathname === "/easyUpload" || !isLoginNeed) {
      return null;
    }
  
    const handleClick = () => {
      navigate("/easyUpload", { state: { autoClickFileUpload: true } });
    };
  
    return (
      <div className="floating_icon" onClick={handleClick}>
        <img src={Floating} alt='floating icon' />
      </div>
    );
  };

  return (
    <>
      <Header />
      <div className="contents_layout" style={{paddingTop:isHeaderMenuShow ? 0 : "5rem"}}>
        <SideMenu />
        <div style={{flex:1,
                     paddingLeft:windowWidth > 1400 ? (isSideMenuShow ? 0 : "19.625rem") : 0,
                    //  paddingTop:windowWidth > 1400 ? 0 : (isSideMenuShow ? 0 : 42),
                     zIndex:1}}>
          <Routes>
            {/* <Route exact path="/" element={ <Home sysDiv={sysDiv}></Home> }></Route> */}
            <Route exact path="/" 
                   element={ 
                    <ContentList userAuth={userAuth} userGroup={userGroup} 
                                sysDiv={sysDiv}
                                cateVal={cateVal} 
                                repcateVal={repcateVal}
                                onCategoryUpdate={handleCategoryUpdate}
                    ></ContentList> 
                   }
            ></Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/login" element={ <Login></Login> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/findpw" element={ <FindPW></FindPW> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/findid" element={ <FindID></FindID> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/findidresult" element={ <FindIDResult></FindIDResult> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/termsagree" element={ <TermsAgree></TermsAgree> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/termspersonal" element={ <TermsPersonalInfo></TermsPersonalInfo> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/termsevent" element={ <TermsEventInfo></TermsEventInfo> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/termsuse" element={ <TermsUse></TermsUse> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/termspersonalprocess" element={ <TermsPersonalProcess></TermsPersonalProcess> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/signup" element={ <Signup></Signup> }></Route>
            </Route>

            {/* <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/contentlist" element={ <ContentList userAuth={userAuth} userGroup={userGroup} sysDiv={sysDiv}></ContentList> }></Route>
            </Route> */}
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/contentUpload" element={ <ContentUpload userNameData={userNameData} userAuth={userAuth} userGroup={userGroup} sysDiv={sysDiv}></ContentUpload> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/drawPage" element={ <DrawPage userAuth={userAuth} userGroup={userGroup} sysDiv={sysDiv}></DrawPage> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/categoryMod" element={ <CategoryMod userNameData={userNameData} userAuth={userAuth} userGroup={userGroup} sysDiv={sysDiv} toCasePreMainPage={toCasePreMainPage}></CategoryMod> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/viewVideo" element={ <ViewVideo userAuth={userAuth} userGroup={userGroup} sysDiv={sysDiv}></ViewVideo> }></Route>
            </Route>

            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaHome" element={ <EvaHome userAuth={userAuth}></EvaHome> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaSignup" element={ <EvaSignup userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaSignup> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaManageFront" element={ <EvaManageFront userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaManageFront> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaManageBack" element={ <EvaManageBack userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaManageBack> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaEmployeeManage" element={ <EvaEmployeeManage userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaEmployeeManage> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaEmpMngReg" element={ <EvaEmpMngReg userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaEmpMngReg> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaQuestionManage" element={ <EvaQuestionManage userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaQuestionManage> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaQueMngReg" element={ <EvaQueMngReg userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaQueMngReg> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaSurveyFront" element={ <EvaSurveyFront userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaSurveyFront> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaSurvey" element={ <EvaSurvey userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaSurvey> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/evaSurveyPublicFront" element={ <EvaSurveyPublicFront></EvaSurveyPublicFront> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="false"></PrivateRoute>}>
              <Route exact path="/evaSurveyPublicBack" element={ <EvaSurveyPublicBack></EvaSurveyPublicBack> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaReportHome" element={ <EvaReportHome userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaReportHome> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaReportShow" element={ <EvaReportShow userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaReportShow> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaReportTerm" element={ <EvaReportTerm userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaReportTerm> }></Route>
            </Route>

            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/caseCategoryMod" element={ <CaseCategoryMod userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></CaseCategoryMod> }></Route>
            </Route>

            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/crmMain" element={ <CrmMain sysDiv={sysDiv} userGroup={userGroup}></CrmMain> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/crmContact" element={ <CrmContact sysDiv={sysDiv} userGroup={userGroup}></CrmContact> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/crmNotiCenter" element={ <CrmNotiCenter sysDiv={sysDiv} userGroup={userGroup}></CrmNotiCenter> }></Route>
            </Route>

            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/adminHome" element={ <AdminHome sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup}></AdminHome> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/adminMemAprv" element={ <AdminMemAprv sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup}></AdminMemAprv> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaReportAdmin" element={ <EvaReportAdmin userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaReportAdmin> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/evaEmpMngAdm" element={ <EvaEmpMngAdm userNameData={userNameData} userAuth={userAuth} userGroup={userGroup}></EvaEmpMngAdm> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/adminCommon" element={ <AdminCommon sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup}></AdminCommon> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/adminDataMod" element={ <AdminDataMod sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup}></AdminDataMod> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/adminMemberlist" element={ <AdminMemberlist sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup}></AdminMemberlist> }></Route>
            </Route>

            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/notice" element={ <Notice sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup}></Notice> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/noticeWrite" element={ <NoticeWrite sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup}></NoticeWrite> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/noticeRead" element={ <NoticeRead sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup}></NoticeRead> }></Route>
            </Route>

            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/easyUpload" element={ <EasyUpload sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup} userNameData={userNameData}></EasyUpload> }></Route>
            </Route>
            <Route element={<PrivateRoute authentication="true"></PrivateRoute>}>
              <Route exact path="/patientContentList" element={ <PatientContentList sysDiv={sysDiv} userAuth={userAuth} userGroup={userGroup} userNameData={userNameData}></PatientContentList> }></Route>
            </Route>
          </Routes>
        </div>
        <FloatingIcon />
      </div>
    </>
  );
}
export default App;
