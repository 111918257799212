import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc, orderBy, query, where } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config';
import Swal from "sweetalert2";

import IconUpward from '../images/renewal/Icon_upward.svg';
import IconDownward from '../images/renewal/Icon_downward.svg';
import More from '../images/renewal/More.svg';


function EvaQuestionManage(props) {
    const navigate = useNavigate();

    const [selEmpdiv, setSelEmpdiv] = useState('Dentist');
    const [quesData, setQuesData] = useState([]);

    // 클릭한 div의 인덱스를 추적하는 상태 변수
    const [selectedDivIndex, setSelectedDivIndex] = useState(null);

    const [activeMenu, setActiveMenu] = useState(null)
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

    const toEvaQueMngReg = (props) => {
        navigate("/evaQueMngReg", {
            state: {
                data: props,
            },
        });
    }

    const fetchDataCallback = useCallback(async () => {
        async function renderQuestions() {
            const docRef = collection(db, "questionnaire");
            const q = query(docRef, where("groupid", "==", props.userGroup),
                                        orderBy("quesno")
                        );
            const snapshot = await getDocs(q);
            return snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        }

        const qData = await renderQuestions();
    
        setQuesData(qData);
    }, [props.userGroup]);

    useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback]);

    const handleMenuClick = (menu) => {
        setSelEmpdiv(menu);
    };
    
    const swapIndex = async (direction) => {
        const filteredData = quesData.filter(data => selEmpdiv === data.empdiv);
        
        // 선택한 div의 인덱스가 유효한지 확인
        if (selectedDivIndex === null) {
            showAlert("error", "문항 미선택", "문항을 클릭하여 선택하시기 바랍니다.");
            return;
        }
    
        const currentIndex = selectedDivIndex;
        const nextIndex = direction === "Up" ? currentIndex - 1 : currentIndex + 1;
    
        // 다음 인덱스가 유효한지 확인
        if (nextIndex < 0 || nextIndex >= filteredData.length) {
            return;
        }
    
        const currentDoc = doc(db, "questionnaire", filteredData[currentIndex].id);
        const nextDoc = doc(db, "questionnaire", filteredData[nextIndex].id);
    
        const currentQuesno = filteredData[currentIndex].quesno;
        const nextQuesno = filteredData[nextIndex].quesno;
    
        try {
            // 업데이트할 문항의 quesno를 서로 교환
            await Promise.all([
                updateDoc(currentDoc, { quesno: nextQuesno }),
                updateDoc(nextDoc, { quesno: currentQuesno })
            ]);
            // swapIndex 이후 selectedDivIndex 값 변경
            setSelectedDivIndex(nextIndex);

            // swapIndex 이후 quesData 상태 업데이트
            fetchDataCallback();
        } catch (error) {
            console.error("에러 메시지:", error.message);
        }
    }

    const handleDeleteButtonClick = (id, quesno) => {
        Swal.fire({
            icon: "question",
            title: "문항 삭제",
            text: "문항 삭제를 하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "삭제",
            cancelButtonText: "취소",
        }).then(async (res) => {
            if (res.isConfirmed) {
                try {
                    // questionnaire 테이블에서 해당 데이터 삭제
                    await deleteDoc(doc(db, "questionnaire", id));
            
                    // 삭제된 데이터의 quesno보다 큰 quesno 값을 가진 데이터들의 quesno를 1씩 차감하여 업데이트
                    const filteredData = quesData.filter(data => selEmpdiv === data.empdiv);
                    const updatePromises = filteredData
                        .filter(data => data.quesno > quesno)
                        .map(async data => {
                            const docRef = doc(db, "questionnaire", data.id);
                            await updateDoc(docRef, { quesno: data.quesno - 1 });
                        });
            
                    // 모든 업데이트 프로미스를 동시에 실행
                    await Promise.all(updatePromises);
            
                    // quesData 상태 업데이트
                    fetchDataCallback();
                } catch (error) {
                    console.error("삭제 에러:", error);
                }
            }
        });
    };

    const handleClick = (e, index) => {
        const { top, left, height } = e.target.getBoundingClientRect();
    
        const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
        const menuWidthInPixels = 5 * rootFontSize;
        const menuHeightInPixels = 5.5 * rootFontSize;
    
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        const windowHeight = window.innerHeight;
    
        let topPosition = top + scrollTop + height - 20;
        let leftPosition = left + scrollLeft - menuWidthInPixels;
    
        // 메뉴가 화면 아래로 넘어가는 경우 위로 표시
        if (topPosition + menuHeightInPixels > scrollTop + windowHeight) {
            topPosition = top + scrollTop - menuHeightInPixels + 20;
        }

        // 메뉴가 화면 왼쪽으로 넘어가는 경우 오른쪽에 표시
        if (leftPosition < 0) {
            leftPosition = left + scrollLeft;
        }
    
        setMenuPosition({
            top: topPosition,
            left: leftPosition,
        });
        setActiveMenu(activeMenu === index ? null : index);
    };

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva">
                        <div>
                            <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>문항 관리</div>
                            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <div className="renew_question_empdiv"
                                        style={{ color:selEmpdiv === 'Dentist' && '#191919',
                                            fontWeight:selEmpdiv === 'Dentist' && 600}}
                                        onClick={() => handleMenuClick('Dentist')}
                                    >원장</div>
                                    <div style={{width:1,height:"0.9375rem",background:'#CECECE',margin:'0rem 0.5rem'}} />
                                    <div className="renew_question_empdiv"
                                        style={{ color:selEmpdiv === 'Staff' && '#191919',
                                            fontWeight:selEmpdiv === 'Staff' && 600}}
                                        onClick={() => handleMenuClick('Staff')}
                                    >선생님</div>
                                </div>
                                <div style={{display:'flex'}}>
                                    <img src={IconUpward} alt='위로' style={{width:"2rem",height:"2rem",cursor:'pointer'}}
                                        onClick={() => swapIndex('Up')} />
                                    <img src={IconDownward} alt='아래로' style={{width:"2rem",height:"2rem",cursor:'pointer',marginLeft:8}} 
                                        onClick={() => swapIndex('Down')} />
                                </div>
                            </div>
                            <div style={{width:'100%',height:2,background:'#191919',marginTop:"0.75rem"}} />
                            {quesData
                                .filter(data => selEmpdiv === data.empdiv)
                                .map((data, index) => (
                                    <div key={index} onClick={() => setSelectedDivIndex(index)}
                                        style={{backgroundColor: selectedDivIndex === index ? '#FDFFF0' : 'transparent'}}>
                                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',color:'#373737',fontSize:"1.125rem",
                                                    fontWeight:600,height:"3.438rem"}}>
                                            <div style={{display:'flex'}}>
                                                <div style={{color:'#C39F56',marginRight:"1.563rem"}}>{data.quesdiv === 'Multiple' ? '객관식' : '서술형'}</div>
                                                <div>{data.question}</div>
                                            </div>
                                            <div className="evaquestionmanage_button_area">
                                                <button className="renew_question_moddel" onClick={()=>toEvaQueMngReg(data)}>편집</button>
                                                <button className="renew_question_moddel" onClick={()=>handleDeleteButtonClick(data.id, data.quesno)}>삭제</button>
                                            </div>
                                            <div className="evaquestionmanage_button_area_mobile">
                                                <img src={More} alt='더보기' style={{width:"2.5rem",height:"2.5rem",cursor:'pointer'}} 
                                                    onClick={(e) => handleClick(e, index)}
                                                />
                                            </div>
                                            {activeMenu === index && (
                                                <div style={{
                                                    position: 'absolute',
                                                    top: `${menuPosition.top}px`,
                                                    left: `${menuPosition.left}px`,
                                                    width: '5rem',
                                                    height: '5.5rem',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    zIndex: 100 // 다른 요소들 위에 표시되도록 함
                                                }}>
                                                    <div className="evamanagefront_seldel_button"
                                                        onClick={()=>toEvaQueMngReg(data)}
                                                    >편집</div>
                                                    <div className="evamanagefront_seldel_button del"
                                                        onClick={()=>handleDeleteButtonClick(data.id, data.quesno)}
                                                    >삭제</div>
                                                </div>
                                            )}
                                        </div>
                                        <div style={{height:1,background:'#E8E8E8'}} />
                                    </div>
                                ))
                            }
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:"6.25rem"}}>
                            <button className="renew_evasignup" onClick={()=>toEvaQueMngReg("NEW")}>신규</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaQuestionManage;