import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { getDoc, doc, updateDoc, Timestamp } from "firebase/firestore";

import SelectIcon from '../images/renewal/Category_select.svg';
import CheckRed from '../images/renewal/Check_red.svg';
import CheckGreen from '../images/renewal/Check_green.svg';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function CrmContact() {
    const { state } = useLocation();
    const personalId = state.personalId;
    const coData = state.coData;
    const navigate = useNavigate();

    const [contactInfo, setContactInfo] = useState([]); //고객데이터 저장 변수
    const [phone4, setPhone4] = useState(); //전화번호 뒷 4자리 변수
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [showCalendarCslt, setShowCalendarCslt] = useState(false);  //방문예정 달력 보이기 기능
    const [showCalendarVisit, setShowCalendarVisit] = useState(false);  //방문 달력 보이기 기능
    const [showCalendarPrcd, setShowCalendarPrcd] = useState(false);  //진행여부 달력 보이기 기능
    const [showCalendarResv, setShowCalendarResv] = useState(false);  //예약 달력 보이기 기능
    const [showCalendarDiag, setShowCalendarDiag] = useState(false);  //진단 달력 보이기 기능 
    const [showCalendarCsEx, setShowCalendarCsEx] = useState(false);  //상담예정 달력 보이기 기능
    const [showCalendarTher, setShowCalendarTher] = useState(false);  //처료결정 달력 보이기 기능
    const [showCalendarThEx, setShowCalendarThEx] = useState(false);  //처료예정 달력 보이기 기능
    const [showCalendarEtcP, setShowCalendarEtcP] = useState(false);  //기타진행 달력 보이기 기능
    const [showCalendarEtcR, setShowCalendarEtcR] = useState(false);  //기타예약 달력 보이기 기능

    const messageRef = useRef(null);
    const calendarRef = useRef(null);
    const currentDate = new Date(); // 현재 날짜로 설정
    
    const callDb = useCallback(async () => {
        //고객 컨택정보 조회
        const contactInfoRef = doc(db, "crmcontactinfo", personalId);
        const snapshot = await getDoc(contactInfoRef);
        
        setPhone4(snapshot.data().phone.slice(-4));
        setContactInfo(snapshot.data());
    },[personalId])

    useEffect(() => {
        callDb();
    },[callDb])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // calendarModal이 true일 때 마우스 휠 이벤트를 막는 useEffect 훅 추가
    useEffect(() => {
        const handleWheel = (event) => {
            if (showCalendarCslt || showCalendarVisit || showCalendarPrcd ||
                showCalendarResv || showCalendarDiag || showCalendarCsEx ||
                showCalendarTher || showCalendarThEx || showCalendarEtcP ||
                showCalendarEtcR) {
                event.preventDefault();
            }
        };

        if (showCalendarCslt || showCalendarVisit || showCalendarPrcd ||
            showCalendarResv || showCalendarDiag || showCalendarCsEx ||
            showCalendarTher || showCalendarThEx || showCalendarEtcP ||
            showCalendarEtcR) {
            window.addEventListener('wheel', handleWheel, { passive: false });
        } else {
            window.removeEventListener('wheel', handleWheel);
        }

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, [showCalendarCslt, showCalendarVisit, showCalendarPrcd,
        showCalendarResv, showCalendarDiag, showCalendarCsEx,
        showCalendarTher, showCalendarThEx, showCalendarEtcP,
        showCalendarEtcR]);

    //저장완료됐을때 저장완료 div 보이게 하는 로직
    const handleSaveComplete = () => {
        // 가장 왼쪽으로 스크롤 이동
        // window.scrollTo(0, 0);

        // 메시지를 보이게 합니다.
        if (messageRef.current) {
            messageRef.current.style.display = 'flex';
        }

        // 0.5초 후에 메시지를 숨깁니다.
        setTimeout(() => {
            if (messageRef.current) {
                messageRef.current.style.display = 'none';
            }
        }, 500);
        callDb();
    };

    const handleCalendarClick = async (date, step, field) => {
        const timestampDate = Timestamp.fromDate(date);

        const ref = doc(db, "crmcontactinfo", personalId);
        try{
            if(field === "visit"){
                setShowCalendarCslt(!showCalendarCslt);
                if(step === 1){
                    await updateDoc(ref, {
                        consultyn: "Y",
                        consultdate: timestampDate,
                        // visityn: "",
                        // visitdate: "",
                        step: 2,
                        completeyn: "N"
                    })
                }else{
                    await updateDoc(ref, {
                        consultyn: "Y",
                        consultdate: timestampDate,
                        // completeyn: "N"
                    })
                }
            }else if(field === "visitreal"){
                setShowCalendarVisit(!showCalendarVisit);
                if(step === 2){
                    await updateDoc(ref, {
                        visityn: "Y",
                        visitdate: timestampDate,
                        step: 3,
                        completeyn: "N"
                    })
                }else{
                    await updateDoc(ref, {
                        visityn: "Y",
                        visitdate: timestampDate,
                        // completeyn: "N"
                    })
                }
            }else if(field === "proceed"){
                setShowCalendarPrcd(!showCalendarPrcd);
                await updateDoc(ref, {
                    proceeddate: timestampDate,
                    // completeyn: "N"
                })
            }else if(field === "reserve"){
                setShowCalendarResv(!showCalendarResv);
                if(step === 5){
                    await updateDoc(ref, {
                        reservedate: timestampDate,
                        step: 6,
                        completeyn: "N"
                    })
                }else{
                    await updateDoc(ref, {
                        reservedate: timestampDate,
                        // completeyn: "N"
                    })
                }
            }else if(field === "diagnosis"){
                setShowCalendarDiag(!showCalendarDiag);
                await updateDoc(ref, {
                    diagnosisdate: timestampDate,
                    // completeyn: "N"
                })
            }else if(field === "csltexpt"){
                setShowCalendarCsEx(!showCalendarCsEx);
                await updateDoc(ref, {
                    csltexpectdate: timestampDate,
                    // completeyn: "N"
                })
            }else if(field === "therapy"){
                setShowCalendarTher(!showCalendarTher);
                await updateDoc(ref, {
                    therapydate: timestampDate,
                    // completeyn: "N"
                })
            }else if(field === "etcproceed"){
                setShowCalendarEtcP(!showCalendarEtcP);
                await updateDoc(ref, {
                    etcproceeddate: timestampDate,
                    // completeyn: "N"
                })
            }else if(field === "etcreserve"){
                setShowCalendarEtcR(!showCalendarEtcR);
                if(step === 5){
                    await updateDoc(ref, {
                        etcreservedate: timestampDate,
                        step: 6,
                        completeyn: "N"
                    })
                }else{
                    await updateDoc(ref, {
                        etcreservedate: timestampDate,
                        // completeyn: "N"
                    })
                }
            }else if(field === "therapyex"){
                setShowCalendarThEx(!showCalendarThEx);
                await updateDoc(ref, {
                    therapyexdate: timestampDate,
                    // completeyn: "N"
                })
            }
            handleSaveComplete();
        }catch(error){
            console.log(error)
        }
    };

    const toCrmMain = () => {
        navigate("/crmMain", {
            state: {
                coData: coData
            },
        })
    }

    const handleClickOutside = (e) => {
        if (calendarRef.current && !calendarRef.current.contains(e.target)) {
            // setShowCalendar(false);
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarThEx(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
        }
    };

    const terminateProcess = async () => {
        const ref = doc(db, "crmcontactinfo", personalId);
        await updateDoc(ref, {
            completeyn: "Y"
        })
        handleSaveComplete();
    }

    const stepCompleteRenew = {
        color: '#E7E7E7',
        backgroundColor: '#5D5D5D'
    }

    function SelectBoxRenew (props) {
        const { fieldname, fieldval, commoncode, stepval } = props;
        const filteredCoCode = coData.filter(item => item.code === commoncode);
        const [selVal, setSelVal] = useState(fieldval);

        return(
            <div className="renew_crm_noti_step_attribute">
                <select
                    name={fieldname}
                    style={{ color:'#0F5EFE', fontSize:"1rem", fontWeight: 500, width: '100%' }}
                    value={selVal}
                    onChange={async (e) => {
                        const targetVal = e.target.value;
                        setSelVal(targetVal);

                        const ref = doc(db, "crmcontactinfo", personalId);

                        try {
                            if (fieldname === "consultyn") {
                                await updateDoc(ref, {
                                    consultyn: targetVal
                                });
                                if (targetVal === "Y") {
                                    await updateDoc(ref, {
                                        step: stepval + 1,
                                        completeyn: "N"
                                    });
                                } else if (targetVal === "N") {
                                    await updateDoc(ref, {
                                        consultdate: "",
                                        visityn: "",
                                        visitdate: "",
                                        carecate: "",
                                        proceedyn: "",
                                        proceeddate: "",
                                        reservedate: "",
                                        reserveyn: "",
                                        diagnosisyn: "",
                                        diagnosisdate: "",
                                        diagconsultyn: "",
                                        csltexpectdate: "",
                                        therapyyn: "",
                                        therapydate: "",
                                        etcproceedyn: "",
                                        etcproceeddate: "",
                                        etcreservedate: "",
                                        etcreserveyn: "",
                                        therapyexdate: "",
                                        therapyexyn: "",
                                        step: 0,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "visityn") {
                                await updateDoc(ref, {
                                    visityn: targetVal
                                });
                                if (targetVal === "Y") {
                                    await updateDoc(ref, {
                                        visitdate: new Date(),
                                        step: stepval + 1,
                                        completeyn: "N"
                                    });
                                } else if (targetVal === "N") {
                                    await updateDoc(ref, {
                                        visitdate: "",
                                        carecate: "",
                                        proceedyn: "",
                                        proceeddate: "",
                                        reservedate: "",
                                        reserveyn: "",
                                        diagnosisyn: "",
                                        diagnosisdate: "",
                                        diagconsultyn: "",
                                        csltexpectdate: "",
                                        therapyyn: "",
                                        therapydate: "",
                                        etcproceedyn: "",
                                        etcproceeddate: "",
                                        etcreservedate: "",
                                        etcreserveyn: "",
                                        therapyexdate: "",
                                        therapyexyn: "",
                                        step: 2,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "carecate") {
                                await updateDoc(ref, {
                                    carecate: targetVal,
                                    proceedyn: "",
                                    proceeddate: "",
                                    reserveyn: "",
                                    reservedate: "",
                                    diagnosisyn: "",
                                    diagnosisdate: "",
                                    diagconsultyn: "",
                                    csltexpectdate: "",
                                    therapyyn: "",
                                    therapydate: "",
                                    etcproceedyn: "",
                                    etcproceeddate: "",
                                    etcreservedate: "",
                                    etcreserveyn: "",
                                    therapyexdate: "",
                                    therapyexyn: "",
                                    step: 4,
                                    completeyn: "N"
                                });
                            } else if (fieldname === "proceedyn") {
                                await updateDoc(ref, {
                                    proceedyn: targetVal
                                });
                                if (targetVal === "진행") {
                                    await updateDoc(ref, {
                                        proceeddate: new Date(),
                                        step: 5,
                                        completeyn: "N"
                                    });
                                } else if (targetVal === "보류") {
                                    await updateDoc(ref, {
                                        proceeddate: new Date(),
                                        reservedate: "",
                                        reserveyn: "",
                                        step: 4,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "reserveyn") {
                                await updateDoc(ref, {
                                    reserveyn: targetVal
                                });
                                if (targetVal === "Y") {
                                    await updateDoc(ref, {
                                        step: 7,
                                        completeyn: "Y"
                                    });
                                } else if (targetVal === "N") {
                                    await updateDoc(ref, {
                                        step: 6,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "diagnosisyn") {
                                await updateDoc(ref, {
                                    diagnosisyn: targetVal
                                });
                                if (targetVal === "Y") {
                                    await updateDoc(ref, {
                                        diagnosisdate: new Date(),
                                        step: 5,
                                        completeyn: "N"
                                    });
                                } else if (targetVal === "N") {
                                    await updateDoc(ref, {
                                        diagnosisdate: new Date(),
                                        diagconsultyn: "",
                                        csltexpectdate: "",
                                        therapyyn: "",
                                        therapydate: "",
                                        therapyexdate: "",
                                        therapyexyn: "",
                                        step: 4,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "diagconsultyn") {
                                await updateDoc(ref, {
                                    diagconsultyn: targetVal
                                });
                                if (targetVal === "참석") {
                                    await updateDoc(ref, {
                                        step: 6,
                                        completeyn: "N"
                                    });
                                } else if (targetVal === "미참석") {
                                    await updateDoc(ref, {
                                        therapyyn: "",
                                        therapydate: "",
                                        therapyexdate: "",
                                        therapyexyn: "",
                                        step: 5,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "therapyyn") {
                                await updateDoc(ref, {
                                    therapyyn: targetVal
                                });
                                if (targetVal === "진행") {
                                    await updateDoc(ref, {
                                        therapydate: new Date(),
                                        step: 7,
                                        completeyn: "N"
                                    });
                                } else if (targetVal === "보류") {
                                    await updateDoc(ref, {
                                        therapydate: new Date(),
                                        therapyexdate: "",
                                        therapyexyn: "",
                                        step: 6,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "etcproceedyn") {
                                await updateDoc(ref, {
                                    etcproceedyn: targetVal
                                });
                                if (targetVal === "진행") {
                                    await updateDoc(ref, {
                                        etcproceeddate: new Date(),
                                        step: 5,
                                        completeyn: "N"
                                    });
                                } else if (targetVal === "보류") {
                                    await updateDoc(ref, {
                                        etcproceeddate: new Date(),
                                        etcreservedate: "",
                                        etcreserveyn: "",
                                        step: 4,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "etcreserveyn") {
                                await updateDoc(ref, {
                                    etcreserveyn: targetVal
                                });
                                if (targetVal === "Y") {
                                    await updateDoc(ref, {
                                        step: 7,
                                        completeyn: "Y"
                                    });
                                } else if (targetVal === "N") {
                                    await updateDoc(ref, {
                                        step: 6,
                                        completeyn: "N"
                                    });
                                }
                            } else if (fieldname === "therapyexyn") {
                                await updateDoc(ref, {
                                    therapyexyn: targetVal
                                });
                                if (targetVal === "Y") {
                                    await updateDoc(ref, {
                                        step: 8,
                                        completeyn: "Y"
                                    });
                                } else if (targetVal === "N") {
                                    await updateDoc(ref, {
                                        step: 7,
                                        completeyn: "N"
                                    });
                                }
                            }
                            handleSaveComplete();
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                >
                    {fieldval === "" && <option></option>}
                    {filteredCoCode.map((option) => (
                        <option key={option.id} value={option.value}>{option.value}</option>
                    ))}
                </select>
                <img src={SelectIcon} alt='선택' style={{ width:"0.9375rem", height:"0.9375rem"}} />
            </div>
        )
    }

    const handleDateClick = (e, field) => {
        const { top, left, height } = e.target.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const verticalCenter = windowHeight / 2;
        let newTop = top + height;
        const newLeft = left;

        if (top > verticalCenter) {
            newTop = top - 265;
        }

        setCalendarPosition({ top: newTop, left: newLeft });

        if (field === "consultdate") {
            setShowCalendarCslt(!showCalendarCslt);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
            setShowCalendarThEx(false);
        } else if (field === "visitdate") {
            setShowCalendarVisit(!showCalendarVisit);
            setShowCalendarCslt(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
            setShowCalendarThEx(false);
        } else if (field === "proceeddate") {
            setShowCalendarPrcd(!showCalendarPrcd);
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
            setShowCalendarThEx(false);
        } else if (field === "reservedate") {
            setShowCalendarResv(!showCalendarResv)
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
            setShowCalendarThEx(false);
        } else if (field === "diagnosisdate") {
            setShowCalendarDiag(!showCalendarDiag)
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
            setShowCalendarThEx(false);
        } else if (field === "csltexpectdate") {
            setShowCalendarCsEx(!showCalendarCsEx)
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarTher(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
            setShowCalendarThEx(false);
        } else if (field === "therapydate") {
            setShowCalendarTher(!showCalendarTher)
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
            setShowCalendarThEx(false);
        } else if (field === "etcproceeddate") {
            setShowCalendarEtcP(!showCalendarEtcP)
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarEtcR(false);
            setShowCalendarThEx(false);
        } else if (field === "etcreservedate") {
            setShowCalendarEtcR(!showCalendarEtcR)
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarEtcP(false);
            setShowCalendarThEx(false);
        } else if (field === "therapyexdate") {
            setShowCalendarThEx(!showCalendarThEx)
            setShowCalendarCslt(false);
            setShowCalendarVisit(false);
            setShowCalendarPrcd(false);
            setShowCalendarResv(false);
            setShowCalendarDiag(false);
            setShowCalendarCsEx(false);
            setShowCalendarTher(false);
            setShowCalendarEtcP(false);
            setShowCalendarEtcR(false);
        }
    };

    function FormattedValDateRenew(props) {
        const { fieldval, valdate, handleDateClick } = props;
    
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
    
        const formattedDate = valdate ? formatDate(valdate.toDate()) : "";
    
        return (
            <div className="renew_crm_noti_step_attribute">
                <div
                    className="renew_crm_noti_step_valdate"
                    onClick={(e) => handleDateClick(e, fieldval)}
                    style={{ cursor: "pointer", height:"1.188rem" }}
                >
                    {formattedDate}
                </div>
                <img src={SelectIcon} alt="선택" style={{ width:"0.9375rem", height:"0.9375rem" }} />
            </div>
        );
    }

    //관리 체크리스트 컨텐츠 내용
    function ChecklistBodyRenew () {
        const currentDate = new Date();
        let timeDifferenceMilliseconds;
        let timeDifferenceDays;
        let phoneSms;
        let step = "";
        let stepResult;

        if(contactInfo.completeyn === "N"){
            if(contactInfo.visityn === "N"){
                // Timestamp와 현재 시간의 차이 계산
                timeDifferenceMilliseconds = currentDate - contactInfo.consultdate.toDate();
                timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));

                if(timeDifferenceDays === 0){
                    phoneSms = "전화"
                }else if(timeDifferenceDays === 7){
                    phoneSms = "전화"
                }else if(timeDifferenceDays === 30){
                    phoneSms = "전화"
                }else if(timeDifferenceDays === 90){
                    phoneSms = "전화"
                }else{
                    phoneSms = ""
                }
            }else if(contactInfo.carecate === "임플란트"){
                if(contactInfo.proceedyn === "보류"){
                    step = "진행여부"
                    stepResult = "보류"
                    // Timestamp와 현재 시간의 차이 계산
                    timeDifferenceMilliseconds = currentDate - contactInfo.proceeddate.toDate();
                    timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));

                    if(timeDifferenceDays === 0){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 3){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 7){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 14){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 30){
                        phoneSms = "전화"
                    }else{
                        phoneSms = ""
                    }
                }else if(contactInfo.reserveyn === "N"){
                    step = "예약이행"
                    stepResult = "미이행"
                    // Timestamp와 현재 시간의 차이 계산
                    timeDifferenceMilliseconds = currentDate - contactInfo.reservedate.toDate();
                    timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));
                    if(timeDifferenceDays === 0){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 7){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 30){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 90){
                        phoneSms = "전화"
                    }else{
                        phoneSms = ""
                    }
                }
            }else if(contactInfo.carecate === "치아교정"){
                if(contactInfo.diagnosisyn === "N"){
                    step = "진단진행"
                    stepResult = "미진행"
                    // Timestamp와 현재 시간의 차이 계산
                    timeDifferenceMilliseconds = currentDate - contactInfo.diagnosisdate.toDate();
                    timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));

                    if(timeDifferenceDays === 0){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 3){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 7){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 14){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 30){
                        phoneSms = "전화"
                    }else{
                        phoneSms = ""
                    }
                }else if(contactInfo.diagconsultyn === "미참석"){
                    step = "진단상담"
                    stepResult = "미참석"
                    // Timestamp와 현재 시간의 차이 계산
                    timeDifferenceMilliseconds = currentDate - contactInfo.csltexpectdate.toDate();
                    timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));

                    if(timeDifferenceDays === 0){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 7){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 30){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 90){
                        phoneSms = "전화"
                    }else{
                        phoneSms = ""
                    }
                }else if(contactInfo.therapyyn === "보류"){
                    step = "치료결정"
                    stepResult = "보류"
                    // Timestamp와 현재 시간의 차이 계산
                    timeDifferenceMilliseconds = currentDate - contactInfo.therapydate.toDate();
                    timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));

                    if(timeDifferenceDays === 0){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 3){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 7){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 14){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 30){
                        phoneSms = "전화"
                    }else{
                        phoneSms = ""
                    }
                }else if(contactInfo.therapyexyn === "N"){
                    step = "치료여부"
                    stepResult = "미치료"
                    // Timestamp와 현재 시간의 차이 계산
                    timeDifferenceMilliseconds = currentDate - contactInfo.therapyexdate.toDate();
                    timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));

                    if(timeDifferenceDays === 0){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 3){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 7){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 14){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 30){
                        phoneSms = "전화"
                    }else{
                        phoneSms = ""
                    }
                }
            }else if(contactInfo.carecate === "기타진료"){
                if(contactInfo.etcproceedyn === "보류"){
                    step = "진행여부"
                    stepResult = "보류"
                    // Timestamp와 현재 시간의 차이 계산
                    timeDifferenceMilliseconds = currentDate - contactInfo.etcproceeddate.toDate();
                    timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));

                    if(timeDifferenceDays === 0){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 3){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 7){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 14){
                        phoneSms = "문자"
                    }else if(timeDifferenceDays === 30){
                        phoneSms = "전화"
                    }else{
                        phoneSms = ""
                    }
                }else if(contactInfo.etcreserveyn === "N"){
                    step = "예약이행"
                    stepResult = "미이행"
                    // Timestamp와 현재 시간의 차이 계산
                    timeDifferenceMilliseconds = currentDate - contactInfo.etcreservedate.toDate();
                    timeDifferenceDays = Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));

                    if(timeDifferenceDays === 0){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 7){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 30){
                        phoneSms = "전화"
                    }else if(timeDifferenceDays === 90){
                        phoneSms = "전화"
                    }else{
                        phoneSms = ""
                    }
                }
            }
        }
        //렌더링 영역
        if(contactInfo.visityn === "N"){
            return(
                <div style={{display:'flex',justifyContent:'center'}}>
                    <div style={{display:'inline-flex',padding:"2rem 3.5rem",flexDirection:'column',alignItems:'flex-start',borderRadius:"1rem",background:'#292929'}}>
                        <div style={{display:'flex'}}>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">단계</div>
                                <div className="renew_crm_noti_checklist_body">방문</div>
                            </div>
                            <div style={{width:1,height:"5.375rem",background:'#424242',margin:"0rem 1rem"}} />
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">상태</div>
                                <div className="renew_crm_noti_checklist_body">미이행</div>
                            </div>
                            <div style={{width:1,height:"5.375rem",background:'#424242',margin:"0rem 1rem"}} />
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">경과</div>
                                <div className="renew_crm_noti_checklist_body">{timeDifferenceDays}일</div>
                            </div>
                            <div style={{width:1,height:"5.375rem",background:'#424242',margin:"0rem 1rem"}} />
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">관리 구분</div>
                                <div className="renew_crm_noti_checklist_body">{phoneSms}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return(
                <div style={{display:'flex',justifyContent:'center'}}>
                    <div style={{display:'inline-flex',padding:"2rem 3.5rem",flexDirection:'column',alignItems:'flex-start',borderRadius:"1rem",background:'#292929'}}>
                        <div style={{display:'flex'}}>
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">상담 카테고리</div>
                                {step !== "" ? <div className="renew_crm_noti_checklist_body">{contactInfo.carecate}</div>
                                : <div className="renew_crm_noti_checklist_body" />
                                }
                            </div>
                            <div style={{width:1,height:"5.375rem",background:'#424242',margin:"0rem 1rem"}} />
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">단계</div>
                                <div className="renew_crm_noti_checklist_body">{step}</div>
                            </div>
                            <div style={{width:1,height:"5.375rem",background:'#424242',margin:"0rem 1rem"}} />
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">상태</div>
                                <div className="renew_crm_noti_checklist_body">{stepResult}</div>
                            </div>
                            <div style={{width:1,height:"5.375rem",background:'#424242',margin:"0rem 1rem"}} />
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">경과</div>
                                {step !== "" ? <div className="renew_crm_noti_checklist_body">{timeDifferenceDays}일</div>
                                : <div className="renew_crm_noti_checklist_body" />
                                }
                            </div>
                            <div style={{width:1,height:"5.375rem",background:'#424242',margin:"0rem 1rem"}} />
                            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <div className="renew_crm_noti_checklist_header">관리 구분</div>
                                <div className="renew_crm_noti_checklist_body">{phoneSms}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }   
    }

    return (
        <div className="renew_background" style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            <div ref={messageRef} className="renew_save_complete" style={{display:'none'}}>
                <img src={CheckGreen} alt='종결' style={{width:"0.8125rem",height:"0.8125rem",marginRight:"0.5rem"}} />
                <div>저장 완료</div>
            </div>
            <div className="crm_contact_background">
                <div style={{display:'flex',justifyContent:'space-between',marginBottom:"4.5rem"}}>
                    <div className="renew_content_title" style={{color:'#BEBEBE',cursor:'pointer'}} onClick={toCrmMain}>CRM</div>
                    <div className="renew_content_title" style={{color:'#BEBEBE',cursor:'pointer'}} onClick={()=>navigate("/crmNotiCenter")}>알림센터</div>
                </div>
                <div className="crmcontact_personinfo_area">
                    <div className="crmcontact_personname">
                        <div>{contactInfo.name} 님</div>
                        <div className="crmcontact_personname age">{contactInfo.age} 세</div>
                    </div>
                    <div style={{width:1,height:"2.5rem",background:'#D2D2D2',margin:'0rem 1.25rem'}} />
                    <div style={{display:'flex'}}>
                        <div style={{marginRight:"1.5rem"}}>
                            <div className="renew_crm_noti_dash_header">전화번호(뒤 4자리)</div>
                            <div className="renew_crm_noti_dash_content">{phone4}</div>
                        </div>
                        <div style={{marginRight:"1.5rem"}}>
                            <div className="renew_crm_noti_dash_header">상담자</div>
                            <div className="renew_crm_noti_dash_content">{contactInfo.incstaffname}</div>
                        </div>
                        <div>
                            <div className="renew_crm_noti_dash_header">진료의사</div>
                            <div className="renew_crm_noti_dash_content">{contactInfo.incdentistname}</div>
                        </div>
                    </div>
                </div>
                <div className="renew_crm_noti_subtitle">단계별 체크리스트</div>
                <div style={{display:'flex',overflow:'auto'}}>
                    <div className="renew_crm_noti_step_group">
                        {contactInfo.completeyn === "Y" && contactInfo.step <= 1 ? (
                            <div className="renew_crm_noti_step_complete">
                                <img src={CheckRed} alt='종결' style={{width:"0.8125rem",height:"0.8125rem"}} />
                                <div style={{marginLeft:"0.5rem",color:'#FFF',fontSize:"1.063rem",fontWeight:500}}>종결!</div>
                            </div>
                            ) : (
                                <div style={{height:"2.875rem"}} />
                            )
                        }
                        <div className="renew_crm_noti_step_wrap">
                            <div style={{display:'flex',justifyContent:'center'}}>
                                {contactInfo.step <= 1 &&<div className="renew_crm_noti_step_title" style={stepCompleteRenew}>Step1</div>}
                                {contactInfo.step > 1 && <div className="renew_crm_noti_step_title">Step1</div>}
                            </div>
                            <div className="renew_crm_noti_step_field">상담 예약</div>
                            <SelectBoxRenew fieldname="consultyn" fieldval={contactInfo.consultyn} commoncode="004" stepval={contactInfo.step} />
                            {contactInfo.step > 0 && <div className="renew_crm_noti_step_field">방문 예정일</div>}
                            {contactInfo.consultyn === "Y" && <FormattedValDateRenew valdate={contactInfo.consultdate} fieldval="consultdate" handleDateClick={handleDateClick} />}
                        </div>
                    </div>
                    {contactInfo.step >= 2 &&
                        <div className="renew_crm_noti_step_group">
                            {contactInfo.completeyn === "Y" && contactInfo.step === 2 ? (
                                <div className="renew_crm_noti_step_complete">
                                    <img src={CheckRed} alt='종결' style={{width:"0.8125rem",height:"0.8125rem"}} />
                                    <div style={{marginLeft:"0.5rem",color:'#FFF',fontSize:"1.063rem",fontWeight:500}}>종결!</div>
                                </div>
                                ) : (
                                    <div style={{height:"2.875rem"}} />
                                )
                            }
                            <div className="renew_crm_noti_step_wrap">
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    {contactInfo.step === 2 &&<div className="renew_crm_noti_step_title" style={stepCompleteRenew}>Step2</div>}
                                    {contactInfo.step !== 2 &&<div className="renew_crm_noti_step_title" >Step2</div>}
                                </div>
                                {contactInfo.step > 1 && <div className="renew_crm_noti_step_field">방문 여부</div>}
                                {contactInfo.step > 1 && <SelectBoxRenew fieldname="visityn" fieldval={contactInfo.visityn} commoncode="004" stepval={contactInfo.step} />}
                                {contactInfo.step > 1 && <div className="renew_crm_noti_step_field">방문 일자</div>}
                                <FormattedValDateRenew valdate={contactInfo.visitdate} fieldval="visitdate" handleDateClick={handleDateClick} />
                            </div>
                        </div>
                    }
                    {contactInfo.step >= 3 &&
                        <div className="renew_crm_noti_step_group">
                            {contactInfo.completeyn === "Y" && contactInfo.step === 3 ? (
                                <div className="renew_crm_noti_step_complete">
                                    <img src={CheckRed} alt='종결' style={{width:"0.8125rem",height:"0.8125rem"}} />
                                    <div style={{marginLeft:"0.5rem",color:'#FFF',fontSize:"1.063rem",fontWeight:500}}>종결!</div>
                                </div>
                                ) : (
                                    <div style={{height:"2.875rem"}} />
                                )
                            }
                            <div className="renew_crm_noti_step_wrap">
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    {contactInfo.step === 3 &&<div className="renew_crm_noti_step_title" style={stepCompleteRenew}>Step3</div>}
                                    {contactInfo.step !== 3 &&<div className="renew_crm_noti_step_title" >Step3</div>}
                                </div>
                                {contactInfo.step > 2 && <div className="renew_crm_noti_step_field" style={{marginTop:62}}>상담카테고리</div>}
                                {contactInfo.step > 2 && <SelectBoxRenew fieldname="carecate" fieldval={contactInfo.carecate} commoncode="006" stepval={contactInfo.step} />}
                            </div>
                        </div>
                    }
                    {contactInfo.step >= 4 &&
                        <div className="renew_crm_noti_step_group">
                            {contactInfo.completeyn === "Y" && contactInfo.step === 4 ? (
                                <div className="renew_crm_noti_step_complete">
                                    <img src={CheckRed} alt='종결' style={{width:"0.8125rem",height:"0.8125rem"}} />
                                    <div style={{marginLeft:"0.5rem",color:'#FFF',fontSize:"1.063rem",fontWeight:500}}>종결!</div>
                                </div>
                                ) : (
                                    <div style={{height:"2.875rem"}} />
                                )
                            }
                            <div className="renew_crm_noti_step_wrap">
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    {contactInfo.step === 4 &&<div className="renew_crm_noti_step_title" style={stepCompleteRenew}>Step4</div>}
                                    {contactInfo.step !== 4 &&<div className="renew_crm_noti_step_title" >Step4</div>}
                                </div>
                                {contactInfo.step > 3 && contactInfo.carecate === "임플란트" && <div className="renew_crm_noti_step_field">진행여부</div>}
                                {contactInfo.step > 3 && contactInfo.carecate === "치아교정" && <div className="renew_crm_noti_step_field">진단진행</div>}
                                {contactInfo.step > 3 && contactInfo.carecate === "기타진료" && <div className="renew_crm_noti_step_field">진행여부</div>}
                                {contactInfo.step > 3 && contactInfo.carecate === "임플란트" && <SelectBoxRenew fieldname="proceedyn" fieldval={contactInfo.proceedyn} commoncode="005" stepval={contactInfo.step} />}
                                {contactInfo.step > 3 && contactInfo.carecate === "치아교정" && <SelectBoxRenew fieldname="diagnosisyn" fieldval={contactInfo.diagnosisyn} commoncode="004" stepval={contactInfo.step} />}
                                {contactInfo.step > 3 && contactInfo.carecate === "기타진료" && <SelectBoxRenew fieldname="etcproceedyn" fieldval={contactInfo.etcproceedyn} commoncode="005" stepval={contactInfo.step} />}
                                {contactInfo.step > 3 && contactInfo.carecate === "임플란트" &&  <div className="renew_crm_noti_step_field">진행여부결정일자</div>}
                                {contactInfo.step > 3 && contactInfo.carecate === "치아교정" &&  <div className="renew_crm_noti_step_field">진단결정일자</div>}
                                {contactInfo.step > 3 && contactInfo.carecate === "기타진료" &&  <div className="renew_crm_noti_step_field">진행여부결정일자</div>}
                                {contactInfo.step > 3 && contactInfo.carecate === "임플란트" && <FormattedValDateRenew valdate={contactInfo.proceeddate} fieldval="proceeddate" handleDateClick={handleDateClick} />}
                                {contactInfo.step > 3 && contactInfo.carecate === "치아교정" && <FormattedValDateRenew valdate={contactInfo.diagnosisdate} fieldval="diagnosisdate" handleDateClick={handleDateClick} />}
                                {contactInfo.step > 3 && contactInfo.carecate === "기타진료" && <FormattedValDateRenew valdate={contactInfo.etcproceeddate} fieldval="etcproceeddate" handleDateClick={handleDateClick} />}
                            </div>
                        </div>
                    }
                    {contactInfo.step >= 5 &&
                        <div className="renew_crm_noti_step_group">
                            {contactInfo.completeyn === "Y" && contactInfo.step === 5 ? (
                                <div className="renew_crm_noti_step_complete">
                                    <img src={CheckRed} alt='종결' style={{width:"0.8125rem",height:"0.8125rem"}} />
                                    <div style={{marginLeft:"0.5rem",color:'#FFF',fontSize:"1.063rem",fontWeight:500}}>종결!</div>
                                </div>
                                ) : (
                                    <div style={{height:"2.875rem"}} />
                                )
                            }
                            <div className="renew_crm_noti_step_wrap">
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    {contactInfo.step === 5 &&<div className="renew_crm_noti_step_title" style={stepCompleteRenew}>Step5</div>}
                                    {contactInfo.step !== 5 &&<div className="renew_crm_noti_step_title" >Step5</div>}
                                </div>
                                {contactInfo.step > 4 && contactInfo.carecate === "임플란트" && <div className="renew_crm_noti_step_field" style={{marginTop:62}}>예약일자</div>}
                                {contactInfo.step > 4 && contactInfo.carecate === "치아교정" && <div className="renew_crm_noti_step_field">상담예정일자</div>}
                                {contactInfo.step > 4 && contactInfo.carecate === "기타진료" && <div className="renew_crm_noti_step_field" style={{marginTop:62}}>예약일자</div>}
                                {contactInfo.proceedyn === "진행" && contactInfo.carecate === "임플란트" && <FormattedValDateRenew valdate={contactInfo.reservedate} fieldval="reservedate" handleDateClick={handleDateClick} />}
                                {contactInfo.diagnosisyn === "Y" && contactInfo.carecate === "치아교정" && <FormattedValDateRenew valdate={contactInfo.csltexpectdate} fieldval="csltexpectdate" handleDateClick={handleDateClick} />}
                                {contactInfo.etcproceedyn === "진행" && contactInfo.carecate === "기타진료" && <FormattedValDateRenew valdate={contactInfo.etcreservedate} fieldval="etcreservedate" handleDateClick={handleDateClick} />}
                                {contactInfo.step > 4 && contactInfo.carecate === "치아교정" && <div className="renew_crm_noti_step_field">진단상담</div>}
                                {contactInfo.step > 4 && contactInfo.carecate === "치아교정" && <SelectBoxRenew fieldname="diagconsultyn" fieldval={contactInfo.diagconsultyn} commoncode="007" stepval={contactInfo.step} />}
                            </div>
                        </div>
                    }
                    {contactInfo.step >= 6 &&
                        <div className="renew_crm_noti_step_group">
                            {contactInfo.completeyn === "Y" && ((contactInfo.step > 6 && contactInfo.carecate !== "치아교정") ||
                                contactInfo.step === 6) ? (
                                <div className="renew_crm_noti_step_complete">
                                    <img src={CheckRed} alt='종결' style={{width:"0.8125rem",height:"0.8125rem"}} />
                                    <div style={{marginLeft:"0.5rem",color:'#FFF',fontSize:"1.063rem",fontWeight:500}}>종결!</div>
                                </div>
                                ) : (
                                    <div style={{height:"2.875rem"}} />
                                )
                            }
                            <div className="renew_crm_noti_step_wrap">
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    {contactInfo.step === 6 &&<div className="renew_crm_noti_step_title" style={stepCompleteRenew}>Step6</div>}
                                    {contactInfo.step !== 6 &&<div className="renew_crm_noti_step_title" >Step6</div>}
                                </div>
                                {contactInfo.step > 5 && contactInfo.carecate === "임플란트" && <div className="renew_crm_noti_step_field" style={{marginTop:62}}>예약이행여부</div>}
                                {contactInfo.step > 5 && contactInfo.carecate === "치아교정" && <div className="renew_crm_noti_step_field">치료결정여부</div>}
                                {contactInfo.step > 5 && contactInfo.carecate === "기타진료" && <div className="renew_crm_noti_step_field" style={{marginTop:62}}>예약이행여부</div>}
                                {contactInfo.step > 5 && contactInfo.carecate === "임플란트" && <SelectBoxRenew fieldname="reserveyn" fieldval={contactInfo.reserveyn} commoncode="004" stepval={contactInfo.step} />}
                                {contactInfo.step > 5 && contactInfo.carecate === "치아교정" && <SelectBoxRenew fieldname="therapyyn" fieldval={contactInfo.therapyyn} commoncode="005" stepval={contactInfo.step} />}
                                {contactInfo.step > 5 && contactInfo.carecate === "기타진료" && <SelectBoxRenew fieldname="etcreserveyn" fieldval={contactInfo.etcreserveyn} commoncode="004" stepval={contactInfo.step} />}
                                {contactInfo.step > 5 && contactInfo.carecate === "치아교정" && <div className="renew_crm_noti_step_field">치료결정일자</div>}
                                {contactInfo.step > 5 && contactInfo.carecate === "치아교정" && <FormattedValDateRenew valdate={contactInfo.therapydate} fieldval="therapydate" handleDateClick={handleDateClick} />}
                            </div>
                        </div>
                    }
                    {/* 치아교정인 경우 step7 까지 23.10.04 */}
                    {contactInfo.step >= 7 && contactInfo.carecate === "치아교정" &&
                            <div className="renew_crm_noti_step_group" style={{marginRight:0}}>
                            {contactInfo.completeyn === "Y" && contactInfo.step >= 7 ? (
                                <div className="renew_crm_noti_step_complete">
                                    <img src={CheckRed} alt='종결' style={{width:"0.8125rem",height:"0.8125rem"}} />
                                    <div style={{marginLeft:"0.5rem",color:'#FFF',fontSize:"1.063rem",fontWeight:500}}>종결!</div>
                                </div>
                                ) : (
                                    <div style={{height:"2.875rem"}} />
                                )
                            }
                            <div className="renew_crm_noti_step_wrap" style={{marginRight:0}}>
                                <div style={{display:'flex',justifyContent:'center'}}>
                                    {contactInfo.step >= 7 &&<div className="renew_crm_noti_step_title" style={stepCompleteRenew}>Step7</div>}
                                </div>
                                {contactInfo.step > 6 && contactInfo.carecate === "치아교정" && <div className="renew_crm_noti_step_field">치료예정일자</div>}
                                {contactInfo.therapyyn === "진행" && contactInfo.carecate === "치아교정" && <FormattedValDateRenew valdate={contactInfo.therapyexdate} fieldval="therapyexdate" handleDateClick={handleDateClick} />}
                                {contactInfo.step > 6 && contactInfo.carecate === "치아교정" && <div className="renew_crm_noti_step_field">치료여부</div>}
                                {contactInfo.step > 6 && contactInfo.carecate === "치아교정" && <SelectBoxRenew fieldname="therapyexyn" fieldval={contactInfo.therapyexyn} commoncode="004" stepval={contactInfo.step} />}
                            </div>
                        </div>
                    }
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"3.125rem"}}>
                    <div style={{width:"5.375rem",height:"2.625rem",borderRadius:6,border:'1px solid #FF594F',display:'flex',justifyContent:'center',alignItems:'center',
                                color:'#FF594F',fontSize:"1.063rem",fontWeight:500,cursor:'pointer'}}
                         onClick={terminateProcess}
                    >종결처리</div>
                </div>
                <div className="renew_crm_noti_subtitle">관리 체크리스트</div>
                <div className="crm_checklist_wrap">
                    <div style={{display:'inline-flex',padding:"2rem 3.5rem",flexDirection:'column',alignItems:'flex-start',borderRadius:"1rem",background:'#292929'}}>
                        <ChecklistBodyRenew />
                    </div>
                </div>
            </div>
            {showCalendarCslt && (
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "visit")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            )}
            {showCalendarVisit && (
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "visitreal")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            )}
            {showCalendarPrcd && contactInfo.carecate === "임플란트" &&
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "proceed")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            }
            {showCalendarResv && contactInfo.carecate === "임플란트" && 
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "reserve")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            }
            {showCalendarDiag && contactInfo.carecate === "치아교정" && 
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "diagnosis")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            }
            {showCalendarCsEx && contactInfo.carecate === "치아교정" && 
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "csltexpt")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            }
            {showCalendarTher && contactInfo.carecate === "치아교정" && 
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "therapy")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            }
            {showCalendarEtcP && contactInfo.carecate === "기타진료" && 
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "etcproceed")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            }
            {showCalendarEtcR && contactInfo.carecate === "기타진료" && 
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "etcreserve")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            }
            {showCalendarThEx && contactInfo.carecate === "치아교정" && 
                <div className="sliding_calendar_regdate"
                    style={{ top: calendarPosition.top, left: calendarPosition.left, zIndex:1000 }}
                    ref={calendarRef}
                >
                    <Calendar
                        onChange={(e)=>handleCalendarClick(e, contactInfo.step, "therapyex")}
                        defaultActiveStartDate={currentDate}
                    />
                </div>
            }
        </div>
    )
}

export default CrmContact;