import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { db } from '../firebase_config';


function EvaReportHome(props) {
  const navigate = useNavigate();
  const [selEmpdiv, setSelEmpdiv] = useState('Dentist');
  const [employeeData, setEmployeeData] = useState([]);

  const fetchDataCallback = useCallback(async () => {
    async function renderEmployees() {
        const docRef = collection(db, "employees");
        const q = query(docRef, 
                        where("groupid", "==", props.userGroup),
                        orderBy("empno"));
        const snapshot = await getDocs(q);
        return snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
    }

    const data = await renderEmployees();

    setEmployeeData(data);
  }, [props.userGroup]);

  useEffect(() => {
      fetchDataCallback();
  }, [fetchDataCallback]);

  const toEvaReportShow = (props) => {
    navigate("/evaReportShow",{
      state: {
          data: props
      },
    });
  }

  return (
    <div className="renew_background">
      <div className="content_align">
        <div className="content_maxwidth">
          <div className="renew_content_background eva">
            <div>
              <div style={{display:'flex',justifyContent:'space-between',marginBottom:"4.5rem"}}>
                <div className="renew_content_title">리포트</div>
                <div style={{display:'flex'}}>
                  <div className="renew_report_button" 
                      style={{fontWeight:600,color:'#191919',borderBottom:'4px solid #E5B88E',marginRight:"1rem"}}
                  >개인별 종합</div>
                  <div className="renew_report_button" style={{borderBottom:'4px solid #FFF'}} onClick={()=>navigate("/evaReportTerm")}
                  >기간별 진료 실적</div>
                </div>
              </div>
              <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:"1.5rem"}}>
                <div className="renew_question_empdiv"
                    style={{color:selEmpdiv === 'Dentist' && '#191919',
                        fontWeight:selEmpdiv === 'Dentist' && 600}}
                    onClick={()=>setSelEmpdiv('Dentist')}
                >원장</div>
                <div style={{width:1,height:"0.9375rem",background:'#CECECE',margin:'0rem 0.5rem'}} />
                <div className="renew_question_empdiv"
                    style={{ color:selEmpdiv === 'Staff' && '#191919',
                        fontWeight:selEmpdiv === 'Staff' && 600}}
                    onClick={()=>setSelEmpdiv('Staff')}
                >선생님</div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}>
                {employeeData
                  .filter(data => selEmpdiv === data.empdiv)
                  .map((data, index) => (
                      <div key={index} onClick={()=>toEvaReportShow(data)} className="evareporthome_image_area">
                        <img src={data.fileAddr} alt="직원이미지" className="evareporthome_image" />
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',color:'#373737',fontSize:"1.125rem",
                                    fontWeight:600,padding:'1rem 0rem'}}>
                            <div style={{display:'flex'}}>
                                <div>{data.name}</div>
                                <div style={{color:'#858585',marginLeft:5}}>{data.empdiv === 'Dentist' ? '원장' : '선생'}</div>
                            </div>
                        </div>
                      </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EvaReportHome;