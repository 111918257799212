import React, { useState, useCallback, useEffect } from 'react';
import { db } from '../firebase_config.js';
import { getDocs, updateDoc, setDoc, doc, where, collection, query } from "firebase/firestore";
import Email from '../Email.js';
import Swal from "sweetalert2";

function AdminMemAprv(props) {
    const userAuth = props.userAuth;
    
    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);

    const [newMemList, setNewMemList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [groupId, setGroupId] = useState("");
    const [groupName, setGroupName] = useState("");

    const callDb = useCallback(async () => {
        const ref = collection(db, "users");
        const q = query(ref, where("approveyn", "==", "N"),
                         where("newmemberconfirm", "==", ""),
                         where("emailverified","==","Y"),
                        );
        const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

        const newMemData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        
        setNewMemList(newMemData);

        const groupSnapshot = await getDocs(query(collection(db, "group"))); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
        const existData = groupSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        setGroupList(existData);
    },[])

    useEffect(() => {
        callDb();
    },[callDb, updateState])

    const registerGroupData = async () => {
        if(groupId !== "" && groupName !== ""){
            if(groupList.filter(item => item.groupid === groupId).length === 0){
                try{     
                    await setDoc(doc(db, "group", groupId),{
                        groupid: groupId,
                        groupname: groupName
                    });
                    Swal.fire({
                        icon: "success",
                        title: "그룹등록",
                        text: "그룹등록이 완료되었습니다",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            setUpdateState(!updateState);
                        }
                    });
                } catch(error) {
                    console.log("에러메세지:", error.message);
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: "그룹등록 실패",
                    text: "존재하는 그룹코드",
                    // showCancelButton: true,
                    confirmButtonText: "확인",
                    // cancelButtonText: "취소",
                }).then((res) => {
                    if (res.isConfirmed) {
                        // props.onDelete();
                    }
                });
            }   
        }else{
            Swal.fire({
                icon: "error",
                title: "그룹등록 실패",
                text: "입력값 없음",
                // showCancelButton: true,
                confirmButtonText: "확인",
                // cancelButtonText: "취소",
            }).then((res) => {
                if (res.isConfirmed) {
                    // props.onDelete();
                }
            });
        }
    }

    function ShowNewMemList() {
        return(
            <div>
                {newMemList.map((option,index) => (
                    <div key={option.id} style={{display:'flex',color:'#373737',fontSize:18,fontWeight:500,
                        height:55,borderBottom:'#E8E8E8 1px solid',background:index%2 === 0 && '#FAFAFA',
                        alignItems:'center',justifyContent:'space-between'}}>
                        <div style={{width:'20%'}}>{option.userName}</div>
                        <div style={{width:'30%'}}>{option.dentalname}</div>
                        {groupList.filter(item => item.groupname === option.dentalname).length > 0 ? (
                            <div style={{width:'20%'}}>Y</div>
                        ) : (
                            <div style={{width:'20%'}}>N</div>
                        )}
                        <div style={{width:'10%'}}>
                            <button onClick={async ()=>{
                                    if(groupList.filter(item => item.groupname === option.dentalname).length > 0){
                                        const filterList = groupList.filter(item => item.groupname === option.dentalname);
                                        await updateDoc(doc(db, "users", option.id), {
                                            groupid: filterList[0].groupid
                                        })
                                        Swal.fire({
                                            icon: "question",
                                            title: "그룹등록 완료",
                                            text: "그룹등록 완료",
                                            // showCancelButton: true,
                                            confirmButtonText: "확인",
                                            // cancelButtonText: "취소",
                                        }).then((res) => {
                                            if (res.isConfirmed) {
                                                // props.onDelete();
                                                setUpdateState(!updateState);
                                            }
                                        });
                                    }else{
                                        Swal.fire({
                                            icon: "error",
                                            title: "그룹코드 없음",
                                            text: "그룹코드 먼저 등록하고 하시오",
                                            // showCancelButton: true,
                                            confirmButtonText: "확인",
                                            // cancelButtonText: "취소",
                                        }).then((res) => {
                                            if (res.isConfirmed) {
                                                // props.onDelete();
                                            }
                                        });
                                    }
                                }}
                                >선택
                            </button>
                        </div>
                        <div style={{width:'10%'}}>
                            <button onClick={async ()=>{
                                    if(userAuth === 7 && option.groupid !== "nocode"){
                                        await updateDoc(doc(db, "users", option.id), {
                                            approveyn: "Y",
                                            auth: 2,
                                            newmemberconfirm: "Y",
                                        })
                                        Swal.fire({
                                            icon: "question",
                                            title: "승인 완료",
                                            text: "회원 승인 완료",
                                            // showCancelButton: true,
                                            confirmButtonText: "확인",
                                            // cancelButtonText: "취소",
                                        }).then((res) => {
                                            if (res.isConfirmed) {
                                                const sendEmailForm = {
                                                    templateKey: "template_8k2tokf",
                                                    userEmail: option.userId,
                                                    userName: option.userName,
                                                }
                                                Email(sendEmailForm);
                                                // props.onDelete();
                                                setUpdateState(!updateState);
                                            }
                                        });
                                    }else{
                                        Swal.fire({
                                            icon: "error",
                                            title: "승인 실패",
                                            text: "권한없거나 그룹지정안함",
                                            // showCancelButton: true,
                                            confirmButtonText: "확인",
                                            // cancelButtonText: "취소",
                                        }).then((res) => {
                                            if (res.isConfirmed) {
                                                // props.onDelete();
                                            }
                                        });
                                    }
                                }}
                                >승인
                            </button>
                        </div>
                        <div style={{width:'10%'}}>
                            <button onClick={async ()=>{
                                    Swal.fire({
                                        icon: "question",
                                        title: "미확인처리",
                                        text: "미확인처리하시나요?",
                                        showCancelButton: true,
                                        confirmButtonText: "확인",
                                        cancelButtonText: "취소",
                                    }).then(async (res) => {
                                        if (res.isConfirmed) {
                                            if(userAuth === 7){
                                                await updateDoc(doc(db, "users", option.id), {
                                                    newmemberconfirm: "N",
                                                    groupid: "nogroup",
                                                })
                                                Swal.fire({
                                                    icon: "question",
                                                    title: "미확인처리 완료",
                                                    text: "미확인처리 완료",
                                                    // showCancelButton: true,
                                                    confirmButtonText: "확인",
                                                    // cancelButtonText: "취소",
                                                }).then((res) => {
                                                    if (res.isConfirmed) {
                                                        // props.onDelete();
                                                        setUpdateState(!updateState);
                                                    }
                                                });
                                            }else{
                                                Swal.fire({
                                                    icon: "error",
                                                    title: "승인 실패",
                                                    text: "권한없거나 그룹지정안함",
                                                    // showCancelButton: true,
                                                    confirmButtonText: "확인",
                                                    // cancelButtonText: "취소",
                                                }).then((res) => {
                                                    if (res.isConfirmed) {
                                                        // props.onDelete();
                                                    }
                                                });
                                            }
                                            // props.onDelete();
                                        }
                                    });
                                }}
                                >미확인
                            </button> 
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="renew_background" style={{padding:50}}>
            <div className="renew_content_background" 
                 style={{minWidth:window.innerWidth-700,minHeight:window.innerHeight-200}}
            >
                <div className="renew_content_title" style={{marginBottom:72}}>신규회원승인</div>
                <div style={{display:'flex',borderBottom:'#E8E8E8 1px solid',color:'#9F9F9F',fontSize:16,
                            fontWeight:500,paddingBottom:10,width:'100%'}}>
                    <div style={{width:'20%'}}>이름</div>
                    <div style={{width:'30%'}}>병원명</div>
                    <div style={{width:'20%'}}>그룹명존재여부</div>
                    <div style={{width:'10%'}}>그룹지정</div>
                    <div style={{width:'10%'}}>승인</div>
                    <div style={{width:'10%'}}>미확인</div>
                </div>
                <ShowNewMemList />
                <div style={{display:'flex',borderBottom:'#E8E8E8 1px solid',color:'#9F9F9F',fontSize:16,
                            fontWeight:500,paddingBottom:10,width:'100%',marginTop:50}}>
                    <div style={{width:'30%'}}>그룹코드</div>
                    <div style={{width:'40%'}}>그룹명</div>
                    <div style={{width:'30%'}}>등록</div>
                </div>
                <div style={{display:'flex',color:'#373737',fontSize:18,fontWeight:500,
                    height:55,borderBottom:'#E8E8E8 1px solid',alignItems:'center',justifyContent:'space-between'}}>
                    <input style={{width:'30%'}}
                            id="_groupid" 
                            type="text" 
                            placeholder="그룹코드"
                            onChange={(e)=>{setGroupId(e.target.value);}}
                    ></input>
                    <input style={{width:'40%'}}
                            id="_groupname" 
                            type="text" 
                            placeholder="그룹명"
                            onChange={(e)=>{setGroupName(e.target.value);}}
                    ></input>
                    <div style={{width:'30%'}}>
                        <button onClick={(e)=>{
                                e.preventDefault();
                                registerGroupData()
                            }
                        }>등록</button>
                    </div>
                </div>
                <div>
                    <div>권한값: 1 = explanation 수정 가능</div>
                    <div>권한값: 2 = explanation 수정 불가능</div>
                    <div>권한값: 3 = 승인대기</div>
                    <div>권한값: 7 = 관리자</div>
                </div>
            </div>
        </div>
    )
}

export default AdminMemAprv;