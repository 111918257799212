import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { where, getDocs, collection, query } from "firebase/firestore";
import Swal from "sweetalert2";


function FindID() {
    const navigate = useNavigate();

    const [registerUsername, setRegisterUsername] = useState("");
    const [registerUserphone, setRegisterUserphone] = useState("");

    const findUserInfo = async () => {
        try {
            const userRef = collection(db, "users");
            const q = query(userRef, where("userName", "==", registerUsername),
                                    where("userPhone", "==", registerUserphone),
                            )
            const querySnapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
            if (querySnapshot.size > 0) {
                const userData = [];

                querySnapshot.forEach((doc) => {
                    userData.push({
                        id: doc.id,
                        ...doc.data() // 문서의 데이터를 객체 형태로 추가
                    });
                });

                // `navigate` 함수 사용하여 findidresult 화면으로 이동하면서 데이터 전달
                navigate('/findidresult', { state: { users: userData } });
            }else{
                showAlert("warning", "일치 회원정보 없음", "입력한 정보와 일치하는 회원정보가 없습니다.");
            }
        } catch (error) {
            // Handle any potential errors
            showAlert("warning", "아이디찾기 데이터조회 오류", error);
        }
    }

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background signup">
            <div className="renew_content_background">
                <div>
                    <div>
                        <div className="renew_content_title">아이디 찾기</div> 
                        <div className="content_area" />
                        <div className="renew_inner_title">이름</div>
                        <div>
                            <input className="renew_evasignup"
                                    id="_username" 
                                    type="text" 
                                    onChange={(e)=>{setRegisterUsername(e.target.value);}}
                            />
                        </div>
                        <div className="renew_inner_title">전화번호</div>
                        <div>
                            <input className="renew_evasignup"
                                    id="_phone" 
                                    type="text" 
                                    placeholder="예)01012345678" 
                                    onChange={(e)=>{setRegisterUserphone(e.target.value);}}
                            />
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"13.38rem"}}>
                    {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                    <button className="renew_evasignup"
                            onClick={(e)=>{
                                e.preventDefault();
                                if(
                                    registerUsername !== "" &&
                                    registerUserphone !== ""
                                ){
                                    findUserInfo();
                                }else{
                                    Swal.fire({
                                        icon: "error",
                                        title: "미입력 값 존재",
                                        text: "입력하지 않은 항목이 존재합니다",
                                        //showCancelButton: true,
                                        confirmButtonText: "확인",
                                        //cancelButtonText: "취소",
                                    }).then((res) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (res.isConfirmed) {
                                            //확인 요청 처리
                                        }
                                    });
                                }
                            }}
                    >아이디 찾기</button>
                </div>
            </div>
        </div>
    )
}

export default FindID;