import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, getDocs, orderBy, query, where, updateDoc, increment, doc} from "firebase/firestore";
import { db, auth } from '../firebase_config.js';
import Swal from "sweetalert2";

import Secret from '../images/secret.png';
import Refresh from '../images/renewal/Refresh.svg';
import ViewCnt from '../images/renewal/View_cnt.svg';
import Reply from '../images/renewal/Reply.svg';

function Notice(props) {
  const navigate = useNavigate();
  const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
  const noticeName = state.id;
  const noticeCode = state.code;

  const [noticeList, setNoticeList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
        const q = query(collection(db, "bbsdata"), where("code", "==", noticeCode), 
                        where("delyn", "==", "N"), where("reply", "==", "N"), 
                        orderBy("istop", "desc"), orderBy("regdate","desc"));
        const snapshot = await getDocs(q);
    
        const data = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        }));
        
        setNoticeList(data);
    };

    fetchData(); // fetchData 함수를 즉시 호출
  }, [refresh, noticeCode]);

  const toNoticeRead = async (id, email, secret) => {
    const userid = auth.currentUser.email;
    const docRef = doc(db, "bbsdata", id);

    if(email === userid || props.userAuth === 1 || props.userAuth === 7){
      // 조회수 증가 
      await updateDoc(docRef, {
        count: increment(1)
      })
      await new Promise(resolve => setTimeout(resolve, 500));
      navigate("/noticeRead", {
        state: {
          id: id,
          owncontent: "Y",
          noticename: noticeName,
          noticecode: noticeCode,
        },
      })
    }else if(!secret){
      // 조회수 증가 
      await updateDoc(docRef, {
        count: increment(1)
      })
      await new Promise(resolve => setTimeout(resolve, 500));
      navigate("/noticeRead", {
        state: {
          id: id,
          owncontent: "N",
          noticename: noticeName,
          noticecode: noticeCode,
        },
      })
    }else{
      Swal.fire({
        icon: "error",
        title: "비밀글",
        text: "작성자 본인만 확인가능합니다.",
        // showCancelButton: true,
        confirmButtonText: "확인",
        // cancelButtonText: "취소",
      }).then((res) => {
          /* Read more about isConfirmed, isDenied below */
          if (res.isConfirmed) {
              //확인 요청 처리
          }
      });
    }
  }

  return (
    <div className="renew_background notice">
      <div className="renew_content_background notice">
        <div>
          <div style={{display:'flex',alignItems:'center',marginBottom:"4.5rem"}}>
            <div className="renew_content_title">{noticeName}</div>
            <div style={{display:'flex',width:"2.25rem",height:"2.25rem",flexShrink:0,borderRadius:"0.5rem",border:'1px solid #E3E3E3',
                        alignItems:'center',justifyContent:'center',marginLeft:"1rem",cursor:'pointer'}}
                onClick={()=>setRefresh(!refresh)}
            >
              <img src={Refresh} alt='새로고침' style={{width:"1.125rem",height:"1.125rem"}} />
            </div>
          </div>
          <div>
            <div className="notice_header">
              <div className="notice_header_mobile_count">댓글 수</div>
              <div className="notice_header_mobile_content">제목</div>
              <div className="notice_header_index">번호</div>
              <div className="notice_header_username">작성자</div>
              <div className="notice_header_title">제목</div>
              <div className="notice_header_info" />
            </div>
            {noticeList.map((option, index) => {
              const regDate = option.regdate.toDate(); // Firebase Timestamp를 JavaScript Date 객체로 변환

              // 현재 시간과의 차이 계산
              const currentTime = new Date();
              const diffTime = currentTime - regDate;
              const diffMinutes = Math.floor(diffTime / (1000 * 60)); // 분 단위로 변환
          
              let formattedDate = '';
          
              if (diffMinutes < 1) {
                  formattedDate = '방금';
              } else if (diffMinutes < 60) {
                  formattedDate = `${diffMinutes}분 전`;
              } else if (currentTime.getDate() === regDate.getDate()) {
                  formattedDate = `${regDate.getHours()}:${('0' + regDate.getMinutes()).slice(-2)}`;
              } else {
                  formattedDate = `${('0' + (regDate.getMonth() + 1)).slice(-2)}-${('0' + regDate.getDate()).slice(-2)}`;
              }

              return (
                <div key={option.id} 
                    style={{display:'flex',color:'#373737',fontSize:"1.125rem",fontWeight:500,padding:'1.063rem 0rem',
                            borderBottom:'1px #E8E8E8 solid',alignItems:'center'}}
                >
                  <div className="notice_header_mobile_count">{option.replycount}</div>
                  <div className="notice_header_mobile_content" onClick={()=>toNoticeRead(option.id, option.userid, option.secret)}>
                    <div>
                      {option.secret === true ? 
                        <div style={{display:'flex',alignItems:'center'}}>
                          {option.title}
                          <img src={Secret} alt="비밀글" style={{width:"1.25rem",height:"1.25rem",marginLeft:"0.3125rem"}} />
                        </div> 
                        : option.title
                      }
                    </div>
                    <div style={{display:'flex',fontSize:"1rem",marginTop:"0.5rem"}}>
                      <div>{formattedDate}</div>
                      <div style={{margin:"0rem 0.2rem"}} />
                      <div style={{color:"#9F9F9F"}}>
                        {option.username === "관리자"
                        ? option.username
                        : `${option.username[0]}${'*'.repeat(option.username.length - 1)}`}
                      </div>
                    </div>
                  </div>
                  <div className="notice_header_index" style={{color:'#858585'}}>
                    {option.istop ? "공지" : noticeList.length - index}
                  </div>
                  <div className="notice_header_username">
                    {option.username === "관리자"
                    ? option.username
                    : `${option.username[0]}${'*'.repeat(option.username.length - 1)}`}
                  </div>
                  <div className="notice_header_title" style={{cursor:'pointer'}}
                      onClick={()=>toNoticeRead(option.id, option.userid, option.secret)}
                  >{option.secret === true ? 
                      <div style={{display:'flex',alignItems:'center'}}>
                        {option.title}
                        <img src={Secret} alt="비밀글" style={{width:"1.25rem",height:"1.25rem",marginLeft:"0.3125rem"}} />
                      </div> 
                    : option.title
                  }</div>
                  <div className="notice_header_info body">
                    <div style={{display:'flex',alignItems:'center'}}>
                      <img src={ViewCnt} alt='조회수' style={{width:"1.5rem",height:"1.5rem",marginRight:"0.375rem"}} />
                      <div>{option.count}</div>
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}>
                      <img src={Reply} alt='댓글수' style={{width:"1.5rem",height:"1.5rem",marginRight:"0.375rem"}} />
                      <div>{option.replycount}</div>
                    </div>
                    <div>{formattedDate}</div>
                  </div>
                </div>
              )})
            }
          </div>
        </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:"6.25rem"}}>
          {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
          { noticeName === "공지사항" ?
            (props.userAuth === 7 || props.userAuth === 1) &&
            <button className="renew_evasignup" 
                    onClick={()=>navigate("/noticeWrite",{
                      state: {
                        contentdata: "NEW",
                        noticename: noticeName,
                        noticecode: noticeCode,
                      },
                    })}
            >글쓰기</button>
          :
            <button className="renew_evasignup" 
                    onClick={()=>navigate("/noticeWrite",{
                      state: {
                        contentdata: "NEW",
                        noticename: noticeName,
                        noticecode: noticeCode,
                      },
                    })}
            >글쓰기</button>
          }
        </div>
      </div>
    </div>
  )
}

export default Notice;