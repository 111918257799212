import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config';

function EvaReportShow(props) {
    const navigate = useNavigate();
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const empdata = state.data;

    const [diagData, setDiagData] = useState([]);
    const [surveyData, setServeyData] = useState([]);
    const [selQuesdiv, setSelQuesdiv] = useState('Multiple');
    const [totalDiagTime, setTotalDiagTime] = useState(0);  // 누적 진료시간
    const [avgDiagTime, setAvgDiagTime] = useState(0);  //평균 진료시간

    const fetchDataCallback = useCallback(async () => {
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        const fetchDiagData = async () => {
            const docRef = collection(db, "diagtime");
            const q = query(docRef, where("empid", "==", empdata.fileid),
                                    where("diagtimestart", ">=", oneMonthAgo),
                                    where("diagtimestart", "<=", new Date()));
            const snapshot = await getDocs(q);
    
            return snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        };
    
        const fetchSurveyData = async () => {
            const docRef = collection(db, "surveydatas");
            const q = query(docRef, where("managerid", "==", empdata.fileid),
                                    where("regdatetime", ">=", oneMonthAgo),
                                    where("regdatetime", "<=", new Date()));
            const snapshot = await getDocs(q);
    
            return snapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
        };
    
        const [diagData, surveyData] = await Promise.all([fetchDiagData(), fetchSurveyData()]);
    
        setDiagData(diagData);
        setServeyData(surveyData);
    
        const tmpDiagTime = diagData.reduce((acc, cur) => acc + cur.diagelapsemin, 0);
    
        if (tmpDiagTime > 0) {
            setTotalDiagTime(tmpDiagTime);
            setAvgDiagTime(Math.round(tmpDiagTime / diagData.length));
        }
    }, [empdata.fileid]);
    
    useEffect(() => {
        fetchDataCallback();
    }, [fetchDataCallback]);

    function SurveyQuestions({ surveyData, selQuesdiv }) {
        const questionList = surveyData
            .filter(item => item.quesdiv === selQuesdiv && item.question !== "empty")
            .map(item => item.question);
    
        const uniqueQuestions = [...new Set(questionList)];
    
        return (
            <div>
                {uniqueQuestions.map((question, index) => (
                    <div key={index}>
                        <SurveyResults question={question} surveyData={surveyData} selQuesdiv={selQuesdiv} />
                    </div>
                ))}
            </div>
        );
    }
    
    function SurveyResults({ question, surveyData, selQuesdiv }) {
        if (selQuesdiv === "Multiple") {
            const resultList = surveyData.filter(item => item.quesdiv === selQuesdiv && item.question === question);
            const resultCounts = resultList.reduce((acc, item) => {
                acc[item.resultval] = (acc[item.resultval] || 0) + 1;
                return acc;
            }, { vg: 0, g: 0, n: 0, b: 0, vb: 0 });
    
            return (
                <div style={{marginTop:"3rem"}}>
                    <div style={{display:'flex',color:'#C39F56',fontSize:"1.25rem",fontWeight:500}}>Q. 
                        <div style={{marginLeft:5,color:'#000'}}>{question}</div>
                    </div>
                    <div className="evareportshow_point_area">
                        {["매우 만족", "만족", "보통", "불만", "매우 불만"].map((label, index) => (
                            <div className="evareportshow_point_flex" key={index}>
                                <div>{label}</div>
                                <div className="evareportshow_point">{resultCounts[["vg", "g", "n", "b", "vb"][index]]}</div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        } else {
            const resultList = surveyData.filter(item => item.quesdiv === selQuesdiv && item.question === question);
    
            return (
                <div>
                    <div style={{display:'flex',color:'#C39F56',fontSize:"1.25rem",fontWeight:500,marginTop:"3rem",
                                marginBottom:"1.5rem"}}>Q. 
                        <div style={{marginLeft:5,color:'#000'}}>{question}</div>
                    </div>
                    {resultList.map((item, index) => (
                        item.resultval !== "" && (
                            <div key={index} style={{display:'flex',padding:"1rem",flexDirection:'column',
                                                alignItems:'flex-start',gap:"0.625rem",borderRadius:"0.5rem",background:'#F4F4F0',
                                                marginBottom:"1rem",color:'#373737',fontSize:"1rem",fontWeight:500}}>
                                {item.resultval}
                            </div>
                        )
                    ))}
                </div>
            );
        }
    }

    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva">
                        <div style={{display:'flex',justifyContent:'space-between',marginBottom:"4.5rem"}}>
                            <div className="renew_content_title">리포트</div>
                            <div style={{display:'flex'}}>
                                <div className="renew_report_button" 
                                    style={{fontWeight:600,color:'#191919',borderBottom:'4px solid #E5B88E',marginRight:"1rem"}}
                                >개인별 종합</div>
                                <div className="renew_report_button" style={{borderBottom:'4px solid #FFF'}} onClick={()=>navigate("/evaReportTerm")}
                                >기간별 진료 실적</div>
                            </div>
                        </div>
                        <div className="evareportshow_flex">
                            <img src={empdata.fileAddr} alt='직원이미지' style={{width:"15rem",height:"22.5rem",borderRadius:"0.625rem"}} />
                            <div className="evareportshow_text_area">
                                <div className="evareportshow_nametag">
                                    <div>{empdata.name}</div>
                                    <div style={{color:'#858585',marginLeft:5}}>{empdata.empdiv === 'Dentist' ? '원장' : '선생'}</div>
                                </div>
                                <div className="evareportshow_performance_area">
                                    <div style={{marginRight:"4.438rem"}}>
                                        <div className="renew_report_patcnt">진료 환자 수</div>
                                        <div style={{display:'flex',alignItems:'flex-end'}}>
                                            <div className="renew_report_number">{diagData.length}</div>
                                            <div className="renew_report_number_text">명</div>
                                        </div>
                                    </div>
                                    <div style={{marginRight:"4.438rem"}}>
                                        <div className="renew_report_patcnt">누적 진료 시간</div>
                                        <div style={{display:'flex',alignItems:'flex-end'}}>
                                            <div className="renew_report_number">{totalDiagTime}</div>
                                            <div className="renew_report_number_text">분</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="renew_report_patcnt">평균 진료 시간</div>
                                        <div style={{display:'flex',alignItems:'flex-end'}}>
                                            <div className="renew_report_number">{avgDiagTime}</div>
                                            <div className="renew_report_number_text">분</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:"3.75rem",height:1,background:'#E3E3E3',marginBottom:"0.5rem"}} />
                                <div style={{color:'#BEBEBE',fontSize:"0.75rem",fontWeight:500}}>*최근 1개월 기준입니다.</div>
                            </div>
                        </div>
                        <div style={{display:'flex',alignItems:'flex-end'}}>
                            <div className="renew_report_question_div"
                                style={{color:selQuesdiv === 'Multiple' && '#191919',
                                    fontWeight:selQuesdiv === 'Multiple' && 600,padding:"0.5rem",
                                    borderBottom:selQuesdiv === 'Multiple' && '2px #C39F56 solid'}}
                                onClick={()=>setSelQuesdiv('Multiple')}
                            >객관식</div>
                            <div className="renew_report_question_div"
                                style={{color:selQuesdiv === 'Descriptive' && '#191919',
                                    fontWeight:selQuesdiv === 'Descriptive' && 600,padding:"0.5rem",
                                    borderBottom:selQuesdiv === 'Descriptive' && '2px #C39F56 solid'}}
                                onClick={()=>setSelQuesdiv('Descriptive')}
                            >서술형</div>
                            <div style={{ flexGrow: 1, borderBottom: '2px #E3E3E3 solid' }} />
                        </div>
                        <SurveyQuestions surveyData={surveyData} selQuesdiv={selQuesdiv} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaReportShow;