import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { auth, db } from '../firebase_config.js';
import Email from '../Email.js';
import Logo from '../images/renewal/Logo.svg';
import Swal from "sweetalert2";


function Login() {
    const [loginEmail, setLoginEmail] = useState();
    const [loginPassword, setLoginPassword] = useState();
    const navigate = useNavigate();

    const loginProcess = async () => {
        try {
            // const user = await signInWithEmailAndPassword(
            await signInWithEmailAndPassword(
                auth,
                loginEmail,
                loginPassword
            ).then(async ()=>{
                if(auth.currentUser.emailVerified === false){
                    Swal.fire({
                        icon: "error",
                        title: "이메일 인증 미완료",
                        text: "발송된 인증 이메일을 확인하시기 바랍니다.",
                        showCancelButton: true,
                        confirmButtonText: "확인",
                        cancelButtonText: "인증메일 재전송",
                    }).then(async (res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            // signOut(auth);
                            // window.location.reload(false);  //페이지 refresh
                        } else if (res.dismiss === Swal.DismissReason.cancel) {
                            // 인증메일 재전송 버튼 클릭 시
                            try {
                                // 사용자에게 이메일 확인 요청
                                await sendEmailVerification(auth.currentUser);
                                Swal.fire({
                                    icon: "success",
                                    title: "인증 이메일 재전송 성공",
                                    text: "이메일을 확인하여 인증을 완료해주세요.",
                                });
                            } catch (error) {
                                console.error("인증 이메일 전송 실패:", error);
                                Swal.fire({
                                    icon: "error",
                                    title: "인증 이메일 전송 실패",
                                    text: "다시 시도해주세요.",
                                });
                            }
                        }
                    });
                }else{
                    const docRef = doc(db, "users", loginEmail);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        if (docSnap.data().emailverified === "N"){
                            //user테이블에 이메일인증 필드 값 변경 처리
                            const userRef = doc(db, "users", loginEmail);
                            await updateDoc(userRef, {
                                emailverified: "Y",
                            })
                
                            const sendEmailForm = {
                                templateKey: "template_cugdufd",
                                userEmail: loginEmail,
                                userName: docSnap.data().userName,
                            }
                            Email(sendEmailForm);
                        }
                    }
                }
            });
            // console.log("로그인완료:", user);
            //navigate("/") //페이지 이동 필요없어짐.
        } catch(error) {
          Swal.fire({
              icon: "error",
              title: "로그인 실패",
              text: "아이디와 비밀번호를 확인하세요",
              //showCancelButton: true,
              confirmButtonText: "확인",
              //cancelButtonText: "취소",
          }).then((res) => {
              /* Read more about isConfirmed, isDenied below */
              if (res.isConfirmed) {
                  //확인 요청 처리
              }
          });
            console.log("에러메세지:", error.message);
        }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            loginProcess();
        }
    }

    return (
        <div className="renew_background" style={{display:'flex',justifyContent:'center',alignItems:'center',minHeight:'100vh',padding:0}}>
            <div className="desktop_layout">
                <img src={Logo} alt="로고" className="login_logo" />
                <div style={{color:'#8C8C8C',fontStyle:'normal',lineHeight:'normal'}}>
                    <div style={{fontSize:"1rem",fontWeight:600}}>덴탈커넥트</div>
                    <div style={{display:'flex',marginTop:17,marginBottom:11,}}>
                        <div style={{fontSize:"0.875rem",fontWeight:400,cursor:'pointer'}} onClick={()=>navigate('/termsuse')}>이용약관</div>
                        <div style={{fontSize:"0.875rem",marginLeft:5,marginRight:5}}>|</div>
                        <div style={{fontSize:"0.875rem",fontWeight:400,cursor:'pointer'}} onClick={()=>navigate('/termspersonalprocess')}>개인정보처리방침</div>
                    </div>
                    <div style={{color:'#C8C8C8',fontSize:"0.875rem",fontWeight:500}}>문의: dentalconnet@gmail.com</div>
                    <div style={{color:'#C8C8C8',fontSize:"0.875rem",fontWeight:500}}>Copyright 2022. Dental Connect All rights reserved.</div>
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <div className="tablet_layout" style={{marginTop:"0rem"}}>
                    <img src={Logo} alt="로고" className="login_logo" />
                </div>
                <div className="input_area">
                    <input className="renew_login_id_pw" 
                        id="_email" 
                        type="email" 
                        placeholder="아이디" 
                        onChange={(e)=>{setLoginEmail(e.target.value);}}>
                    </input>
                    <div className="login_between_input" />
                    <input className="renew_login_id_pw" 
                        id="_password" 
                        type="password" 
                        placeholder='비밀번호'
                        onChange={(e)=>{setLoginPassword(e.target.value);}}
                        onKeyPress={onKeyPress}>
                    </input>
                    <div className="login_between_button" />
                    <button className='renew_login_id_pw loginbutton'
                            onClick={loginProcess}
                    >로그인</button>
                    <div className="option_area">
                        <div style={{color:'#CA976F',fontSize:"1.125rem",fontWeight:600,cursor:'pointer'}}
                            onClick={()=>navigate('/termsagree')}
                        >
                            회원가입
                        </div>
                        <div style={{display:'flex',color:'#BEBEBE',fontSize:"1rem",fontWeight:500}}>
                            <div style={{cursor:'pointer',textDecorationLine:'underline'}} onClick={()=>navigate('/findid')}>아이디</div>
                            <div style={{margin:"0px 5px"}}>/</div>
                            <div style={{cursor:'pointer',textDecorationLine:'underline'}} onClick={()=>navigate('/findpw')}>비밀번호</div>
                            <div style={{marginLeft:5}}>찾기</div>
                        </div>
                    </div>
                </div>
                <div className="tablet_layout" style={{marginBottom:"2.5rem"}}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div style={{color:'#858585',fontSize:"1.0625rem",fontWeight:600}}>덴탈커넥트</div>
                        <div style={{display:'flex',marginTop:"1rem",}}>
                            <div style={{fontSize:"0.9375rem",fontWeight:500,cursor:'pointer',color:'#A5A5A5'}} onClick={()=>navigate('/termsuse')}>이용약관</div>
                            <div style={{fontSize:"0.9375rem",marginLeft:5,marginRight:5}}>|</div>
                            <div style={{fontSize:"0.9375rem",fontWeight:500,cursor:'pointer',color:'#A5A5A5'}} onClick={()=>navigate('/termspersonalprocess')}>개인정보처리방침</div>
                        </div>
                        <div style={{color:'#A5A5A5',fontSize:"0.9375rem",fontWeight:500,marginBottom:"3rem"}}>문의: dentalconnet@gmail.com</div>
                        <div style={{color:'#CECECE',fontSize:"0.875rem",fontWeight:500}}>Copyright 2022. Dental Connect All rights reserved.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;