import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { auth, db } from '../firebase_config.js';
import { doc, setDoc, where, getDocs, collection, query } from "firebase/firestore";
import Swal from "sweetalert2";


function Signup() {
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const checkperson = state.checkperson; //전 페이지에서 넘어온 매개변수 
    const checkevent = state.checkevent; //전 페이지에서 넘어온 매개변수 
    const checkuse = state.checkuse; //전 페이지에서 넘어온 매개변수

    const [registerEmail, setRegisterEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [registerPassword, setRegisterPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(false);
    const [checkPassword, setCheckPassword] = useState("");
    const [registerUsername, setRegisterUsername] = useState("");
    const [registerUserphone, setRegisterUserphone] = useState("");
    const [pwCheckComment, setPwCheckComment] = useState("");
    const [chkEmailYn, setChkEmailYn] = useState('중복확인');
    const [dentalName, setDentalName] = useState("");
    //const navigate = useNavigate();

    const registerAuth = async () => {
        // 등록된 전화번호 입력한 사람만 가입하는 방식에서 일단 가입하고 승인해주는 방식으로 변경
        // 기존 로직 주석처리 23.09.16
        // const docRef = doc(db, "authphone", registerUserphone);
        // const docSnap = await getDoc(docRef);

        // if (docSnap.exists()) {
            if(chkEmailYn === "확인완료"){
                if(passwordValid && pwCheckComment === "완료" && emailValid){
                    //try {
                            createUserWithEmailAndPassword(    
                                auth,
                                registerEmail,
                                registerPassword
                            ).then(()=>{
                                register();
                            }).catch((error)=>{
                                Swal.fire({
                                    icon: "warning",
                                    title: "회원가입 오류",
                                    text: error,
                                    //showCancelButton: true,
                                    confirmButtonText: "확인",
                                    //cancelButtonText: "취소",
                                })
                            })
                    //} catch(error) {
                    //    console.log("에러메세지:", error.message);
                    //}
                }else{
                    Swal.fire({
                        icon: "warning",
                        title: "회원가입 오류",
                        text: "비밀번호/아이디 규칙을 확인해주세요",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                        }
                    });
                }
            }else{
                Swal.fire({
                    icon: "warning",
                    title: "중복체크 미완료",
                    text: "아이디 중복체크를 해주세요",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
            }
        // } else {
        //     console.log("No such document!");
        //     Swal.fire({
        //         icon: "warning",
        //         title: "허가되지 않은 사용자",
        //         text: "등록된 사용자만 가입가능합니다",
        //         confirmButtonText: "확인",
        //     }).then((res) => {
        //         if (res.isConfirmed) {
        //         }
        //     });
        // }
    }

    const register = async () => {   
        // 회원가입 시 그룹아이디 불러와서 넣어주는 로직
        // 승인해줄때 그룹아이디 넣어주거나 등록해주거나 관리자가 해주는 것으로 변경
        // 기존 그룹아이디 불러오는 로직 주석처리 23.09.16
        // let groupid;

        // try{
        //     const q = query(collection(db, "authphone"), where("phonenumber", "==", registerUserphone));

        //     const querySnapshot = await getDocs(q);
        //     querySnapshot.forEach((doc) => {
        //       groupid = doc.data().groupid;
        //     });
        // } catch(error) {
        //     console.log("에러메세지:", error.message);
        // }
        const now = new Date();

        try{     
            await setDoc(doc(db, "users", registerEmail),{
                userId: registerEmail,
                userName: registerUsername,
                userPhone: registerUserphone,
                groupid: "nocode",
                auth: 3,
                approveyn: "N",
                dentalname: dentalName,
                termsperson: checkperson,
                termsevent: checkevent,
                termsuse: checkuse,
                emailverified: "N",
                newmemberconfirm: "",
                regdate: now,
            });
            // console.log("회원가입완료");
            //window.location.reload(false);  //페이지 refresh

            // 사용자에게 이메일 확인 요청
            await sendEmailVerification(auth.currentUser);
            Swal.fire({
                icon: "success",
                title: "회원가입완료",
                text: "발송된 인증메일 확인 및 관리자 승인 후 정상이용 가능합니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                    signOut(auth);
                    window.location.reload(false);  //페이지 refresh
                }
            });
        } catch(error) {
            console.log("에러메세지:", error.message);
        }
    }

    const chkPassword = (props) => {
        setCheckPassword(props);
        //console.log(checkPassword);
        
    }

    useEffect (()=>{
        if(registerPassword !== "" && registerPassword === checkPassword){
            setPwCheckComment("완료")
        }else(
            setPwCheckComment("")
        )
    },[registerPassword, checkPassword])

    const chkEmail = async () => {
        if(registerEmail !== ""){
            const contentsRef = collection(db, "users");
            const q = query(contentsRef, where("userId", "==", registerEmail));
            const querySnapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

            if(querySnapshot.docs.length !== 0){
                Swal.fire({
                    icon: "error",
                    title: "중복아이디체크",
                    text: "입력하신 아이디는 이미 존재합니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        setChkEmailYn("중복확인");
                    }
                });
            }else{
                Swal.fire({
                    icon: "success",
                    title: "중복아이디체크",
                    text: "이용가능한 아이디입니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        setChkEmailYn("확인완료");
                    }
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "아이디 미입력",
                text: "아이디를 입력하세요",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        }
    }

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setRegisterPassword(newPassword);
    
        const hasLetter = /[a-zA-Z]/.test(newPassword);
        const hasNumber = /\d/.test(newPassword);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

        // 유효성 체크
        setPasswordValid(hasLetter && hasNumber && hasSpecialChar && newPassword.length >= 6);
    };

    const handleEmailCheck = (e) => {
        const newEmail = e.target.value;
        setRegisterEmail(newEmail);
        setChkEmailYn("중복확인");

        const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/.test(newEmail);

        setEmailValid(emailRegEx);
    }

    return (
        <div className="renew_background signup">
            <div className="renew_content_background">
                <div>
                    <div className="renew_content_title">회원가입</div> 
                    <div className="content_area signup" style={{marginTop:"4.5rem"}}>
                        <div style={{marginRight:"5rem"}}>
                            <div style={{display:'flex'}}>
                                <div className="renew_inner_title">아이디(이메일)</div>
                                {!emailValid && registerEmail && (
                                    <div className="renew_inner_title" style={{color:'#FF594F',marginLeft:"0.5rem"}}>이메일양식이어야합니다.</div>
                                )}
                            </div>
                            <div>
                                <input className="renew_evasignup"
                                        style={{width:"13.25rem",marginRight:"0.5rem"}}
                                        id="_email" 
                                        type="email" 
                                        placeholder='인증메일 수신가능 주소'
                                        onChange={handleEmailCheck}
                                />
                                <button style={{width:"5rem",height:"3rem",borderRadius:"0.5rem",border:'1px solid #7962F6',color:'#7962F6',
                                                fontSize:"1rem",fontWeight:600}}
                                        onClick={()=>{chkEmail()}}
                                >{chkEmailYn}
                                </button>
                            </div>
                        </div>
                        <div>
                            <div className="renew_inner_title">이름</div>
                            <div>
                                <input className="renew_evasignup"
                                        id="_username" 
                                        type="text" 
                                        onChange={(e)=>{setRegisterUsername(e.target.value);}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content_area signup">
                        <div style={{marginRight:"5rem"}}>
                            <div style={{display:'flex'}}>
                                <div className="renew_inner_title">비밀번호</div>
                                {!passwordValid && registerPassword && (
                                    <div className="renew_inner_title" style={{color:'#FF594F',marginLeft:"0.3125rem"}}>숫자/특수문자를 포함해야 합니다.</div>
                                )}
                            </div>
                            <div>
                                <input className="renew_evasignup"
                                        id="_password" 
                                        type="password" 
                                        placeholder='숫자+특수문자 포함 6자리이상'
                                        onChange={handlePasswordChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div style={{display:'flex'}}>
                                <div className="renew_inner_title">비밀번호 확인</div>
                                <div className="renew_inner_title" style={{color:'#FF594F',marginLeft:"0.3125rem"}}>{pwCheckComment}</div>
                            </div>
                            <div>
                                <input className="renew_evasignup"
                                        id="_password_chk" 
                                        type="password" 
                                        placeholder='비밀번호 확인'
                                        onChange={(e)=>{chkPassword(e.target.value)}}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="content_area signup">
                        <div style={{marginRight:"5rem"}}>
                            <div className="renew_inner_title">전화번호</div>
                            <div>
                                <input className="renew_evasignup"
                                        id="_phone" 
                                        type="text" 
                                        placeholder="예)01012345678" 
                                        onChange={(e)=>{setRegisterUserphone(e.target.value);}}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="renew_inner_title">병원명</div>
                            <div>
                                <input className="renew_evasignup"
                                        id="_dentalname" 
                                        type="text" 
                                        placeholder="승인확인가능 병원명" 
                                        onChange={(e)=>setDentalName(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signup_button_area">
                    {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                    <button className="renew_evasignup"
                            onClick={(e)=>{
                                e.preventDefault();
                                if(
                                    registerEmail !== "" &&
                                    registerUsername !== "" &&
                                    registerUserphone !== "" &&
                                    dentalName !== ""
                                ){
                                    registerAuth();
                                }else{
                                    Swal.fire({
                                        icon: "error",
                                        title: "미입력 값 존재",
                                        text: "입력하지 않은 항목이 존재합니다",
                                        //showCancelButton: true,
                                        confirmButtonText: "확인",
                                        //cancelButtonText: "취소",
                                    }).then((res) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (res.isConfirmed) {
                                            //확인 요청 처리
                                        }
                                    });
                                }
                            }}
                    >회원가입</button>
                </div>
            </div>
        </div>
    )
}

export default Signup;