import React, { useState, useCallback, useEffect, useRef } from 'react';
import { db } from '../firebase_config.js';
import { getDocs, collection, query, doc, updateDoc, where } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Calendar_select from '../images/renewal/Calendar_select.svg';

function CrmNotiCenter(props) {
    const userGroup = props.userGroup;
    const navigate = useNavigate();

    const [targetPhone, setTargetPhone] = useState(0);
    const [targetSms, setTargetSms] = useState(0);
    const [completePercent, setCompletePercent] = useState(0);
    const [completeCnt, setCompleteCnt] = useState(0);

    const [subject0, setSubject0] = useState([]);
    const [subject3, setSubject3] = useState([]);
    const [subject7, setSubject7] = useState([]);
    const [subject14, setSubject14] = useState([]);
    const [subject30, setSubject30] = useState([]);
    const [subject90, setSubject90] = useState([]);

    const [personInfo, setPersonInfo] = useState([]);
    const [surveyData, setSurveyData] = useState([]);
    const [coCode, setCoCode] = useState([]);
    const [selDate, setSelDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);  //기준일자 달력 보이기 기능
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });

    const calendarRef = useRef(null);

    const callDb = useCallback(async () => {
        //환자리스트 조회
        const personalInfoRef = collection(db, "crmcontactinfo");
        const q = query(personalInfoRef, where("groupid", "==", userGroup)
                                       , where("step", ">", 1)
                                       , where("step", "<", 8)                
        );
        const snapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

        const personData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        
        setPersonInfo(personData);

        //설문조사 완료 조회
        const svRef = collection(db, "crmsurveynotification");
        const svQ = query(svRef, where("confirmyn", "==", "N"), where("groupid", "==", userGroup));
        const svSnapshot = await getDocs(svQ);

        const svData = svSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));
        setSurveyData(svData);

        //공통코드 조회
        const coRef = collection(db, "commoncode");
        const coQ = query(coRef);
        const coSnapshot = await getDocs(coQ);

        const coData = coSnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        setCoCode(coData);
    },[userGroup])

    useEffect(() => {
        callDb();
    },[callDb])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toCrmMain = () => {
        navigate("/crmMain", {
            state: {
                coData: coCode
            },
        })
    }
    
    //contact 페이지 이동
    const toCrmContactPage = (props) => {
        navigate("/crmContact", {
            state: {
                personalId: props,
                coData: coCode
            },
        })
    }

    function ViewCalendar() {
      
        return (
            <>
                {showCalendar && (
                    <div className="sliding_calendar_regdate"
                        style={{ top: calendarPosition.top, left: calendarPosition.left }}
                        ref={calendarRef}
                    >
                        <Calendar
                            onChange={(e)=>{
                                setSelDate(e);
                                setShowCalendar(!showCalendar);
                            }}
                            defaultActiveStartDate={selDate}
                        />
                    </div>
                )}
            </>
        );
    }

    const handleClickOutside = (e) => {
        if (calendarRef.current && !calendarRef.current.contains(e.target)) {
            setShowCalendar(false);
        }
    };

    function DateSelect () {
        const year = selDate.getFullYear();
        const month = selDate.getMonth() + 1; // 월은 0부터 시작하므로 +1 해야 합니다.
        const day = selDate.getDate();

        return(
            <div style={{display:'flex',alignItems:'center',cursor:'pointer'}}
                onClick={(e)=>{ 
                    const { top, left, height } = e.target.getBoundingClientRect();
                    const newTop = top + height; // 원하는 위치 조절

                    let newLeft;
                    if (window.innerWidth < 480) {
                        // 브라우저 너비가 480px 미만일 때 중앙 정렬
                        newLeft = (window.innerWidth - 350) / 2;
                    } else {
                        newLeft = left;
                    }

                    setCalendarPosition({ top: newTop, left: newLeft });
                    setShowCalendar(!showCalendar);
                }} 
            >
                <div className="renew_crm_unit_date">{year}년 {month}월 {day}일</div>
                <img src={Calendar_select} alt='일자변경' style={{width:"1.188rem",height:"1.188rem",marginLeft:6}} />
            </div>
        )
    }

    const timeDifferenceDays = (date1, date2) => {
        const timeDifferenceMilliseconds = date1 - date2.toDate();
        return Math.floor(timeDifferenceMilliseconds / (1000 * 60 * 60 * 24));
    };

    useEffect(() => {
        let tempTargetPhone = 0;
        let tempTargetSms = 0;
        let tempSubject0 = [];
        let tempSubject3 = [];
        let tempSubject7 = [];
        let tempSubject14 = [];
        let tempSubject30 = [];
        let tempSubject90 = [];

        personInfo.forEach((option) => {
            let days;
            if (option.visityn === "N") {
                // 방문 미이행
                days = timeDifferenceDays(selDate, option.consultdate);
                option.phoneSms = "전화";
                option.stepText = "방문";
                option.status = "미이행";

                if(days === 0){
                    tempSubject0.push(option);
                    tempTargetPhone++;
                }else if(days === 3){
                    tempSubject3.push(option);
                    tempTargetPhone++;
                }else if(days === 7){
                    tempSubject7.push(option);
                    tempTargetPhone++;
                }else if(days === 14){
                    tempSubject14.push(option);
                    tempTargetPhone++;
                }else if(days === 30){
                    tempSubject30.push(option);
                    tempTargetPhone++;
                }else if(days === 90){
                    tempSubject90.push(option);
                    tempTargetPhone++;
                }
            } else if (option.carecate === "임플란트") {
                if (option.proceedyn === "보류") {
                    // 진행여부 보류
                    days = timeDifferenceDays(selDate, option.proceeddate);
                    option.stepText = "진행여부";
                    option.status = "보류";

                    if(days === 0){
                        option.phoneSms = "문자";
                        tempSubject0.push(option);
                        tempTargetSms++;
                    }else if(days === 3){
                        option.phoneSms = "문자";
                        tempSubject3.push(option);
                        tempTargetSms++;
                    }else if(days === 7){
                        option.phoneSms = "전화";
                        tempSubject7.push(option);
                        tempTargetPhone++;
                    }else if(days === 14){
                        option.phoneSms = "문자";
                        tempSubject14.push(option);
                        tempTargetSms++;
                    }else if(days === 30){
                        option.phoneSms = "전화";
                        tempSubject30.push(option);
                        tempTargetPhone++;
                    }
                } else if (option.reserveyn === "N") {
                    // 예약이행 미이행
                    days = timeDifferenceDays(selDate, option.reservedate);
                    option.phoneSms = "전화";
                    option.stepText = "예약이행";
                    option.status = "미이행";
                    
                    if(days === 0){
                        tempSubject0.push(option);
                        tempTargetPhone++;
                    }else if(days === 7){
                        tempSubject7.push(option);
                        tempTargetPhone++;
                    }else if(days === 30){
                        tempSubject30.push(option);
                        tempTargetPhone++;
                    }else if(days === 90){
                        tempSubject90.push(option);
                        tempTargetPhone++;
                    }
                }
            } else if (option.carecate === "치아교정") {
                if (option.diagnosisyn === "N") {
                    // 진단진행 미진행
                    days = timeDifferenceDays(selDate, option.diagnosisdate);
                    option.stepText = "진단진행";
                    option.status = "미진행";
                    
                    if(days === 0){
                        option.phoneSms = "문자";
                        tempSubject0.push(option);
                        tempTargetSms++;
                    }else if(days === 3){
                        option.phoneSms = "문자";
                        tempSubject3.push(option);
                        tempTargetSms++;
                    }else if(days === 7){
                        option.phoneSms = "전화";
                        tempSubject7.push(option);
                        tempTargetPhone++;
                    }else if(days === 14){
                        option.phoneSms = "문자";
                        tempSubject14.push(option);
                        tempTargetSms++;
                    }else if(days === 30){
                        option.phoneSms = "전화";
                        tempSubject30.push(option);
                        tempTargetPhone++;
                    }
                } else if (option.diagconsultyn === "미참석") {
                    // 진단상담 미참석
                    days = timeDifferenceDays(selDate, option.csltexpectdate);
                    option.phoneSms = "전화";
                    option.stepText = "진단상담";
                    option.status = "미참석";

                    if(days === 0){
                        tempSubject0.push(option);
                        tempTargetPhone++;
                    }else if(days === 7){
                        tempSubject7.push(option);
                        tempTargetPhone++;
                    }else if(days === 30){
                        tempSubject30.push(option);
                        tempTargetPhone++;
                    }else if(days === 90){
                        tempSubject90.push(option);
                        tempTargetPhone++;
                    }
                } else if (option.therapyyn === "보류") {
                    // 치료결정 보류
                    days = timeDifferenceDays(selDate, option.therapydate);
                    option.stepText = "치료결정";
                    option.status = "보류";

                    if(days === 0){
                        option.phoneSms = "문자";
                        tempSubject0.push(option);
                        tempTargetSms++;
                    }else if(days === 3){
                        option.phoneSms = "문자";
                        tempSubject3.push(option);
                        tempTargetSms++;
                    }else if(days === 7){
                        option.phoneSms = "전화";
                        tempSubject7.push(option);
                        tempTargetPhone++;
                    }else if(days === 14){
                        option.phoneSms = "문자";
                        tempSubject14.push(option);
                        tempTargetSms++;
                    }else if(days === 30){
                        option.phoneSms = "전화";
                        tempSubject30.push(option);
                        tempTargetPhone++;
                    }
                } else if (option.therapyexyn === "N") {
                    // 치료여부 미치료
                    days = timeDifferenceDays(selDate, option.therapyexdate);
                    option.stepText = "치료여부";
                    option.status = "미치료";
                    
                    if(days === 0){
                        option.phoneSms = "문자";
                        tempSubject0.push(option);
                        tempTargetSms++;
                    }else if(days === 3){
                        option.phoneSms = "문자";
                        tempSubject3.push(option);
                        tempTargetSms++;
                    }else if(days === 7){
                        option.phoneSms = "전화";
                        tempSubject7.push(option);
                        tempTargetPhone++;
                    }else if(days === 14){
                        option.phoneSms = "문자";
                        tempSubject14.push(option);
                        tempTargetSms++;
                    }else if(days === 30){
                        option.phoneSms = "전화";
                        tempSubject30.push(option);
                        tempTargetPhone++;
                    }
                }
            } else if (option.carecate === "기타진료") {
                if (option.etcproceedyn === "보류") {
                    // 진행여부 보류
                    days = timeDifferenceDays(selDate, option.etcproceeddate);
                    option.stepText = "진행여부";
                    option.status = "보류";
                    
                    if(days === 0){
                        option.phoneSms = "문자";
                        tempSubject0.push(option);
                        tempTargetSms++;
                    }else if(days === 3){
                        option.phoneSms = "문자";
                        tempSubject3.push(option);
                        tempTargetSms++;
                    }else if(days === 7){
                        option.phoneSms = "전화";
                        tempSubject7.push(option);
                        tempTargetPhone++;
                    }else if(days === 14){
                        option.phoneSms = "문자";
                        tempSubject14.push(option);
                        tempTargetSms++;
                    }else if(days === 30){
                        option.phoneSms = "전화";
                        tempSubject30.push(option);
                        tempTargetPhone++;
                    }
                } else if (option.etcreserveyn === "N") {
                    // 예약이행 미이행
                    days = timeDifferenceDays(selDate, option.etcreservedate);
                    option.phoneSms = "전화";
                    option.stepText = "예약이행";
                    option.status = "미이행";
                    
                    if(days === 0){
                        tempSubject0.push(option);
                        tempTargetPhone++;
                    }else if(days === 7){
                        tempSubject7.push(option);
                        tempTargetPhone++;
                    }else if(days === 30){
                        tempSubject30.push(option);
                        tempTargetPhone++;
                    }else if(days === 90){
                        tempSubject90.push(option);
                        tempTargetPhone++;
                    }
                }
            }
        });

        setSubject0(tempSubject0);
        setSubject3(tempSubject3);
        setSubject7(tempSubject7);
        setSubject14(tempSubject14);
        setSubject30(tempSubject30);
        setSubject90(tempSubject90);
        setTargetPhone(tempTargetPhone);
        setTargetSms(tempTargetSms);

        const tempCompleteCnt = personInfo.filter((item) => item.completeyn === "Y").length;
        setCompleteCnt(tempCompleteCnt);
        if (personInfo.length !== 0) {
            setCompletePercent(((tempCompleteCnt / personInfo.length) * 100).toFixed(1));
        }
    }, [personInfo, selDate]);

    function NotificationHeader() {
        return(
            <div className="renew_crm_checklist_header">
                <div className="crm_checklist_header_name">이름</div>
                <div className="crm_checklist_header_phone">전화번호</div>
                <div className="crm_checklist_header_age">나이</div>
                <div className="crm_checklist_header_carecate">상담카테고리</div>
                <div className="crm_checklist_header_steptext">단계</div>
                <div className="crm_checklist_header_status">상태</div>
                <div className="crm_checklist_header_phonesms">관리구분</div>
            </div>
        )
    }

    const NotificationSection = ({ title, options }) => (
        <div className="crmnoticenter_notisection_area">
            <div className="renew_crm_unit_title">{title}</div>
            <NotificationHeader />
            {options.map((option, index) => (
                <div
                    key={index}
                    className="renew_crm_checklist_header"
                    style={{ color: '#373737', fontSize: "1.125rem", fontWeight:600 }}
                >
                    <div className="crm_checklist_header_name" 
                        onClick={()=>{
                            toCrmContactPage(option.id)
                        }}
                    >{option.name}</div>
                    <div className="crm_checklist_header_phone">{option.phone}</div>
                    <div className="crm_checklist_header_age">{option.age}</div>
                    <div className="crm_checklist_header_carecate">{option.carecate}</div>
                    <div className="crm_checklist_header_steptext">{option.stepText}</div>
                    <div className="crm_checklist_header_status">{option.status}</div>
                    <div className="crm_checklist_header_phonesms">{option.phoneSms}</div>
                </div>
            ))}
        </div>
    );

    function SurveyCompleteChk (props) {
        const { patId } = props;
        const dateFormatter = new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        let formattedConsultDate;
        if(patId.diagtimestart !== "" && patId.diagtimestart !== null){
            formattedConsultDate = dateFormatter.format(patId.diagtimestart.toDate());
        }else {
            formattedConsultDate = "진료시간 미측정";
        }

        const dateFormatterReg = new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        let formattedRegDate;
        if(patId.regdate !== ""){
            formattedRegDate = dateFormatterReg.format(patId.regdate.toDate());
        }else {
            formattedRegDate = "등록시간 없음";
        }
        
        return(
            <div style={{borderRadius:"0.5rem",background:'#F4F4F0',display:'flex',alignItems:'center',
                        justifyContent:'space-between',padding:'1.375rem 1.5rem',marginBottom:"0.5rem"}}>
                <div className="crmnoticenter_survey_unit_flex">
                    <div style={{color:'#767676',fontSize:"0.875rem",fontWeight:600}}>{formattedConsultDate} 진료</div>
                    <div className="crmnoticenter_survey_unit_name">{patId.patientname}님 설문완료! ({formattedRegDate})</div>
                </div>
                <div style={{borderRadius:8,background:'#000',color:'#FFF',fontSize:"0.9375rem",
                            fontWeight:500,padding:"0.5625rem 0.8125rem",cursor:'pointer'}} onClick={async ()=>{
                    await updateDoc(doc(db, "crmsurveynotification", patId.id), {
                        confirmyn: "Y"
                    })
                    callDb();
                }}>확인</div>
            </div>
        )
    }

    return (
        <div className="renew_background" >
            <div className="content_align">
                <div className="content_maxwidth crm">
                    <div className="renew_content_background crm">
                        <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>알림센터</div>
                        <DateSelect />
                        <div style={{display:'flex',justifyContent:'center',marginTop:"2.813rem"}}>
                            <div className="crmnoticenter_notice_area">
                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                    <div style={{color:'#9F9F9F',marginBottom:"1.438rem"}}>전화 대상</div>
                                    <div>{targetPhone}</div>
                                </div>
                                <div className="renew_crm_dash_between" />
                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                    <div style={{color:'#9F9F9F',marginBottom:"1.438rem"}}>문자 대상</div>
                                    <div>{targetSms}</div>
                                </div>
                                <div className="renew_crm_dash_between" />
                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                    <div style={{color:'#9F9F9F',marginBottom:"1.438rem"}}>총 처리 대상</div>
                                    <div>{personInfo.length}</div>
                                </div>
                                <div className="renew_crm_dash_between" />
                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                    <div style={{color:'#9F9F9F',marginBottom:"1.438rem"}}>처리 완료</div>
                                    <div>{completeCnt}</div>
                                </div>
                                <div className="renew_crm_dash_between" />
                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                    <div style={{color:'#9F9F9F',marginBottom:"1.438rem"}}>완료율</div>
                                    <div>{completePercent}%</div>
                                </div>
                            </div>
                        </div>
                        <NotificationSection title="당일대상" options={subject0} />
                        <NotificationSection title="3일 경과" options={subject3} />
                        <NotificationSection title="1주일 경과" options={subject7} />
                        <NotificationSection title="2주일 경과" options={subject14} />
                        <NotificationSection title="1달 경과" options={subject30} />
                        <NotificationSection title="3달 경과" options={subject90} />
                        <div style={{color:'#272727',fontSize:"1.25rem",fontWeight:600,marginBottom:"1rem",
                                    marginTop:"4.5rem"}}
                        >설문조사 완료</div>
                        {surveyData
                            .slice()
                            .sort((a, b) => b.regdate - a.regdate)
                            .map((option) => (
                            <SurveyCompleteChk patId={option} key={option.id}/>
                        ))}
                        <div style={{display:'flex',justifyContent:'center',marginTop:"6.25rem"}}>
                            {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                            <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF'}}
                                    onClick={toCrmMain}
                            >CRM 메인으로</button>
                        </div>
                    </div>
                    <ViewCalendar />
                </div>
            </div>
        </div>
    )
}

export default CrmNotiCenter;