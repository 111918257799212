import React from 'react';
import { useNavigate } from 'react-router-dom';

function TermsPersonalProcess() {
    const navigate = useNavigate();

    return (
        <div className="renew_background signup">
            <div className="renew_content_background">
                <div className="renew_content_title" >개인정보처리방침</div> 
                <div className="content_area" style={{fontSize:"1rem"}}>
                    <div style={{fontSize:"1.125rem",fontWeight:500}}>시행일자 : 2024.03.06</div>
                    <br />
                    <div>덴탈커넥트 개인정보처리방침</div>
                    <br />
                    <div>제1장 개인정보처리방침</div>
                    <br />
                    <div>1. 제목 및 서문<br />
                        &lt;덴탈커넥트&gt;<br />
                        ("https://dentalconnet.com"이하 "덴탈커넥트")은(는) 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 
                        및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 
                        정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
                    </div>
                    <br />
                    <div>2. 개인정보의 처리 목적<br />
                        덴탈커넥트는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」제18조에 따라 
                        별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                    </div>
                    <br />
                    <div>1) 홈페이지 회원가입 및 관리<br />
                        회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 회원 맞춤형 채용정보 제공, 신규 서비스 안내, 설문, 이벤트 관련 정보 및 참여 기회 제공, 광고 및 마케팅 정보 제공, 
                        서비스 부정이용 방지, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.
                    </div>
                    <br />
                    <div>2) 민원사무 처리<br />
                        민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.
                    </div>
                    <br />
                    <div>3) 재화 또는 서비스 제공<br />
                        서비스 제공, 콘텐츠 제공, 본인인증을 목적으로 개인정보를 처리합니다.
                    </div>
                    <br />
                    <div>4) 마케팅 및 광고에의 활용<br />
                        이벤트 및 광고성 정보 제공 및 참여기회 제공 , 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                    </div>
                    <br />
                    <div>3. 개인정보의 처리 및 보유 기간</div>
                    <br />
                    <div>1) 덴탈커넥트는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유, 
                        이용기간 내에서 개인정보를 처리, 보유합니다.
                    </div>
                    <br />
                    <div>2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</div>
                    <br />
                    <div>① 홈페이지 회원가입 및 관리 : 회원 탈퇴 시 혹은 서비스 제공기간 (관계법령의 규정에 의하여 보존할 필요가 있는 경우 및 사전 동의를 득한 경우 해당 보유 기간) 종료 시까지<br />
                        ② 민원사무 처리 : 민원업무 종료 시 까지<br />
                        ③ 재화 또는 서비스 제공 : 회원 탈퇴 시 혹은 서비스 제공기간 (관계법령의 규정에 의하여 보존할 필요가 있는 경우 및 사전 동의를 득한 경우 해당 보유 기간) 종료 시까지<br />
                        ④ 마케팅 및 광고에의 활용 : 회원 탈퇴 시 혹은 서비스 제공기간 (관계법령의 규정에 의하여 보존할 필요가 있는 경우 및 사전 동의를 득한 경우 해당 보유 기간) 종료 시까지
                    </div>
                    <br />
                    <div>4. 처리하는 개인정보의 항목<br />
                        덴탈커넥트는 다음의 개인정보 항목을 처리하고 있습니다.
                    </div>
                    <br />
                    <div>1) 회원가입<br />
                        - 필수항목 : 아이디, 비밀번호, 회원구분 및 직종, 대표자명, 대표자 면허번호, 근무처명, 병원구분, 사업자등록번호, 연락처(전화번호/휴대폰/이메일), 주소<br />
                        2) 인터넷 서비스 이용과정에서 자동 생성되는 개인정보 항목<br />
                        - 필수항목 : IP주소, 쿠키, 서비스 이용기록, 방문기록, 불량 이용기록<br />
                        3) 민원사무 처리<br />
                        - 필수항목 : 아이디, 비밀번호, 회원구분 및 직종, 대표자명, 대표자 면허번호, 근무처명, 병원구분, 사업자등록번호, 연락처(전화번호/휴대폰/이메일), 주소<br />
                    </div>
                    <br />
                    <div>5. 개인정보의 제3자 제공에 관한 사항</div>
                    <br />
                    <div>1) 덴탈커넥트는 다음과 같이 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건·사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 
                        관계기관에 개인정보를 제공할 수 있습니다.<br />
                        분류: <b>재난대응</b><br />
                        근거법령: <b>재난안전법</b><br />
                        제공기관: <b>중앙대책본부 또는 지역대책본부</b><br />
                        제공되는 개인정보: <b>•성명, 주민등록번호, 주소및전화번호(휴대전화번호포함)</b><br />
                        분류: 감염병의 예방 및 관리<br />
                        근거법령: 감염병예방법<br />
                        제공기관: 질병관리청 또는 전국시·도<br />
                        제공되는 개인정보: •성명, 주민등록번호, 주소및전화번호(휴대전화번호포함)<br />
                        분류: 생계곤란 등의 위기상황 대처<br />
                        근거법령: 긴급복지 지원법<br />
                        제공기관: 국가 및 지방자치단체<br />
                        제공되는 개인정보: •생활이 곤란한 경우 등에 대한 긴급한 생계지원 대상 발굴에 필요한 최소한의 정보
                    </div>
                    <br />
                    <div>이 경우 덴탈커넥트는 근거법령에 의거하여 필요한 최소한의 개인정보만을 제공하며, 목적과 다르게 제공하지 않겠습니다.</div>
                    <br />
                    <div>6. 개인정보의 파기<br />
                        덴탈커넥트는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다. 다만, 다른 법령에 의해 보관해야 하는 정보는 법령이 정한 기간 동안 
                        별도 분리보관 후 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
                    </div>
                    <br />
                    <div>-파기절차 및 기한<br />
                        수집·이용목적이 달성된 개인정보는 지체 없이 파기되며, 관련 법령에 따라 보관되어야 할 경우 별도의 DB에 옮겨져 내부 규정 및 관련 법령을 준수하여 일정 기간 동안 
                        안전하게 보관된 후 지체 없이 파기됩니다. 이때 DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고 다른 목적으로 이용하지 않습니다.
                    </div>
                    <br />
                    <div>-파기방법<br />
                        전자적 파일 형태의 정보는 복구 및 재생할 수 없는 기술적 방법을 사용하여 완전하게 삭제합니다.
                    </div>
                    <br />
                    <div>7. 정보주체와 법정대리인의 권리·의무 및 행사방법에 관한 사항</div>
                    <br />
                    <div>1) 정보주체는 덴탈커넥트에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.<br />
                        1. 개인정보 열람요구<br />
                        2. 오류 등이 있을 경우 정정 요구<br />
                        3. 삭제요구<br />
                        4. 처리정지 요구
                    </div>
                    <br />
                    <div>2) 정보주체는 개인정보 조회/수정을 위해서는 ‘마이페이지’를 통하여 가능하며, 가입해지(동의철회)를 위해서는 ‘회원탈퇴’를 클릭하여 본인확인 절차를 거친 후 
                        직접 열람, 정정 또는 탈퇴가 가능합니다.
                    </div>
                    <br />
                    <div>3) 또한 제1항에 따른 권리 행사는 덴탈커넥트에 대해 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전화, 이메일, 모사전송(FAX) 등을 통하여 하실 수 있으며 
                        덴탈커넥트는 이에 대해 지체 없이 조치하겠습니다.
                    </div>
                    <br />
                    <div>4) 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에 덴탈커넥트는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</div>
                    <br />
                    <div>5) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 
                        따른 위임장을 제출하셔야 합니다.
                    </div>
                    <br />
                    <div>6) 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</div>
                    <br />
                    <div>7) 덴탈커넥트는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</div>
                    <br />
                    <div>8. 개인정보의 안전성 확보조치에 관한 사항<br />
                        덴탈커넥트는 개인정보보호법 제29조, 같은 법 시행령 제30조 및 제48조의2에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
                    </div>
                    <br />
                    <div>1) 개인정보 취급 직원의 최소화 및 교육<br />
                        개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                    </div>
                    <br />
                    <div>2) 정기적인 자체 감사 실시<br />
                        개인정보 취급 관련 안정성 확보를 위해 정기적으로 자체 감사를 실시하고 있습니다.
                    </div>
                    <br />
                    <div>3) 내부관리계획의 수립 및 시행<br />
                        개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                    </div>
                    <br />
                    <div>4) 개인정보의 암호화<br />
                        이용자의 개인정보 중 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 
                        사용하는 등의 별도 보안기능을 사용하고 있습니다.
                    </div>
                    <br />
                    <div>5) 해킹 등에 대비한 기술적 대책<br />
                        덴탈커넥트는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 
                        기술적/물리적으로 감시 및 차단하고 있습니다.
                    </div>
                    <br />
                    <div>6) 개인정보에 대한 접근 제한<br />
                        개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 
                        외부로부터의 무단 접근을 통제하고 있습니다.
                    </div>
                    <br />
                    <div>7) 접속기록의 보관 및 위변조 방지<br />
                        개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.
                    </div>
                    <br />
                    <div>8) 문서보안을 위한 잠금장치 사용<br />
                        개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
                    </div>
                    <br />
                    <div>9) 비인가자에 대한 출입 통제<br />
                        개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
                    </div>
                    <br />
                    <div>9. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항<br />
                        덴탈커넥트는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
                    </div>
                    <br />
                    <div>쿠키는 웹사이트가 귀하의 컴퓨터 브라우저(마이크로소프트 엣지, 크롬 등)로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 본 사이트의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 
                        귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.<br />
                        1) 쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다.<br />
                        또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 
                        아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
                    </div>
                    <br />
                    <div>2) 쿠키 등 사용 목적 : 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 
                        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
                    </div>
                    <br />
                    <div>3) 쿠키 설정 거부 방법 : 쿠키 설정을 거부하는 방법으로는 귀하가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 
                        모든 쿠키의 저장을 거부할 수 있습니다.<br />
                        설정방법 예시 : 마이크로소프트 엣지의 경우 → 설정 &gt; 쿠키 및 사이트 권한
                    </div>
                    <br />
                    <div>단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.</div>
                    <br />
                    <div>10. 정보주체의 권익침해에 대한 구제방법</div>
                    <br />
                    <div>권익침해 구제방법</div>
                    <br />
                    <div>① 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 
                        이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
                    </div>
                    <br />
                    <div>1. 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영)</div>
                    <br />
                    <div>- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</div>
                    <br />
                    <div>- 홈페이지 : privacy.kisa.or.kr</div>
                    <br />
                    <div>- 전화 : (국번없이) 118</div>
                    <br />
                    <div>- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</div>
                    <br />
                    <div>2. 개인정보 침해신고센터 (한국인터넷진흥원 운영)</div>
                    <br />
                    <div>- 소관업무 : 개인정보 침해사실 신고, 상담 신청</div>
                    <br />
                    <div>- 홈페이지 : privacy.kisa.or.kr</div>
                    <br />
                    <div>- 전화 : (국번없이) 118</div>
                    <br />
                    <div>- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</div>
                    <br />
                    <div>3. 대검찰청 사이버범수사과 : (국번없이) 1301 (www.spo.go.kr)<br />
                        4. 경찰청 사이버범죄수사단 : (국번없이) 182 (ecrm.cyber.go.kr)
                    </div>
                    <br />
                    <div>②「개인정보보호법」 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 
                        규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 
                        정하는 바에 따라 행정심판을 청구할 수 있습니다.<br />
                        ▶ 중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)
                    </div>
                    <br />
                    <div>11. 개인정보 처리방침의 변경에 관한 사항<br />
                        이 개인정보처리방침은 2024.3.6일부터 공고/시행되며, 법령 및 방침 또는 보안기술의 변경에 따라 내용의 추가, 
                        삭제 및 수정이 있는 경우에는 변경사항의 시행 7일 전부터 본 사이트 공지사항을 통하여 고지할 것입니다. 
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"13.38rem"}}>
                    {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                    <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF'}}
                            onClick={()=>navigate(-1)}
                    >뒤로</button>
                </div>
            </div>
        </div>
    )
}

export default TermsPersonalProcess;