import React, { useState } from 'react';
import { db } from '../firebase_config.js';
import { doc, setDoc, getDocs, where, collection, query } from "firebase/firestore";
import Swal from "sweetalert2";


function EvaSignup(props) {
    const userAuth = props.userAuth;
    const [registerUsername, setRegisterUsername] = useState("");
    const [registerUserbirth, setRegisterUserbirth] = useState("");
    const [registerUserphone, setRegisterUserphone] = useState("");

    const noAuthAlert = () => {
        Swal.fire({
          icon: "warning",
          title: "권한 없음",
          text: "해당 메뉴의 권한이 없습니다",
          //showCancelButton: true,
          confirmButtonText: "확인",
          //cancelButtonText: "취소",
        })
    }

    const register = async () => {   
        let now = new Date();
        let todayYear = now.getFullYear();
        let todayMonth = now.getMonth() + 1;
        let todayDate = now.getDate();
        let registerDate = String(todayYear)+String(todayMonth)+String(todayDate);

        const contentsRef = collection(db, "evapatient");
        const q = query(contentsRef, where("username", "==", registerUsername), 
                                     where("userphone", "==", registerUserphone),
                                     where("userbirth", "==", registerUserbirth),
                                     where("regdate", "==", registerDate),
                                     where("groupid", "==", props.userGroup)
                        );
        const querySnapshot = await getDocs(q); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;

        if (querySnapshot.docs.length !== 0) {
            Swal.fire({
                icon: "warning",
                title: "등록된 환자정보",
                text: "오늘 등록한 기록이 있는 환자입니다.",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        } else {
            let attId = String(todayYear).substring(2)+String(todayMonth)+String(todayDate)+String(registerUserphone)+String(registerUsername);

            try{     
                await setDoc(doc(db, "evapatient", attId),{
                    username: registerUsername,
                    userphone: registerUserphone,
                    userbirth: registerUserbirth,
                    regdate: registerDate,
                    registrant: props.userNameData,
                    groupid: props.userGroup,
                    dentist1st: "none",
                    staff1st: "none",
                    dentist2nd: "none",
                    staff2nd: "none",
                    dentist3rd: "none",
                    staff3rd: "none",
                    dentist4th: "none",
                    staff4th: "none",
                    dentist5th: "none",
                    staff5th: "none",
                    fileid: attId,
                    surveyYn: "N",
                    diagstart: "",
                    diagend: "",
                    diagtimestart: null,
                    diagtimeend: null,
                    diagelapsemin: ""
                });

                Swal.fire({
                    icon: "success",
                    title: "환자 등록완료",
                    text: "환자 등록이 완료되었습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        // 상태값 초기화
                        setRegisterUsername("");
                        setRegisterUserbirth("");
                        setRegisterUserphone("");
                        // 각 입력란의 텍스트 지우기
                        document.getElementById("_username").value = "";
                        document.getElementById("_birthdate").value = "";
                        document.getElementById("_phone").value = "";
                    }
                });
            } catch(error) {
                console.log("에러메세지:", error.message);
            }
        }
    }

    const registerClick = () => {
        if(
            registerUsername !== "" &&
            registerUserbirth !== "" &&
            registerUserphone !== ""
        ){
            if(userAuth === 1 || userAuth === 2 || userAuth === 7){
                register();
            }else{
                noAuthAlert();
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "미입력 값 존재",
                text: "입력하지 않은 항목이 존재합니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            }).then((res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                }
            });
        }
    }

    const onKeyPress = (e) => {
        if(e.key === 'Enter'){
            registerClick();
        }
    }

    return (
        <div className="renew_background" >
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva" >
                        <div>
                            <div className="renew_content_title" style={{marginBottom:"4.5rem"}}>환자 등록</div>
                            <div className="renew_inner_title">이름</div>
                            <input className="renew_evasignup" 
                                id="_username" 
                                type="text" 
                                onChange={(e)=>{setRegisterUsername(e.target.value);}}>
                            </input>
                            <div className="renew_inner_title">생년월일</div>
                            <input className="renew_evasignup" 
                                id="_birthdate" 
                                type="text" 
                                placeholder='예 ) 940214'
                                onChange={(e)=>{
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setRegisterUserbirth(onlyNumber);
                                }}>
                            </input>
                            <div className="renew_inner_title">전화번호</div>
                            <input className="renew_evasignup" 
                                id="_phone" 
                                type="text" 
                                placeholder='예 ) 01012345678'
                                onChange={(e)=>{
                                    const { value } = e.target
                                    const onlyNumber = value.replace(/[^0-9]/g, '')
                                    setRegisterUserphone(onlyNumber);
                                }}
                                onKeyPress={onKeyPress}>
                            </input>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:"6.25rem"}}>
                            {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                            <button className="renew_evasignup" onClick={registerClick}>등록</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaSignup;