import React, { useRef, useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, getDocs, collection, query, where } from "firebase/firestore";
import { storage, db } from '../firebase_config';
import { useNavigate, useLocation } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import Swal from "sweetalert2";

import NoImage from '../images/renewal/No_image.svg';


function EvaEmpMngReg(props) {
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const empdata = state.data; //전 페이지에서 넘어온 매개변수 

    let initialSelEmpdiv = "";
    let initialSelEmpname = "";
    let initialSelFileaddr = "";

    if (empdata !== "NEW") {
        initialSelEmpdiv = empdata.empdiv;
        initialSelEmpname = empdata.name;
        initialSelFileaddr = empdata.fileAddr;
    }

    const [selEmpdiv, setSelEmpdiv] = useState(initialSelEmpdiv !== "" ? initialSelEmpdiv : "");
    const [selEmpname, setSelEmpname] = useState(initialSelEmpname !== "" ? initialSelEmpname : "");
    const [imageURL, setImageURL] = useState(initialSelFileaddr !== "" ? initialSelFileaddr : "");

    const [fileData, setFileData] = useState();     //파일 리스트
    const [upFileName, setUpFileName] = useState('');
    
    const fileInput = useRef();
    const navigate = useNavigate();
    
    // 파일 선택시 파일리스트 상태 변경해주는 함수
    const handleContentChange = async (e) => {
        const uploadFile = Array.from(e.target.files);
    
        // 이미지가 아닌 파일을 업로드하려는 경우
        if (uploadFile.some(file => file.type.substr(0, 5) !== "image")) {
            showAlert("error", "지원하지 않는 형식의 파일", "이미지 업로드만 가능합니다.");
            return;
        }
    
        // 이미지 압축 시작 알림
        showAlert("info", "이미지 압축 중", "첨부파일 항목에 파일 이름이 나타날 때까지 잠시 기다려주세요.");
    
        // 원본 리사이즈 로직
        const options = {
            maxSizeMB: 5,
            maxWidthOrHeight: 3840
        };
    
        try {
            // 압축된 파일 생성
            const compressedFiles = await Promise.all(uploadFile.map(file => imageCompression(file, options)));
    
            // 압축된 파일을 state에 설정
            setFileData(compressedFiles);
            setUpFileName(compressedFiles[0].name);
        } catch (error) {
            console.error("이미지 압축 중 오류 발생:", error);
            showAlert("error", "이미지 압축 중 오류 발생", "다시 시도해주세요.");
        }
    };

    //업로드 시 호출될 함수
    const handleContentUpload = async (e, fileData) => {
        e.preventDefault();
    
        // 파일이 선택되지 않은 경우 경고 메시지 표시
        if (!upFileName) {
            showAlert("error", "업로드 파일 미선택", "업로드할 파일을 선택해주세요.");
            return;
        }
    
        // 직원 구분이 선택되지 않은 경우 경고 메시지 표시
        if (!selEmpdiv) {
            showAlert("warning", "직원 구분 미선택", "직원 구분을 선택해주세요.");
            return;
        }
    
        // 중복 이미지인지 확인하는 쿼리 실행
        const q = query(collection(db, "employees"), where("filename", "==", upFileName));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length > 0) {
            showAlert("warning", "중복 이미지", "해당 파일은 이미 존재합니다.");
            return;
        }

        // 직원 수 확인
        const empRef = collection(db, "employees");
        const empQuery = query(empRef, where("groupid", "==", props.userGroup), where("empdiv", "==", selEmpdiv));
        const empSnapshot = await getDocs(empQuery);
        const maxEmpNo = empSnapshot.size + 1; // 해당하는 직원 데이터 수에 1을 더함
    
        // 이미지 업로드 처리
        try {
            const uploadTasks = fileData.map(async (file) => {
                const storageRef = ref(storage, `employeeimages/${props.userGroup}/${file.name}`);
                const task = uploadBytesResumable(storageRef, file);
                const snapshot = await task;
    
                // 이미지 다운로드 URL 가져오기
                const downloadURL = await getDownloadURL(snapshot.ref);

                // 이미지 표시
                setImageURL(downloadURL); // setImageURL은 useState를 통해 상태를 관리하는 함수
    
                // 파일 ID 생성
                const now = new Date();
                const fileid = `${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}${selEmpname}`;
    
                // 직원 정보 생성
                await createEmployeeInfo(fileid, downloadURL, storageRef._location.path, upFileName, selEmpname, props.userGroup, props.userNameData, selEmpdiv, maxEmpNo);
    
                return downloadURL;
            });
    
            // 모든 이미지 업로드 및 직원 정보 생성이 완료되면 성공 메시지 표시
            await Promise.all(uploadTasks);
            setSelEmpdiv("");
            setUpFileName("");
            setSelEmpname("");
            document.getElementById("_employeename").value = "";
            document.getElementById("_fileinfo").value = "";

            showAlert("success", "직원 등록", "직원 등록이 완료되었습니다.");
        } catch (error) {
            console.error("이미지 업로드 중 오류 발생:", error);
            showAlert("error", "이미지 업로드 오류", "이미지 업로드 중 오류가 발생했습니다. 다시 시도해주세요.");
        }
    };

    //파일정보 테이블에 저장하는 로직
    const createEmployeeInfo = async (fileid, fileUrl, imgUrl, file_name, empName, userGroup, uploaderName, employeeDiv, maxEmpNo) => {
        try{
            await setDoc(doc(db, "employees", fileid),{
                fileid: fileid,
                fileAddr: fileUrl,
                imgUrl: imgUrl,
                filename: file_name,
                name: empName,
                empdiv: employeeDiv,
                groupid: userGroup,
                uploadUser: uploaderName,
                empno: maxEmpNo
            })
        } catch(error){
            console.log(error.message);
        }
    }

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    return (
        <div className="renew_background">
            <div className="content_align">
                <div className="content_maxwidth">
                    <div className="renew_content_background eva">
                        <div className="renew_content_title evaempmngreg">직원 편집</div>
                        <div className="evaempmngreg_flex">
                            {imageURL ? (
                                // 이미지가 있는 경우 이미지 표시
                                <img src={imageURL} alt="업로드된 이미지" style={{width:"15rem",height:"22.5rem",borderRadius:"0.625rem",
                                    marginRight:"5.5rem"}} />
                            ) : (
                                // 이미지가 없는 경우 안내 메시지 표시
                                <div style={{width:"15rem",height:"22.5rem",flexShrink:0,borderRadius:"0.625rem",background:'#F8F8F8',
                                            display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',
                                            color:'#A5A5A5',fontSize:"1rem",fontWeight:400,marginRight:"5.5rem"}}
                                >
                                    <img src={NoImage} alt="이미지없음" style={{width:"2rem",height:"2rem"}} />
                                    <div>이미지를</div>
                                    <div>추가해주세요.</div>
                                </div>
                            )}
                            <div className="evaempmngreg_margin">
                                <div className="renew_question_mod_subtitle">직원구분</div>
                                <div style={{display:'flex',marginBottom:"2rem"}}>
                                    <div className="renew_question_sel_unit" 
                                        style={{marginRight:10,color:selEmpdiv === 'Dentist' && '#FFF',
                                            background:selEmpdiv === 'Dentist' && '#191919'}}
                                        onClick={()=>setSelEmpdiv('Dentist')}>원장</div>
                                    <div className="renew_question_sel_unit"
                                        style={{color:selEmpdiv === 'Staff' && '#FFF',
                                            background:selEmpdiv === 'Staff' && '#191919'}}
                                        onClick={()=>setSelEmpdiv('Staff')}>선생님</div>
                                </div>
                                <div className="renew_question_mod_subtitle">이름</div>
                                <input id="_employeename" 
                                        className="renew_employee_mod"
                                        style={{marginBottom:"2rem"}}
                                        placeholder="이름을 입력해주세요"
                                        defaultValue={selEmpname}
                                        onChange={(e)=>setSelEmpname(e.target.value)}
                                ></input>
                                <div className="renew_question_mod_subtitle">이미지 첨부</div>
                                <div style={{display:'flex'}}>
                                    <input 
                                        // multiple //파일 여러개올릴때
                                        accept='image/*'
                                        type="file"
                                        ref={fileInput}
                                        style={{display:"none"}}
                                        onChange={handleContentChange}
                                    ></input>
                                    <input id="_fileinfo" className="renew_employee_mod"
                                        style={{width:"13.25rem"}} 
                                        defaultValue={upFileName}
                                        readOnly
                                    ></input>
                                    <button style={{width:"5rem",height:"3rem",flexShrink:0,borderRadius:"0.5rem",border:'1px solid #7962F6',
                                                    color:'#7962F6',fontSize:"1rem",fontWeight:600,marginLeft:"0.5rem"}}
                                            onClick={()=>fileInput.current.click()}
                                    >파일첨부</button>
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'center',marginTop:"9.375rem"}}>
                            <button className="renew_question_mod" onClick={()=>navigate("/evaEmployeeManage")}>뒤로</button>
                            <button className="renew_question_mod" style={{marginLeft:16,background:'#191919',color:'#FFF'}}
                                onClick={(e) =>handleContentUpload(e, fileData)}
                            >저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EvaEmpMngReg