import React from 'react';
import { useNavigate } from 'react-router-dom';

function TermsEventInfo() {
    const navigate = useNavigate();

    return (
        <div className="renew_background signup">
            <div className="renew_content_background">
                <div>
                    <div className="renew_content_title" >이벤트정보제공및동의</div> 
                    <div className="content_area" style={{fontSize:"1rem"}}>
                        <div style={{fontSize:"1.125rem",fontWeight:500}}>시행일자 : 2024.03.05</div>
                        <br />
                        <div>덴탈커넥트 이벤트 정보 제3자 제공 동의서<br />
                            덴탈커넥트는 정보주체 동의하에 수집된 개인정보에 한해 원활한 서비스 제공을 위해 다음과 같은 범위로만 제 자에게 제공하고 있습니다<br />
                            개인정보를 제공받는 자: 덴탈커넥트로부터 경품 제공등에 필요한 업무를 위탁 받은 자<br />
                            제공받는 자의 개인정보 이용 목적: 이벤트 경품 제공 및 발송<br />
                            제공하는 개인정보 항목: 휴대폰번호<br />
                            제공받는 자의 보유,이용기간: 이벤트 기간 및 이벤트 발송 종료 시까지<br />
                            정보주체는 상기 동의를 거부할 수 있습니다 다만 위와 같은 제3자 제공에 대해 동의 거부 시 치과인 이벤트 참여가 불가합니다
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',marginTop:"13.38rem"}}>
                    {/* <button className="renew_evasignup" onClick={()=>navigate(-1)}>뒤로</button> */}
                    <button className="renew_evasignup" style={{color:'#373737',background:'#EFEFEF'}}
                            onClick={()=>navigate(-1)}
                    >뒤로</button>
                </div>
            </div>
        </div>
    )
}

export default TermsEventInfo;